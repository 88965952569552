import { useQuery } from 'react-query';
import makeApiRequest from '../makeApiCall';
import { ReportsType } from './types';

const GET_REPORTS_QUERY_KEY = 'getReports';
const GET_REPORT_QUERY_KEY = 'getReport';

// Server should return ReportsType
export async function getReports(organisationId: string) {
    const { data } = await makeApiRequest<ReportsType[]>(`report/getReports/${organisationId}`);

    return data;
}

export const useReports = (organisationId: string, queryOptions?: any) => useQuery([GET_REPORTS_QUERY_KEY, organisationId], () => getReports(organisationId), {
    ...queryOptions,
    staleTime: Infinity
});

export async function getReportToken(key: string) {
    return await makeApiRequest<string>(`/report/getReport/${key}`);
}

export async function getReport(key: string) {
    return await makeApiRequest<ReportsType>(`/report/getReportConfig/${key}`);
}

export const useReport = (key: string, queryOptions?: any) => useQuery([GET_REPORT_QUERY_KEY, key], () => getReport(key), {
    ...queryOptions,
    staleTime: Infinity
});

export async function cacheClearReport() {
    return await makeApiRequest<any>('/report/cache/clear');
}
