import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import Typography from 'app/components/Typography';
import { ReportsType } from 'app/api/reports/types';
import useFilteredReports from 'app/hooks/useFilteredReports';
import DropDown from 'app/components/DropDown';
import { removeNumber } from 'app/utils/helpers';
import './reports-nav.scss';

type Props = {
    reportGroup: {
        order: number;
        name: string;
        data: ReportsType[];
    }[];
    handleNavigate: (data: { label: string, value: ReportsType }) => void;
    handleFavouriteReport: (e: React.MouseEvent<HTMLElement, MouseEvent>, reportKey: string) => void;
    moduleName: string;
    favouriteReports: string[];
}

const ReportsNav = ({ reportGroup, moduleName, favouriteReports, handleFavouriteReport, handleNavigate }: Props) => {
    const [selectedGroup, setSelectedGroup] = useState(reportGroup[0].name);

    const { data } = useFilteredReports();

    const renderFavouriteElement = (report: ReportsType) => {
        return !favouriteReports.includes(report.key)
            ? <i data-testid='favorite' onClick={(e) => handleFavouriteReport(e, report.key)} style={{ fontSize: 25 }}
                className='fa-regular fa-star px-2' />
            : <i data-testid='favorite' onClick={(e) => handleFavouriteReport(e, report.key)} style={{ color: '#e6cc00', fontSize: 25 }}
                className='fa-solid fa-star px-2' />;
    };

    const renderReportItem = (report: ReportsType) => {
        return <DropDown.Item onClick={() => handleNavigate({ label: report.key, value: report })} className='card report-item mb-3'>
            <div key={report.key} className='d-flex align-items-center justify-content-between'>
                <div className='d-flex align-items-center'>
                    <div className='placeholder-image me-4'>
                        <img className='me-4' width={250} src={report?.thumbnailUrl || ''}
                            alt={report.key} />
                    </div>
                    <div>
                        <Typography weight='bold' size='3'>{report.reportName}</Typography>
                        <p className='fw-semibold fs-5 text-start report-description'>
                            {report.description}
                        </p>
                    </div>
                </div>
                {renderFavouriteElement(report)}
            </div>
        </DropDown.Item>;
    };

    return (
        <div className='reports-nav'>
            <div className='nav-list'>
                <Typography size='2x' weight='bold' className='text-white pb-4'>{moduleName}</Typography>
                {favouriteReports.length > 0 && <>
                    <div onClick={() => setSelectedGroup('Favourites')} className={`group-name opacity-${selectedGroup === 'Favourites' ? '100' : '50'}`}>
                        <Typography className='text-white' size='1'>Favourites</Typography>
                        <i style={{ color: 'white' }} className='fa-solid fa-arrow-right' />
                    </div></>}
                {reportGroup.map((group, j) => {
                    return <div onClick={() => setSelectedGroup(group.name)} className={`group-name opacity-${selectedGroup === group.name ? '100' : '50'}`} key={j}>
                        <Typography size='1' className='text-white'>{removeNumber(group.name || 'Dashboard')}</Typography>
                        <i style={{ color: 'white' }} className='fa-solid fa-arrow-right' />
                    </div>;
                })}
            </div>
            <div className='nav-content'>
                {selectedGroup === 'Favourites' && <div className='mb-10' >
                    <Typography weight='bold' className='mb-3' size='1'>Favourites</Typography>
                    {favouriteReports.map((favReportKey) => {
                        const report = data?.find((data) => data.key === favReportKey)!;

                        return <React.Fragment key={favReportKey}>
                            {renderReportItem(report)}
                        </React.Fragment>;
                    })}
                </div>}
                {reportGroup.map((group, j) => {
                    if (group.name === selectedGroup) {
                        return <div className='mb-10' key={j}>
                            <Typography weight='bold' className='mb-3' size='1'>{removeNumber(group.name || 'Dashboard')}</Typography>
                            {group.data.map((report) => {
                                return <React.Fragment key={report.key}>
                                    {renderReportItem(report)}
                                </React.Fragment>;
                            })}
                        </div>;
                    } else return <div key={j} />;
                })}
            </div>
        </div>
    );
};

export default ReportsNav;
