import React from 'react';
import Placeholder, { PlaceholderProps } from 'react-bootstrap/Placeholder';
import './placeholder.scss';

type Props = PlaceholderProps & {
    numberOfPlaceholder?: number;
    customSize?: 'xs' | 'md' | 'lg' | 'none';
    className?: string;
}

const AppPlaceholder = ({ numberOfPlaceholder = 1, customSize = 'lg', className, ...otherProps }: Props) => {
    return (<Placeholder as='p' animation='wave'>
        {Array.from(Array(numberOfPlaceholder).keys()).map((_, i) => {
            return <Placeholder {...otherProps} key={i} className={`size-${customSize} ${className}`} />;
        })}
    </Placeholder>
    );
};

export default AppPlaceholder;
