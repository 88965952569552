import { NAV_VERSION_KEY, FILTER_VERSION_KEY, NAV_ORIENTATION_KEY } from 'app/utils/constants';
import create from 'zustand';

type ConfigStateType = {
    navbarOrientation: string;
    navbarVersion: string;
    filterVersion: string;
    user: string;
    setNavbarOrientation: (navbarOrientation: string) => void;
    setNavbarOrientationMobile: (navbarOrientation: string) => void;
    setNavbarVersion: (navbarVersion: string) => void;
    setFilterVersion: (filterVersion: string) => void;
    setInitialConfigs: ({ navbarOrientation, navbarVersion, filterVersion, user }: { navbarOrientation: string, navbarVersion: string, filterVersion: string, user: string }) => void;
}

const useConfigStore = create<ConfigStateType>((set) => ({
    navbarOrientation: '',
    navbarVersion: '',
    filterVersion: '',
    user: '',

    setNavbarOrientation: (navbarOrientation) => set((state) => {
        localStorage.setItem(`${NAV_ORIENTATION_KEY}-${state.user}`, navbarOrientation);

        return ({ ...state, navbarOrientation });
    }),
    setNavbarOrientationMobile: (navbarOrientation) => set((state) => {
        return ({ ...state, navbarOrientation });
    }),
    setNavbarVersion: (navbarVersion) => set((state) => {
        localStorage.setItem(`${NAV_VERSION_KEY}-${state.user}`, navbarVersion);

        return ({ ...state, navbarVersion });
    }),
    setFilterVersion: (filterVersion) => set((state) => {
        localStorage.setItem(`${FILTER_VERSION_KEY}-${state.user}`, filterVersion);

        return ({ ...state, filterVersion });
    }),
    setInitialConfigs: (initialConfig) => set(() => (initialConfig))

}));

export default useConfigStore;
