import { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import Button from 'react-bootstrap/Button';
import useToast from 'app/hooks/useToast';
import { CoreType } from 'app/api/core/types';
import { updateCoreData, useGetCoreData } from 'app/api/core/core';
import useCreateCoreGridColumns from './hooks/useCreateCoreGridColumns';
import useSpreadsheet from '../hooks/useSpreadsheet';
import useUpdateRows from './hooks/useUpdateRows';
import { useAuth } from 'app/modules/auth';
import useConfigStore from 'app/store/userConfig';
import useModalStore from 'app/store/modal';
import useRightDrawerStore from 'app/store/rightDrawer';
import AppTooltip from 'app/components/Tooltip';
import Typography from 'app/components/Typography';
import { ReportsType } from 'app/api/reports/types';
import { ORIENTATION } from 'app/utils/constants';
import { findChangedObjects, updateRightDrawerData } from 'app/utils/helpers';
import { ButtonConfig, buttonConfig } from './buttonConfig';
import AgGrid from './AgGrid';
import SpreadsheetActions from './SpreadsheetActions/SpreadsheetActions';
import SpreadsheetExport from './SpreadsheetExport';

import './spreadsheet-grid.scss';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

type Props = {
    report: ReportsType;
    className?: string;
}

const coreDataFilter = {
    'timb-ss-production-planning-mach': 'machine',
    'timb-ss-production-planning': 'drying',
    'timb-ss-production-planning-treatment-plant': 'treatmentplant'
} as const;

const SpreadsheetGridCORE = ({ report, className }: Props) => {
    const [isSaving, setIsSaving] = useState(false);
    const [freshRecords, setFreshRecords] = useState<CoreType[]>();

    const params = useParams<{ reportKey: string, organisationId: string }>();
    const { currentUser } = useAuth();

    const { setRightDrawerContent, rightDrawerContent } = useRightDrawerStore();
    const { navbarOrientation } = useConfigStore();
    const { setModalContent } = useModalStore();

    const { data: rawSheetData, isLoading: isSpreadsheetDataLoading, refetch } = useGetCoreData(coreDataFilter[report.key as keyof typeof coreDataFilter],
        {
            refetchOnWindowFocus: false,
            refetchOnReconnect: false,
            onSuccess: (data) => {
                setFreshRecords(data.data.data);
            }
        });
    const { setNotification } = useToast();

    const { gridApi, pageSize, isSheetUpdated, setIsSheetUpdated, handleGridReady, onPageSizeChanged, onCellValueChanged } = useSpreadsheet(isSpreadsheetDataLoading);

    const { handleUpdateRows } = useUpdateRows(refetch, report.cellEditorParams?.machine?.values || [], report?.scheduleName || '');

    const sheetData: any = useMemo(() => rawSheetData ? JSON.parse(JSON.stringify(rawSheetData.data.data)) : [], [rawSheetData]);

    const createdColumns = useCreateCoreGridColumns(sheetData[0] || {}, report);

    const columnNames = useMemo(() => createdColumns, [sheetData, report]);

    useEffect(() => {
        const rightDrawerContentUpdate = [{
            keyName: 'actions',
            isShow: false,
            content: () => <Typography>No Available Actions</Typography>
        }, {
            keyName: 'export',
            isShow: true,
            content: () => <SpreadsheetExport parsedRawSheetData={sheetData} isSheetUpdated={isSheetUpdated} />
        }, {
            keyName: 'filters',
            isShow: false,
            content: () => <div />
        }];

        const updatedContent = updateRightDrawerData(rightDrawerContentUpdate, rightDrawerContent);

        setRightDrawerContent(updatedContent);
    }, [isSheetUpdated, sheetData]);

    const handleSaveSpreadsheet = async () => {
        const updatedValues: any[] = [];

        if (gridApi) {
            gridApi.forEachNode((node: any) => {
                const updatedValue = node.data;

                updatedValues.push(updatedValue);
            });
        }

        if (rawSheetData) {
            const changedObjects = findChangedObjects<CoreType>(freshRecords || [], updatedValues);

            try {
                setIsSaving(true);
                await updateCoreData(changedObjects, { scheduleName: report?.scheduleName || '' });
                refetch();

                setNotification('Sheet saved', 'success');
                setIsSheetUpdated(false);
            } catch (error: any) {
                if (error.response && error.response.data && error.response.data.message) {
                    setNotification(error.response.data.message, 'error');
                } else {
                    setNotification('Failed to update spreadsheet', 'error');
                }
            } finally {
                setIsSaving(false);
            }
        }
    };

    const handleModalAction = (config: ButtonConfig) => {
        setModalContent({ content: <SpreadsheetActions config={config} />, title: config.label });
    };

    const handleAsyncButtonAction = async (config: ButtonConfig) => {
        // Future updates
    };

    const onSelectionChanged = useCallback(() => {
        if (gridApi) {
            const selectedRows = gridApi.getSelectedRows().length;

            document.querySelector('#selectedRows')!.innerHTML = 'Rows Selected: ' + selectedRows.toString();
        }
    }, [gridApi]);

    return (
        <div>
            <Typography className='w-100 text-left' weight='bolder' size='2x'>{report.reportName}</Typography>
            <div className={`spreadsheet-grid card w-100 ${className}`}>
                <div className='grid-control'>
                    <div className='d-flex justify-content-end gap-3'>
                        {buttonConfig[params.reportKey!]?.left.map((config, i) => {
                            const shouldRender =
                                (config.component === 'modal' || config.component === 'button') &&
                                (!config.hide ||
                                    !currentUser?.app_metadata.accessGroup ||
                                    !config.hide.includes(currentUser.app_metadata.accessGroup));

                            if (!shouldRender) {
                                return <div key={i} />;
                            }

                            if (config.component === 'modal') {
                                return <Button key={i} size='sm' onClick={() => handleModalAction(config)}>{config.label}</Button>;
                            } else if (config.component === 'button') {
                                return <AppTooltip key={i} className='w-200px' placement='right'
                                    isShow={isSheetUpdated && config.saveSheetBeforeAction}
                                    TooltipContent={<Typography size='8'>There are unsaved changes in the sheet. Please save to enable.</Typography>}>
                                    <Button disabled={config.apiCall ? ((config.saveSheetBeforeAction && isSheetUpdated)) : false} size='sm' onClick={() => handleAsyncButtonAction(config)}>{config.label}</Button>
                                </AppTooltip>;
                            } else return <div key={i} />;
                        })}
                    </div>
                    <div className='d-flex justify-content-end gap-3'>
                        <Button onClick={() => handleUpdateRows(gridApi ? gridApi.getSelectedRows() : [])} size='sm'>Update Rows</Button>
                        {buttonConfig[params.reportKey!]?.right.map((config, i) => {
                            if (config.label === 'Save') {
                                return <Button key={i} disabled={config.saveSheetBeforeAction ? isSaving || !isSheetUpdated : false} size='sm'
                                    onClick={handleSaveSpreadsheet}>{config.label}</Button>;
                            } else return <div key={i} />;
                        })}
                    </div>
                </div>
                <div className={`ag-theme-alpine ${navbarOrientation === ORIENTATION.VERTICAL ? 'vertical' : 'horizontal'}`}>
                    <AgGrid
                        pageSize={pageSize}
                        parsedRawSheetData={sheetData}
                        handleGridReady={handleGridReady}
                        columnDefs={columnNames}
                        onCellValueChanged={onCellValueChanged}
                        onRowSelect={onSelectionChanged} />
                    <div className='d-flex align-items-center justify-content-between my-2'>
                        <p className='fw-semibold fs-4 m-0 me-2' id='selectedRows' />
                        <div className='d-flex align-items-center'><p className='fw-semibold fs-4 m-0 me-2'>Page Size:</p>
                            <select value={pageSize} onChange={onPageSizeChanged} id='page-size'>
                                <option value='dynamic'>Dynamic</option>
                                <option value='50'>50</option>
                                <option value='100'>100</option>
                                <option value='300'>300</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SpreadsheetGridCORE;
