import { useEffect, useState } from 'react';
import { Outlet, useLocation, useParams } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { HeaderWrapper } from './components/header';
import { ScrollTop } from './components/scroll-top';
import { Content } from './components/content';
import { Sidebar } from './components/sidebar';
import {
    InviteUsers,
    UpgradePlan,
    ActivityDrawer,
    ThemeModeProvider,
    useThemeMode
} from '../partials';
import { PageDataProvider } from './core';
import { reInitMenu } from '../helpers';
import useConfigStore from 'app/store/userConfig';
import useIsMobile from 'app/hooks/useIsMobile';
import useRightDrawerStore from 'app/store/rightDrawer';
import Modal from 'app/components/Modal';
import useModalStore from 'app/store/modal';
import { ORIENTATION } from 'app/utils/constants';
import RightDrawer from 'app/components/RightDrawer/RightDrawer';
import SignoutNav from './components/header/signout-nav/SignoutNav';

const MasterLayout = () => {
    const location = useLocation();
    const params = useParams();
    const isMobile = useIsMobile();
    const { navbarOrientation } = useConfigStore();
    const { menuMode } = useThemeMode();
    const { modalContent, clearModalContent, ...modalProps } = useModalStore();
    const { rightDrawerContent } = useRightDrawerStore();

    const handleCloseModal = () => {
        clearModalContent();
        // onToggle && onToggle();
    };

    useEffect(() => {
        reInitMenu();
    }, [location.key]);

    return (
        <PageDataProvider>
            <div className='d-flex flex-column flex-root app-root' id='kt_app_root'>
                <div className='app-page flex-column flex-column-fluid' id='kt_app_page'>
                    {!params.organisationId ? <SignoutNav /> : (isMobile || navbarOrientation === ORIENTATION.HORIZONTAL) ? <HeaderWrapper /> : <></>}
                    <div className='app-wrapper flex-column flex-row-fluid' id='kt_app_wrapper'>
                        {params.organisationId && navbarOrientation === 'vertical' && <Sidebar />}
                        <div className='app-main flex-column flex-row-fluid' id='kt_app_main'>
                            <div className='d-flex flex-column flex-column-fluid'>
                                {/* {params.organisationId && <ToolbarWrapper />} */}
                                <Content>
                                    <Outlet />
                                </Content>
                            </div>
                            {/* <FooterWrapper /> */}
                        </div>
                    </div>
                </div>
            </div>
            <RightDrawer data={rightDrawerContent} />
            <ToastContainer
                position='top-right'
                autoClose={3000}
                hideProgressBar={true}
                closeOnClick={true}
                pauseOnHover={false}
                draggable={false}
                theme={menuMode === 'light' ? 'light' : 'dark'} />

            <Modal
                {...modalProps}
                onToggle={handleCloseModal}
                isShow={!!modalContent}>
                {modalContent}
            </Modal>

            {/* begin:: Drawers */}
            <ActivityDrawer />
            {/* <RightToolbar /> */}
            {/* <DrawerMessenger /> */}
            {/* end:: Drawers */}

            {/* begin:: Modals */}
            <InviteUsers />
            <UpgradePlan />
            {/* end:: Modals */}
            <ScrollTop />
        </PageDataProvider>
    );
};

export { MasterLayout };
