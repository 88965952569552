import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useReport } from 'app/api/reports/reports';
import Tabs from 'app/components/Tabs/Tabs';
import Typography from 'app/components/Typography';
import useIsMobile from 'app/hooks/useIsMobile';
import { ORIENTATION } from 'app/utils/constants';
import useConfigStore from 'app/store/userConfig';
import { KTSVG } from '_metronic/helpers';
import { ThemeModeType, useThemeMode } from '_metronic/partials';
import './nav-config.scss';

type Props = {
    className?: string;
}

const NavConfig = ({ className }: Props) => {
    const params = useParams();
    const { navbarOrientation, setNavbarOrientation, navbarVersion, setNavbarVersion, filterVersion, setFilterVersion } = useConfigStore();
    const { data } = useReport(params.reportKey!);

    const isMobile = useIsMobile();
    const { menuMode, updateMode, updateMenuMode } = useThemeMode();

    useEffect(() => {
        document.body.setAttribute('data-kt-app-layout', menuMode === 'light' ? 'light-sidebar' : 'dark-sidebar');
    }, []);

    const navbarOrientationTabs = [{
        key: 'vertical',
        tabName: 'Vertical'
    }, {
        key: 'horizontal',
        tabName: 'Horizontal'
    }];

    const themeTabs = [{
        key: 'light',
        tabName: 'Light',
        tabLeftElement: <span className='menu-icon' data-kt-element='icon'>
            <KTSVG path='/media/icons/duotune/general/gen060.svg' className='svg-icon-3' />
        </span>
    }, {
        key: 'dark',
        tabName: 'Dark',
        tabLeftElement: <span className='menu-icon' data-kt-element='icon'>
            <KTSVG path='/media/icons/duotune/general/gen061.svg' className='svg-icon-3' />
        </span>
    }];

    const navbarVersionTabs = [{
        key: 'version1',
        tabName: 'Version 1'
    }, {
        key: 'version2',
        tabName: 'Version 2'
    }];

    const filterDisplayTabs = [{
        key: 'pinned',
        tabName: 'Pinned'
    }, {
        key: 'popOut',
        tabName: 'Pop out'
    }];

    const handleOrientationChange = (key: string) => {
        setNavbarOrientation(key);
        window.location.reload();
    };

    const switchMode = (_mode: ThemeModeType) => {
        updateMenuMode(_mode);
        updateMode(_mode);

        // Set sidebar theme
        document.body.setAttribute('data-kt-app-layout', _mode === 'light' ? 'light-sidebar' : 'dark-sidebar');
    };

    const handleNavVersionChange = (version: string) => {
        setNavbarVersion(version);
    };

    return (
        <div className={`nav-config ${className}`}>
            {!isMobile && <div className='mb-3'>
                <Typography size='5' weight='semibold' className='pb-3 text-gray-600'>Navbar Orientation</Typography>
                <Tabs tabItems={navbarOrientationTabs} activeTab={navbarOrientation} onTabChange={handleOrientationChange} />
            </div>}
            {!isMobile && navbarOrientation === ORIENTATION.VERTICAL && <div className='mb-3'>
                <Typography size='5' weight='semibold' className='pb-3 text-gray-600'>Reports Sidebar Navigation</Typography>
                <Tabs tabItems={navbarVersionTabs} activeTab={navbarVersion} onTabChange={handleNavVersionChange} />
            </div>}
            {(!isMobile && (data && data?.data.data.filters?.length > 0)) && <div className='mb-3'>
                <Typography size='5' weight='semibold' className='pb-3 text-gray-600'>Filter Display</Typography>
                <Tabs tabItems={filterDisplayTabs} activeTab={filterVersion} onTabChange={setFilterVersion} />
            </div>}
            <div className='mb-3'>
                <Typography size='5' weight='semibold' className='text-gray-600'>Theme</Typography>
                <Tabs tabItems={themeTabs} activeTab={menuMode} onTabChange={switchMode} />
            </div>
        </div>
    );
};

export default NavConfig;
