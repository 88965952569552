type Config = {
    [key: string]: {
        left: ButtonConfig[];
        right: ButtonConfig[];
    };
}

export type ButtonConfig = {
    label: string;
    component: string;
    inputComponent?: {
        type: 'file';
        accept: '.txt' | '.csv';
    };
    apiCall?: {
        endpoint: string;
    };
    saveSheetBeforeAction?: boolean;
    disableWhenDataIsEmpty?: boolean;
    hide?: string[];
    navigateTo?: string;
    successMessage?: string;
}

export const buttonConfig: Config = {
    'timb-ss-production-planning': {
        left: [],
        right: [
            {
                label: 'Add Row',
                component: 'button',
                disableWhenDataIsEmpty: true
            },
            {
                label: 'Save',
                component: 'button',
                saveSheetBeforeAction: true
            }
        ]
    },
    'timb-ss-production-planning-mach': {
        left: [],
        right: [
            {
                label: 'Add Row',
                component: 'button',
                disableWhenDataIsEmpty: true
            },
            {
                label: 'Save',
                component: 'button',
                saveSheetBeforeAction: true
            }
        ]
    },
    'timb-ss-production-planning-treatment-plant': {
        left: [],
        right: [
            {
                label: 'Add Row',
                component: 'button',
                disableWhenDataIsEmpty: true
            },
            {
                label: 'Save',
                component: 'button',
                saveSheetBeforeAction: true
            }
        ]
    }

};
