
import { AirportsScheduleData } from 'app/api/airports/types';
import { ReportsType } from 'app/api/reports/types';
import { useAuth } from 'app/modules/auth';
import { yyyymmddFormatter } from '../../helpers';

const addPercentSign = (params: any): string => {
    if (isNaN(params.value)) {
        throw new Error('Input is not a valid number');
    }

    return `${params.value}%`;
};

const valueFormatter: any = {
    PERIOD_START: yyyymmddFormatter,
    PERIOD_END: yyyymmddFormatter,
    LOAD_FACTOR: addPercentSign
};

const useCreateGridColumns = (sheetData: AirportsScheduleData, report: ReportsType) => {
    const { currentUser } = useAuth();
    const accessGroup = currentUser?.app_metadata.accessGroup;

    const createdColumns = Object.keys(sheetData || {}).map((name) => {
        let hiddenForAccessGroup: any = [];
        let readonlyForAccessGroup: any = [];

        if (accessGroup) {
            hiddenForAccessGroup = report.accessGroup.columnHidden?.[accessGroup] || [];
            readonlyForAccessGroup = report.accessGroup.columnReadonly?.[accessGroup] || [];
        }

        const reportReadonly = (report.readOnly || []).concat(readonlyForAccessGroup);
        const reportHidden = (report.hidden || []).concat(hiddenForAccessGroup);

        return ({
            field: name,
            width: report.columnWidth ? report.columnWidth[name] : undefined,
            hide: reportHidden.includes(name),
            editable: sheetData.VERSION_IS_LOCKED ? false : !reportReadonly.includes(name),
            headerName: report.columnNames ? report.columnNames[name] : name,
            filter: report.filter ? report.filter[name] : 'agTextColumnFilter',
            filterParams: {
                buttons: ['reset', 'apply'],
                closeOnApply: true
            },
            valueFormatter: valueFormatter[name] ? valueFormatter[name] : undefined,
            cellEditor: report.cellEditor ? report.cellEditor[name] : 'agTextCellEditor',
            cellEditorPopup: report.cellEditor && report.cellEditor[name] === 'agLargeTextCellEditor',
            cellEditorParams: report.cellEditorParams && report.cellEditorParams[name]
        });
    });

    return createdColumns;
};

export default useCreateGridColumns;
