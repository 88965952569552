import { SidebarMenuItemWithSub } from './SidebarMenuItemWithSub';
import { SidebarMenuItem } from './SidebarMenuItem';
import { useParams } from 'react-router-dom';
import { ReportsType } from 'app/api/reports/types';
import AppPlaceholder from 'app/components/Placeholder/Placeholder';
import AppTooltip from 'app/components/Tooltip/Tooltip';
import useIsMobile from 'app/hooks/useIsMobile';
import { useDataTemplateByOrganisation } from 'app/api/dataTemplate/dataTemplate';
import { checkIfValueExists, formatReportGroup, removeNumber } from 'app/utils/helpers';
import Search from 'app/components/Search/Search';
import DropDown from 'app/components/DropDown';
import ReportsNav from 'app/modules/reports-nav';
import useFormatMenu from 'app/hooks/useFormatMenu';
import useFavouriteReport from 'app/hooks/useFavouriteReport';
import useSearchReports from 'app/hooks/useSearchReports';
import useConfigStore from 'app/store/userConfig';
import { NAV_VERSION } from 'app/utils/constants';
import useFilteredReports from 'app/hooks/useFilteredReports';
import useWhiteLabel from 'app/hooks/useWhiteLabel';

const SidebarMenuMain = () => {
    const params = useParams();

    const { data } = useFilteredReports();
    const { handleNavigate, searchOptions, searchValue } = useSearchReports();

    const isMobile = useIsMobile();

    const { data: dataTemplates, isLoading: isDataTemplateLoading } = useDataTemplateByOrganisation(params.organisationId!);

    const { formattedMenu } = useFormatMenu(data!);

    const { favouriteReports, handleFavouriteReport } = useFavouriteReport();

    const { navbarVersion } = useConfigStore();

    const whiteLabelOrg = useWhiteLabel();

    const renderFavouriteElement = (report: ReportsType) => {
        return !favouriteReports.includes(report.key)
            ? <i onClick={(e) => handleFavouriteReport(e, report.key)} className='fa-regular fa-star px-2' />
            : <i onClick={(e) => handleFavouriteReport(e, report.key)} style={{ color: '#e6cc00' }} className='fa-solid fa-star px-2' />;
    };

    const currentReport = data?.find((report) => report.key === params.reportKey) || {};

    return (
        <>
            <div className='menu-item'>
                <div className='menu-content pt-8 pb-2'>
                    {searchOptions && <Search handleSearch={handleNavigate} searchValue={searchValue} options={searchOptions} />}
                </div>
            </div>

            {!isMobile && navbarVersion === NAV_VERSION.VERSION_1 && <>
                <div className='menu-item'>
                    <div className='menu-content pt-8 pb-2'>
                        <span className='menu-title text-uppercase fs-8 ls-1'>Reports</span>
                    </div>
                </div>
                {data
                    ? formattedMenu!.map((module, i) => {
                        return <div key={i} className='menu-item'>
                            <div className='menu-content pb-2'>
                                <DropDown
                                    contentClassName='reports-dropdown w-100 mw-1350px h-100 mh-850px p-0'
                                    drop='end'
                                    toggle={<div onClick={() => { }}
                                        className={`d-flex justify-content-between align-items-center btn btn-custom btn-${whiteLabelOrg || 'primary'} overflow-hidden text-nowrap px-0 h-35px w-100`}>
                                        <span className='btn-label d-flex'>
                                            <div className='d-flex justify-content-start align-items-center ms-4'>
                                                <span className='text-white ms-1'>{removeNumber(module.name)}</span>
                                            </div>
                                        </span>
                                        <i style={{ fontSize: 20 }} className='fa-solid fa-caret-right me-3' />
                                    </div>}>
                                    <ReportsNav moduleName={removeNumber(module.name)} handleNavigate={handleNavigate}
                                        reportGroup={formatReportGroup(module.data)} favouriteReports={favouriteReports} handleFavouriteReport={handleFavouriteReport} />
                                </DropDown>
                            </div>
                        </div>;
                    })
                    : <AppPlaceholder className='h-30px' customSize='xs' numberOfPlaceholder={4}
                        xs={12}
                        md={12}
                        lg={12}
                        xl={12} />}
            </>}

            {(navbarVersion === NAV_VERSION.VERSION_2 || isMobile) && <>
                {favouriteReports.length && data
                    ? <>
                        <div className='menu-item'>
                            <div className='menu-content pt-8 pb-2'>
                                <span className='menu-title text-uppercase fs-8 ls-1'>Favourites</span>
                            </div>
                        </div>
                        <SidebarMenuItemWithSub
                            title='Reports'
                            fontIcon='bi-archive'
                            icon='/media/icons/duotune/general/gen022.svg'>
                            {favouriteReports.map((reportKey) => {
                                const report = data.find((data) => data.key === reportKey)!;

                                return <SidebarMenuItem key={reportKey}
                                    to={`/organisation/${params.organisationId}/report/${report.key}`}
                                    title={removeNumber(report.reportName)}
                                    titleLeftElement={renderFavouriteElement(report)}
                                    titleRightElement={!isMobile
                                        ? <AppTooltip placement='right' TooltipContent={<div className='d-flex align-items-center'>
                                            <img className='me-4' width={250} src={report.thumbnailUrl}
                                                alt={report.key} />
                                            <p className='mw-250px fw-semibold fs-5 text-start'>
                                                {report.description}
                                            </p>
                                        </div>}><i className='fa fa-info-circle' aria-hidden='true' /></AppTooltip>
                                        : <></>} />;
                            })}
                        </SidebarMenuItemWithSub>
                    </>
                    : <span />}
                <div className='menu-item'>
                    <div className='menu-content pt-8 pb-2'>
                        <span className='menu-title text-uppercase fs-8 ls-1'>Reports</span>
                    </div>
                </div>
                {data
                    ? formattedMenu!.map((module, i) => {
                        return <SidebarMenuItemWithSub
                            key={i}
                            isActive={checkIfValueExists(currentReport, module.name)}
                            title={removeNumber(module.name)}
                            fontIcon='bi-archive'
                            icon='/media/icons/duotune/general/gen022.svg'>
                            {formatReportGroup(module.data).map((group, j) => {
                                return <SidebarMenuItemWithSub key={j} title={removeNumber(group.name || 'Dashboard')}
                                    isActive={checkIfValueExists(currentReport, module.name) && checkIfValueExists(currentReport, group.name)}
                                    hasBullet={true}>
                                    {formatReportGroup(module.data)[j].data.map((report, k) => {
                                        return <SidebarMenuItem key={k}
                                            to={`/organisation/${params.organisationId}/report/${report.key}`}
                                            title={report.reportName}
                                            titleLeftElement={renderFavouriteElement(report)}
                                            titleRightElement={!isMobile
                                                ? <AppTooltip placement='right' TooltipContent={<div className='d-flex align-items-center'>
                                                    <img className='me-4' width={250} src={report.thumbnailUrl}
                                                        alt={report.key} />
                                                    <p className='mw-250px fw-semibold fs-5 text-start'>
                                                        {report.description}
                                                    </p>
                                                </div>}><i className='fa fa-info-circle' aria-hidden='true' /></AppTooltip>
                                                : <></>} />;
                                    })}
                                </SidebarMenuItemWithSub>;
                            })}
                        </SidebarMenuItemWithSub>;
                    })
                    : <AppPlaceholder className='h-30px' customSize='xs' numberOfPlaceholder={4}
                        xs={12}
                        md={12}
                        lg={12}
                        xl={12} />}
            </>}

            {!isDataTemplateLoading && dataTemplates?.data.data.length
                ? <SidebarMenuItem
                    className='pt-8'
                    title='Data Upload'
                    to={`/organisation/${params.organisationId}/data-upload`}
                    icon='/media/icons/duotune/abstract/abs027.svg'
                    fontIcon='bi-app-indicator' />
                : <></>}
        </>
    );
};

export { SidebarMenuMain };
