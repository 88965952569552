import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from 'react';
import CreatableSelect from 'react-select/creatable';
import { FixedSizeList as List } from 'react-window';

interface CustomSelectEditorProps {
    value: string;
    stopEditing: () => void;
    values: string[];
    onCreateOption?: (inputValue: string) => void;
}

const CustomSelectEditor = forwardRef((props: CustomSelectEditorProps, ref) => {
    const [selectedOption, setSelectedOption] = useState<any>(null);

    useEffect(() => {
        const initialValue = props.values.find((option: any) => option === props.value) || props.value;

        setSelectedOption({ value: initialValue, label: initialValue });
    }, [props.value]);

    useImperativeHandle(ref, () => ({
        getValue() {
            return selectedOption ? selectedOption.value : '';
        }
    }));

    const handleChange = (option: { label: string; value: string } | null) => {
        setSelectedOption(option);
    };

    const CustomMenuList = useCallback((props: any) => {
        const height = 45; // height of each option
        const itemCount = props.children.length;

        return (
            <List
                height={height * 5}
                itemCount={itemCount}
                itemSize={height}
                width='100%'>
                {({ index, style }: any) => (
                    <div style={style}>
                        {props.children[index]}
                    </div>
                )}
            </List>
        );
    }, []);

    return (
        <CreatableSelect
            menuPortalTarget={document.body}
            // @ts-ignore
            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
            value={selectedOption}
            onChange={handleChange}
            options={props.values.map((value) => ({ label: value, value: value }))}
            components={{ MenuList: CustomMenuList }}
            onBlur={() => props.stopEditing()}
            onKeyDown={(e) => {
                if (e.key === 'Enter') {
                    props.stopEditing();
                }
            }} />
    );
});

export default CustomSelectEditor;
