
import { ReportsType } from 'app/api/reports/types';
import { useAuth } from 'app/modules/auth';

const formatNumber = (params: any) => {
    const parts = params.value.toString().split('.');

    parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');

    return parts.join('.');
};

const currencyFormatter = (params: any) => {
    return '$' + formatNumber(params);
};

const fourDecimalPlacesFormatter = (params: any) => {
    return params.value.toFixed(4);
};

const formatters: { [key: string]: (number: number) => void } = {
    thousandsSeparatorFormat: formatNumber,
    currencyFormat: currencyFormatter,
    fourDecimalPlacesFormat: fourDecimalPlacesFormatter
};

const useCreateGridColumns = (sheetData: { [key: string]: string | number }, report: ReportsType) => {
    const { currentUser } = useAuth();
    const accessGroup = currentUser?.app_metadata.accessGroup;

    const createdColumns = Object.keys(sheetData || {}).map((name) => {
        let hiddenForAccessGroup: any = [];
        let readonlyForAccessGroup: any = [];

        if (accessGroup) {
            hiddenForAccessGroup = report.accessGroup.columnHidden?.[accessGroup] || [];
            readonlyForAccessGroup = report.accessGroup.columnReadonly?.[accessGroup] || [];
        }

        const reportReadonly = (report.readOnly || []).concat(readonlyForAccessGroup);
        const reportHidden = (report.hidden || []).concat(hiddenForAccessGroup);

        return ({
            field: name,
            width: report.columnWidth ? report.columnWidth[name] : undefined,
            hide: reportHidden.includes(name),
            editable: !reportReadonly.includes(name),
            headerName: report.columnNames ? report.columnNames[name] : name,
            filter: report.filter ? report.filter[name] : 'agTextColumnFilter',
            filterParams: {
                buttons: ['reset', 'apply'],
                closeOnApply: true
            },
            type: report.valueFormatter && formatters[report.valueFormatter[name]] ? 'rightAligned' : null,
            valueFormatter: report.valueFormatter ? formatters[report.valueFormatter[name]] : undefined,
            cellEditor: report.cellEditor ? report.cellEditor[name] : 'agTextCellEditor',
            cellEditorPopup: report.cellEditor && report.cellEditor[name] === 'agLargeTextCellEditor',
            cellEditorParams: report.cellEditorParams && report.cellEditorParams[name.toLowerCase()]
                ? {
                    ...(report.cellEditorParams[name] || {}),
                    ...Object.keys(report.cellEditorParams).reduce((acc, paramKey) => {
                        if (report.cellEditorParams && report.cellEditorParams[paramKey]) {
                            acc = report.cellEditorParams[paramKey];
                        }

                        return acc || {};
                    }, {} as Record<string, any>)
                }
                : {}
        });
    });

    return createdColumns;
};

export default useCreateGridColumns;
