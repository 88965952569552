
import makeApiRequest, { useReactQueryRequest, UseApiRequestOptions } from '../makeApiCall';
import { OrgModel } from './types';

const GET_ORGANIZATIONS_QUERY_KEY = 'getOrganisations';

export async function changePassword(email: string) {
    return await makeApiRequest<any>('/User/changePassword', 'PUT', undefined, undefined, { email: email });
}

// Server should return OrgModel
export function useOrganisations({ ...options }: UseApiRequestOptions<OrgModel[]> = {}) {
    return useReactQueryRequest<OrgModel[]>(GET_ORGANIZATIONS_QUERY_KEY, '/user/getOrganisations', { ...options, staleTime: Infinity });
}
