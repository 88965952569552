import { GridApi } from 'ag-grid-community';
import { insertAircraftAirlineData } from 'app/api/airports/airports';
import { AircraftAirlineData } from 'app/api/airports/types';
import useToast from 'app/hooks/useToast';
import { useState } from 'react';

const useAddRow = (refetch: any, gridApi: GridApi | null) => {
    const { setNotification } = useToast();
    const [isLoading, setIsLoading] = useState(false);

    const handleAddRow = async () => {
        const newDataItem: AircraftAirlineData = {
            DISPLAY_CODE: '',
            AIRLINE_NAME: '',
            REFERENCE_CODE: '',
            SEATS: 0
        };

        try {
            setIsLoading(true);
            await insertAircraftAirlineData([newDataItem]);
            refetch();
            setNotification('Added a row', 'success');

            if (gridApi) {
                gridApi.paginationGoToLastPage();
            }
        } catch (error) {
            setNotification('Failed to add a row', 'error');
        } finally {
            setIsLoading(false);
        }
    };

    return { handleAddRow, isLoading };
};

export default useAddRow;
