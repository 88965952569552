import { useState } from 'react';
import { useForm, SubmitHandler } from 'react-hook-form';
import { Form } from 'react-bootstrap';
import { useAuth } from 'app/modules/auth';
import { sendMail } from 'app/api/mail/mail';
import useModalStore from 'app/store/modal';
import Typography from '../Typography';
import AppButton from '../Button/AppButton';

interface FormValues {
    subject: string;
    message: string;
    reportName: string;
    attachment: any
}

export default function EmailSupport() {
    const {
        register,
        handleSubmit,
        formState: { errors }
    } = useForm<FormValues>();
    const { currentUser } = useAuth();
    const [isSubmitting, setIsSubmitting] = useState(false);

    const { setModalContent } = useModalStore();

    const onSubmit: SubmitHandler<FormValues> = async (data) => {
        if (currentUser) {
            setIsSubmitting(true);

            try {
                const formData = new FormData();

                formData.append('emailFrom', currentUser.name);
                formData.append('emailSubject', `WEB-${data.subject}`);
                formData.append('emailBody', `Report/Dashboard name: ${data.reportName || 'Not specified'} \n\n${data.message}`);

                // Check if a file is uploaded and append it to the form data
                const fileInput = (document.querySelector("input[name='attachment']") as HTMLInputElement).files;

                if (fileInput && fileInput.length > 0) {
                    formData.append('attachment', fileInput[0]);
                }

                await sendMail(formData);
                setModalContent({ content: <Typography>Thank you for contacting Lime Support, a team member will be in touch soon.</Typography>, title: 'Email Sent' });
            } catch (error) {
                setModalContent({ content: <Typography>Failed to send support email. Please try again.</Typography>, title: 'Email Failed' });
            }
        }
    };

    return (
        <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Group className='mb-5' controlId='subject'>
                <Form.Label>Subject:</Form.Label>
                <Form.Control type='text' {...register('subject', { required: true })} />
                {errors.subject && <Form.Text className='text-danger'>This field is required</Form.Text>}
            </Form.Group>
            <Form.Group className='mb-5' controlId='subject'>
                <Form.Label>Dashboard/Report Name (Optional):</Form.Label>
                <Form.Control type='text' {...register('reportName')} />
            </Form.Group>
            <Form.Group className='mb-5' controlId='message'>
                <Form.Label>Message:</Form.Label>
                <Form.Control
                    as='textarea'
                    rows={5}
                    {...register('message', {
                        required: true,
                        minLength: 15,
                        maxLength: 4000
                    })} />
                {errors.message?.type === 'required' && (
                    <Form.Text className='text-danger'>This field is required</Form.Text>
                )}
                {errors.message?.type === 'minLength' && (
                    <Form.Text className='text-danger'>Message must be at least 15 characters long</Form.Text>
                )}
                {errors.message?.type === 'maxLength' && (
                    <Form.Text className='text-danger'>Message must be at most 4000 characters long</Form.Text>
                )}
            </Form.Group>
            <Form.Group className='mb-5' controlId='attachment'>
                <Form.Label>Attachment (Optional):</Form.Label>
                <Form.Control
                    type='file'
                    accept='image/*'
                    {...register('attachment')} />
            </Form.Group>
            <div className='d-flex flex-end'>
                <AppButton size='sm' disabled={isSubmitting} type='submit'>Submit</AppButton>
            </div>
        </Form>
    );
}
