import React from 'react';
import AppSelect from '../Select';
import './search.scss';

type Props = {
    className?: string;
    options: { label: string, value: any }[];
    searchValue: { label: string, value: any } | null;
    handleSearch: (data: any) => void;
}

const Search = ({ className, options, searchValue, handleSearch }: Props) => {
    return (
        <div className={`search ${className}`}>
            <i className='fa-solid fa-magnifying-glass' />
            <AppSelect
                className='app-select-search-container'
                classNamePrefix='app-select-search'
                onChange={(data) => handleSearch(data)}
                value={searchValue}
                components={{ IndicatorSeparator: () => null, DropdownIndicator: () => null }}
                placeholder='Search' options={options} />
        </div>
    );
};

export default Search;
