import { CSVLink } from 'react-csv';
import AppTooltip from 'app/components/Tooltip/Tooltip';
import Typography from 'app/components/Typography/Typography';
import './spreadsheet-export.scss';
import { useParams } from 'react-router-dom';

type Props = {
    parsedRawSheetData: any[];
    isSheetUpdated: boolean;
}

const SpreadsheetExport = ({ parsedRawSheetData, isSheetUpdated }: Props) => {
    const params = useParams();

    return (
        <div className='spreadsheet-export'>
            <div className='d-flex'>
                <AppTooltip className='w-200px' placement='bottom' isShow={isSheetUpdated && parsedRawSheetData.length !== 0}
                    TooltipContent={<Typography size='8'>There are unsaved changes in the sheet. Save sheet to enable export.</Typography>}>
                    <CSVLink
                        style={{
                            opacity: isSheetUpdated || parsedRawSheetData.length === 0 ? 0.5 : 1,
                            pointerEvents: isSheetUpdated || parsedRawSheetData.length === 0 ? 'none' : 'all'
                        }}
                        className='btn btn-sm btn-primary'
                        filename={params.reportKey}
                        data={parsedRawSheetData}>
                        <i className='fa-solid fa-file-csv' />
                        Export to CSV
                    </CSVLink>
                </AppTooltip>

            </div>

        </div>
    );
};

export default SpreadsheetExport;
