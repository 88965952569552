import * as Sentry from '@sentry/react';

const sentryConfig = {
    dsn: 'https://921944b84ae2bff7223026a47de563c7@o4506385704943616.ingest.sentry.io/4506511531966465',
    environment: process.env.REACT_APP_SENTRY_ENV,
    integrations: [
        new Sentry.BrowserTracing({
            // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
            tracePropagationTargets: ['localhost', 'bi.limeintel', /^https:\/\/yourserver\.io\/api/]
        }),
        new Sentry.Replay({
            maskAllText: false,
            blockAllMedia: false
        })
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, //  Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0 // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
};

export default sentryConfig;
