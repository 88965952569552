import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import AppSelect from 'app/components/Select/Select';

interface CustomSelectEditorProps {
    value: string;
    stopEditing: () => void;
    values: {
        label: string;
        value: string;
    }[];
    onCreateOption?: (inputValue: string) => void;
}

const CustomSelectEditor = forwardRef((props: CustomSelectEditorProps, ref) => {
    const [selectedOption, setSelectedOption] = useState<any>(null);

    useEffect(() => {
        const initialValue = props.values.find((data) => data.value === props.value) || { value: props.value, label: props.value };

        setSelectedOption({ value: initialValue?.value, label: initialValue?.label });
    }, [props.value]);

    useImperativeHandle(ref, () => ({
        getValue() {
            return selectedOption ? selectedOption.value : '';
        }
    }));

    const handleChange = (option: { label: string; value: string } | null) => {
        setSelectedOption(option);
    };

    return (
        <AppSelect
            menuPortalTarget={document.body}
            // @ts-ignore
            styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
            value={selectedOption}
            onChange={handleChange}
            options={props.values}
            onBlur={() => props.stopEditing()}
            onKeyDown={(e) => {
                if (e.key === 'Enter') {
                    props.stopEditing();
                }
            }} />
    );
});

export default CustomSelectEditor;
