import { ReactNode } from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { Placement } from 'react-bootstrap/esm/types';
import './tooltip.scss';

type Props = {
    children: JSX.Element;
    TooltipContent: ReactNode;
    placement?: Placement;
    sizes?: '2px' | '3px';
    isShow?: boolean;
    className?: string;
}

const AppTooltip = ({ children, placement = 'right', TooltipContent, className, isShow = true }: Props) => {
    const renderTooltip = (props: any) => (
        <Tooltip className={`app-tooltip ${className}`} id='button-tooltip' {...props}>
            {TooltipContent}
        </Tooltip>
    );

    if (isShow) {
        return (
            <OverlayTrigger placement={placement}
                overlay={renderTooltip}>
                <div>
                    {children}
                </div>
            </OverlayTrigger>
        );
    } else return children;
};

export default AppTooltip;
