export const DOWNLOAD_FILES = ['PDF', 'Image'] as const;

export const ORIENTATION = {
    HORIZONTAL: 'horizontal',
    VERTICAL: 'vertical'
};

export const NAV_VERSION = {
    VERSION_1: 'version1',
    VERSION_2: 'version2'
};

export const NAV_ORIENTATION_KEY = 'navOrientation';
export const NAV_VERSION_KEY = 'navVersion';
export const FILTER_VERSION_KEY = 'filterVersion';

export const FILTER_TYPES = {
    NUMBER: 'agNumberColumnFilter',
    DATE: 'agDateColumnFilter',
    TEXT: 'agTextColumnFilter',
    CUSTOM_TEXT: 'agCustomTextColumnFilter'
};

export const CELL_EDITOR_TYPES = {
    TIME: 'agTimeCellEditor',
    NUMBER: 'agNumberCellEditor',
    SELECT: 'agSelectCellEditor'
};

export const MONTHS = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
];
