import { useEffect, useMemo, useState } from 'react';
import useToast from 'app/hooks/useToast';
import AppButton from 'app/components/Button/AppButton';
import useConfigStore from 'app/store/userConfig';
import useRightDrawerStore from 'app/store/rightDrawer';
import Typography from 'app/components/Typography';
import { ReportsType } from 'app/api/reports/types';
import { ORIENTATION } from 'app/utils/constants';
import { updateMemberAirportsData, useGetMemberAirportsData, useGetMemberAirportsDataOptions } from 'app/api/aaan/aaan';
import { MemberAirports } from 'app/api/aaan/types';
import { findChangedObjects, updateRightDrawerData } from 'app/utils/helpers';
import AgGrid from './AgGrid';
import SpreadsheetExport from './SpreadsheetExport';
import useCreateAirportGridColumns from './hooks/useCreateMemberAirportsColumns';
import useAddRow from './hooks/useAddRow';
import useSpreadsheet from '../../hooks/useSpreadsheet';
import useDeleteRows from './hooks/useDeleteRows';

import './spreadsheet-grid-member-airports.scss';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

type Props = {
    report: ReportsType;
    className?: string;
}

const SpreadsheetMemberAirportsReference = ({ report, className }: Props) => {
    const [isSaving, setIsSaving] = useState(false);
    const [freshData, setFreshData] = useState<MemberAirports[]>([]);

    const { setRightDrawerContent, rightDrawerContent } = useRightDrawerStore();
    const { setNotification } = useToast();
    const { navbarOrientation } = useConfigStore();

    const { data: dataOptions, refetch: refetchDataOptions } = useGetMemberAirportsDataOptions(
        {
            refetchOnWindowFocus: false,
            refetchOnReconnect: false
        });

    const { data: sheetDataResponse, isLoading: isSpreadsheetDataLoading, refetch } = useGetMemberAirportsData(
        {
            refetchOnWindowFocus: false,
            refetchOnReconnect: false,
            onSuccess: (data) => {
                setFreshData(data.data.data);
            }
        });

    const { gridApi, pageSize, isSheetUpdated, setIsSheetUpdated, handleGridReady, onPageSizeChanged, onCellValueChanged } = useSpreadsheet(isSpreadsheetDataLoading);

    const sheetData: MemberAirports[] = useMemo(() => sheetDataResponse ? JSON.parse(JSON.stringify(sheetDataResponse.data.data)) : [], [sheetDataResponse]);

    const createdColumns = useCreateAirportGridColumns(sheetData[0] || {}, report);

    const columnNames = useMemo(() => createdColumns, [sheetData, report]);
    const dataOptionsMemo = useMemo(() => dataOptions ? dataOptions.data.data : undefined, [dataOptions]);

    const handleRefetchData = async () => {
        await refetch();
        await refetchDataOptions();
    };

    const { handleDeleteModal } = useDeleteRows(gridApi, handleRefetchData);
    const { handleAddRow, isLoading: isLoadingAddRow } = useAddRow(refetch, gridApi);

    useEffect(() => {
        const rightDrawerContentUpdate = [{
            keyName: 'actions',
            isShow: false,
            content: () => <Typography>No Available Actions</Typography>
        }, {
            keyName: 'export',
            isShow: true,
            content: () => <SpreadsheetExport sheetData={sheetData} isSheetUpdated={isSheetUpdated} />
        }, {
            keyName: 'filters',
            isShow: false,
            content: () => <div />
        }];

        const updatedContent = updateRightDrawerData(rightDrawerContentUpdate, rightDrawerContent);

        setRightDrawerContent(updatedContent);
    }, [isSheetUpdated, sheetData]);

    const handleSaveSpreadsheet = async () => {
        setIsSaving(true);

        const updatedValues: MemberAirports[] = [];

        if (gridApi) {
            gridApi.forEachNode((node) => {
                const updatedValue = node.data;

                updatedValues.push(updatedValue);
            });
        }

        try {
            const changedObjects = findChangedObjects<MemberAirports>(freshData, updatedValues);

            const updateXeroReference = changedObjects.map((data) => {
                const contactId = dataOptionsMemo!.XERO_CONTACTS.find((option) => option.XERO_CONTACT_NAME === data.XERO_CONTACT_NAME)?.XERO_CONTACT_ID || data.XERO_REFERENCE;

                return { ...data, XERO_REFERENCE: contactId };
            });

            await updateMemberAirportsData(updateXeroReference);
            refetchDataOptions();
            setNotification('Sheet saved', 'success');

            setIsSheetUpdated(false);
        } catch (error: any) {
            if (error.response && error.response.data && error.response.data.message) {
                setNotification(error.response.data.message, 'error');
            } else {
                setNotification('Failed to update spreadsheet', 'error');
            }
        } finally {
            setIsSaving(false);
        }
    };

    const handleAddRowGrid = async () => await handleAddRow();

    return (
        <div>
            <div className='d-flex justify-content-between align-items-center mb-3'>
                <Typography className='w-100 text-left' weight='bolder' size='2x'>{report.reportName}</Typography>
            </div>
            <div className={`spreadsheet-grid-member-airports card w-100 ${className}`}>
                <div className='grid-control'>
                    <div className='d-flex justify-content-end gap-3' />
                    <div className='d-flex justify-content-end gap-3'>
                        <AppButton size='sm'
                            onClick={() => handleDeleteModal(gridApi ? gridApi.getSelectedRows() : [])}>Delete Row</AppButton>
                        <AppButton size='sm'
                            disabled={isLoadingAddRow}
                            onClick={handleAddRowGrid}>Add Row</AppButton>
                        <AppButton disabled={isSaving || !isSheetUpdated} isLoading={isSaving} size='sm'
                            onClick={handleSaveSpreadsheet}>
                            Save
                        </AppButton>
                    </div>
                </div>
                <div className={`ag-theme-alpine ${navbarOrientation === ORIENTATION.VERTICAL ? 'vertical' : 'horizontal'}`}>
                    <AgGrid
                        sheetDataOptions={dataOptionsMemo}
                        pageSize={pageSize}
                        sheetData={[...sheetData]}
                        handleGridReady={handleGridReady}
                        columnDefs={columnNames}
                        onCellValueChanged={onCellValueChanged} />
                    <div className='d-flex align-items-center justify-content-end my-2'>
                        <p className='fw-semibold fs-4 m-0 me-2'>Page Size:</p>
                        <select value={pageSize} onChange={onPageSizeChanged} id='page-size'>
                            <option value='dynamic'>Dynamic</option>
                            <option value='50'>50</option>
                            <option value='100'>100</option>
                            <option value='300'>300</option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SpreadsheetMemberAirportsReference;
