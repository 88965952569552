import { useNavigate, useParams } from 'react-router-dom';
import { MenuItem } from './MenuItem';
import DropDown from 'app/components/DropDown';
import AppPlaceholder from 'app/components/Placeholder/Placeholder';
import ReportsNav from 'app/modules/reports-nav';
import { ReportsType } from 'app/api/reports/types';
import { formatReportGroup, removeNumber } from 'app/utils/helpers';
import useFavouriteReport from 'app/hooks/useFavouriteReport';

type Props = {
    formattedMenu: {
        order: number;
        name: string;
        data: {
            [key: string]: ReportsType[];
        };
    }[];
}

export function MenuInner({ formattedMenu }: Props) {
    const navigate = useNavigate();
    const params = useParams();
    const { favouriteReports, handleFavouriteReport } = useFavouriteReport();

    const handleNavigate = (data: { label: string, value: ReportsType }) => {
        navigate(`organisation/${params.organisationId}/report/${data.value.key}`);
    };

    return (
        <>
            {formattedMenu
                ? formattedMenu.map((module) => {
                    return <div key={module.name} className='p-3 d-flex align-items-center'>
                        <DropDown
                            contentClassName='h-reports-dropdown w-100 mw-1350px h-100 mh-850px p-0'
                            drop='down'
                            toggle={<MenuItem title={removeNumber(module.name)} />}>
                            <ReportsNav moduleName={removeNumber(module.name)} handleNavigate={handleNavigate}
                                reportGroup={formatReportGroup(module.data)} favouriteReports={favouriteReports} handleFavouriteReport={handleFavouriteReport} />
                        </DropDown>
                    </div>;
                })
                : <AppPlaceholder className='h-30px' customSize='xs' numberOfPlaceholder={1}
                    xs={12}
                    md={12}
                    lg={12}
                    xl={12} />}
        </>
    );
}
