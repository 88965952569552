import React, { ReactNode } from 'react';
import clsx from 'clsx';
import './tabs.scss';
import useWhiteLabel from 'app/hooks/useWhiteLabel';

type Props = {
    tabItems: Array<{
        key: string;
        tabName: string;
        tabLeftElement?: ReactNode | string;
    }>,
    activeTab: string;
    orientation?: 'horizontal' | 'vertical';
    onTabChange: (key: any) => void;
}

const Tabs = ({ tabItems, orientation = 'horizontal', activeTab, onTabChange }: Props) => {
    const whiteLabelOrg = useWhiteLabel();

    return (
        <div className='tabs'>
            <div className='card-toolbar'>
                <div className={`${orientation === 'vertical' ? 'flex-column' : 'd-flex'} menu-rounded menu-title-gray-700 menu-icon-muted menu-active-bg menu-state-primary fw-semibold fs-base w-175px`}>
                    {tabItems.map((tab) => {
                        return <div key={tab.key} onClick={() => onTabChange(tab.key)} className='menu-item px-3 my-0'>
                            <a
                                href='#'
                                className={`menu-link -${whiteLabelOrg || 'primary'} menu-link px-3 py-2 text-nowrap ${tab.key === activeTab ? 'active' : ''}`}>
                                {tab.tabLeftElement}
                                <span className='menu-title'>{tab.tabName}</span>
                            </a>
                        </div>;
                    })}
                </div>
            </div>
        </div>
    );
};

export default Tabs;
