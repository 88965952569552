import { ReportsType } from 'app/api/reports/types';
import { RightDrawerItem } from 'app/store/rightDrawer';

type UpdateItem = {
    keyName: string;
    content: () => React.ReactNode;
    isShow: boolean;
};

export const groupBy = <T, K extends keyof any>(arr: T[], key: (i: T) => K) => {
    return arr.reduce((groups, item) => {
        (groups[key(item)] ||= []).push(item);

        return groups;
    }, {} as Record<K, T[]>);
};

export const trimSpace = (str: string) => str.replace(/^\s+/g, '');

export const capitalizeFirstLetter = (string: string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
};

export const checkIfValueExists = (obj: any, value: string) => Object.values(obj).includes(value);

export const formatReportGroup = (reportGroup: {
    [key: string]: ReportsType[];
}) => {
    return Object.keys(reportGroup).map((key, i) => {
        return { order: parseInt(key.substring(0, key.indexOf('.'))), name: key, data: reportGroup[key].sort((a, b) => a.order - b.order) };
    }).sort((a, b) => a.order - b.order);
};

export const removeNumber = (str: string) => {
    return str.replace(/^[0-9]+\.\s?/, '');
};

export const findChangedObjects = <T>(oldArray: T[], newArray: T[]): T[] => {
    const changedObjects: T[] = [];

    const maxLength = Math.max(oldArray.length, newArray.length);

    for (let i = 0; i < maxLength; i++) {
        const oldObj = oldArray[i];
        const newObj = newArray[i];
        const changedProps: any = {};

        if (!oldObj || !newObj) {
            changedObjects.push(newObj);
            continue;
        }

        for (const key in newObj) {
            if (oldObj[key] !== newObj[key]) {
                changedProps[key] = newObj[key];
            }
        }

        if (Object.keys(changedProps).length > 0) {
            changedObjects.push({ ...newObj, ...changedProps });
        }
    }

    return changedObjects;
};

export const findChangedFields = <T>(oldArray: T[], newArray: T[]): (T & { changedFields?: Partial<T> })[] => {
    const changedObjects: (T & { changedFields?: Partial<T> })[] = [];

    const maxLength = Math.max(oldArray.length, newArray.length);

    for (let i = 0; i < maxLength; i++) {
        const oldObj = oldArray[i];
        const newObj = newArray[i];
        const changedFields: Partial<T> = {};

        if (!oldObj || !newObj) {
            changedObjects.push({ ...newObj, changedFields: newObj });
            continue;
        }

        for (const key in newObj) {
            if (Object.prototype.hasOwnProperty.call(newObj, key)) {
                const typedKey = key as keyof T;

                if (oldObj[typedKey] !== newObj[typedKey]) {
                    changedFields[typedKey] = newObj[typedKey];
                }
            }
        }

        if (Object.keys(changedFields).length > 0) {
            changedObjects.push({ ...newObj, changedFields });
        }
    }

    return changedObjects;
};

export const deepCopy = <T>(obj: T): T => {
    if (typeof obj !== 'object' || obj === null) {
        return obj;
    }

    if (Array.isArray(obj)) {
        const copyArr = obj.map(item => deepCopy(item));

        return copyArr as unknown as T;
    }

    const copyObj: any = {};

    for (const key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
            copyObj[key] = deepCopy(obj[key]);
        }
    }

    return copyObj as T;
};

export const addUnderscoreToFieldName = (data: { [key: string]: string }): { [key: string]: string } => {
    const newData = { ...data };

    const transformedData: { [key: string]: string } = {};

    for (const key in newData) {
        if (Object.hasOwnProperty.call(newData, key)) {
            transformedData['_' + key] = newData[key];
        }
    }

    return transformedData;
};

export const updateRightDrawerData = (updates: UpdateItem[], rightDrawerContent: RightDrawerItem[] | []) => {
    const updatedContent = rightDrawerContent.map((item) => {
        const update = updates.find((u) => u.keyName === item.keyName);

        if (update) {
            return {
                ...item,
                content: update.content,
                isShow: update.isShow
            };
        }

        return item;
    });

    return updatedContent;
};

export const getCurrentDate = () => {
    const currentDate = new Date();

    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed, so add 1
    const day = String(currentDate.getDate()).padStart(2, '0');

    const formattedDate = `${year}-${month}-${day}`;

    return formattedDate;
};

export const getCurrentDateAndTime = () => {
    const currentDate = new Date();

    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, '0');
    const day = String(currentDate.getDate()).padStart(2, '0');
    const hours = String(currentDate.getHours()).padStart(2, '0');
    const minutes = String(currentDate.getMinutes()).padStart(2, '0');
    const seconds = String(currentDate.getSeconds()).padStart(2, '0');

    return `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
};

export const getTableauServerURL = () => {
    let domain = '';

    if (window.location.origin.includes('localhost') || window.location.origin.includes('limeintel')) {
        domain = 'limeintel.com';
    } else if (window.location.origin.includes('cargonetwork')) {
        domain = 'cargonetwork.com.au';
    }

    // Construct the new URL with tableau2 subdomain
    const newUrl = `https://tableau2.${domain}`;

    return newUrl;
};
