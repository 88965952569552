import React from 'react';
import './typography.scss';
import { TypographySize, TypographyWeight } from './typography.types';

type Props = {
    className?: string,
    component?: 'span' | 'p',
    children: any,
    color?: string,
    size?: TypographySize,
    weight?: TypographyWeight,
    iconLeft?: any,
    iconRight?: any,
    iconLeftSize?: string,
    iconRightSize?: string,
    props?: any,
    onClick?: any
}

export default function Typography({
    component = 'p',
    className = '',
    color = 'base',
    size = '5',
    weight = 'normal',
    iconLeft,
    iconRight,
    iconLeftSize = 'sm',
    iconRightSize = 'sm',
    children,
    onClick,
    ...props
}: Props): JSX.Element {
    const Component = component;

    return (
        <Component onClick={onClick || null} {...props} className={`${className} typography ${iconLeft || iconRight ? 'd-flex align-items-center' : ''} fs-${size} color-${color} fw-${weight}`} >
            {iconLeft && <img src={iconLeft} className={`typography-icon size-${iconLeftSize} me-2`} alt='typography-icon-left' />}
            {children}
            {iconRight && <img src={iconRight} className={`typography-icon size-${iconRightSize} me-2`} alt='typography-icon-right' />}
        </Component>
    );
}
