import { useMemo } from 'react';
import { ReportsType } from 'app/api/reports/types';
import { groupBy } from 'app/utils/helpers';

const useFormatMenu = (reportsData: ReportsType[]) => {
    const formatMenu = (dataToFormat: ReportsType[]) => {
        if (!dataToFormat) return;

        // const spreadsheetReports: ReportsType[] = [];
        // const otherReports: ReportsType[] = [];

        // for (const report of dataToFormat) {
        //     if (report.type === 'spreadsheet') {
        //         spreadsheetReports.push(report);
        //     } else {
        //         otherReports.push(report);
        //     }
        // }

        // let filteredSpreadsheetReports: ReportsType[] = [];

        // if (currentUser?.app_metadata.accessGroup) {
        //     filteredSpreadsheetReports = dataToFormat.filter(
        //         (data) =>
        //             data.accessGroup?.pageAccess?.includes(currentUser.app_metadata.accessGroup || '')
        //     );
        // } else {
        //     filteredSpreadsheetReports = dataToFormat;
        // }

        // const mergedArray = otherReports.concat(filteredSpreadsheetReports);

        const firstFormat = groupBy(dataToFormat || [], (data) => data.module);

        const finalFormat = {} as { [key: string]: { [key: string]: ReportsType[] } };

        for (const key in firstFormat) {
            finalFormat[key] = groupBy(firstFormat[key], (data) => data.group);
        }

        // return finalFormat;
        return Object.keys(finalFormat).map((key, i) => {
            return { order: parseInt(key.substring(0, key.indexOf('.'))), name: key, data: finalFormat[key] };
        }).sort((a, b) => a.order - b.order);
    };

    const formattedMenu = useMemo(() => formatMenu(reportsData), [reportsData]);

    return { formattedMenu };
};

export default useFormatMenu;
