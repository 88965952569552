import React, { useState } from 'react';
import { useParams } from 'react-router-dom';

const useFavouriteReport = () => {
    const params = useParams();

    const [favouriteReports, setFavouriteReports] = useState<string[]>(JSON.parse(localStorage.getItem(`favourites-${params.organisationId}`) || '[]'));

    const handleFavouriteReport = (e: React.MouseEvent<HTMLElement, MouseEvent>, reportKey: string) => {
        e.preventDefault();
        e.stopPropagation();

        let favouriteReportsCopy = [...favouriteReports];

        if (!favouriteReports.includes(reportKey)) {
            favouriteReportsCopy = [...favouriteReportsCopy, reportKey];
        } else {
            const index = favouriteReportsCopy.indexOf(reportKey);

            if (index > -1) {
                favouriteReportsCopy.splice(index, 1);
            }
        }

        setFavouriteReports(favouriteReportsCopy);
        localStorage.setItem(`favourites-${params.organisationId}`, JSON.stringify(favouriteReportsCopy));
    };

    return { handleFavouriteReport, favouriteReports };
};

export default useFavouriteReport;
