// ReusableInput.tsx
import React from 'react';
import { Form } from 'react-bootstrap';
import './app-input.scss';

interface AppInputProps extends React.ComponentProps<typeof Form.Control> {
    label?: string;
    errorMessage?: string;
}

const AppInput: React.FC<AppInputProps> = ({
    label,
    errorMessage,
    ...props
}) => {
    return (
        <Form.Group className='app-input' controlId={`form${props.name}`}>
            {label && <Form.Label>{label}</Form.Label>}
            <Form.Control
                {...props}
                isInvalid={!!errorMessage} />
            {errorMessage && <Form.Control.Feedback type='invalid'>
                {errorMessage}
            </Form.Control.Feedback>}
        </Form.Group>
    );
};

export default AppInput;
