import React, { useMemo } from 'react';
import { GridReadyEvent } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';

import { ICSSClientBudget } from 'app/api/icss/types';

type Props = {
    pageSize: string;
    sheetData: any[];
    sheetDataOptions: Omit<ICSSClientBudget, 'YEARLY_BUDGETS'>[];
    columnDefs: { [key: string]: any }[]
    handleGridReady: (params: GridReadyEvent) => void;
    onCellValueChanged: () => void;
}

const AgGrid = ({ pageSize, sheetData, columnDefs, sheetDataOptions, handleGridReady, onCellValueChanged }: Props) => {
    const defaultColDef = useMemo(() => ({
        filter: true,
        sortable: true,
        resizable: true
    }), []);

    const updateCustomComponents = () => {
        const updatedData = [...columnDefs];

        columnDefs.forEach((item, index) => {
            if (item.field === 'EMPLOYEE_NAME') {
                updatedData[index] = {
                    ...updatedData[index],
                    cellEditorParams: {
                        values: sheetDataOptions.length > 0 ? sheetDataOptions.map((data: any) => `${data.EMPLOYEE_ID}-${data.EMPLOYEE_NAME}`).sort() : []
                    },
                    editable: (params: any) => !params.data.IS_SAVED
                };
            }
        });

        return updatedData;
    };

    const dynamicYearKeys = useMemo(() => {
        const keys = sheetData.length > 0 ? Object.keys(sheetData[0]) : [];

        return keys.filter(key => key.startsWith('YEAR_'));
    }, [sheetData]);

    const calculateTotals = () => {
        const totals: any = { EMPLOYEE_NAME: 'Totals' }; // Initialize with a label for the totals row

        dynamicYearKeys.forEach((yearKey: any) => {
            totals[yearKey] = sheetData.reduce((total, row) => total + (row[yearKey] || 0), 0);
        });

        return [totals];
    };

    const pinnedTopRowData = useMemo(() => calculateTotals(), [sheetData, dynamicYearKeys]);

    return (
        <AgGridReact
            overlayLoadingTemplate={'<span class="ag-overlay-loading-center">Loading spreadsheet...</span>'}
            pagination={true}
            paginationAutoPageSize={pageSize === 'dynamic'}
            suppressMenuHide={true}
            animateRows={true}
            singleClickEdit={true}
            rowSelection='multiple'
            pinnedTopRowData={pinnedTopRowData}
            onCellEditingStarted={(params) => {
                // Prevent editing of pinned top row
                if (params.node.rowPinned) {
                    params.api.stopEditing();
                }
            }}
            suppressColumnVirtualisation={true}
            stopEditingWhenCellsLoseFocus={true}
            undoRedoCellEditing={true}
            undoRedoCellEditingLimit={10}
            onGridReady={handleGridReady}
            defaultColDef={defaultColDef}
            onCellValueChanged={onCellValueChanged}
            rowData={sheetData}
            columnDefs={updateCustomComponents()} />
    );
};

export default React.memo(AgGrid);
