import { useEffect, useRef, useState } from 'react';
import useTableauStore, { TableauFilterType, TableauParamsType } from 'app/store/tableau';
import { DOWNLOAD_FILES } from 'app/utils/constants';
import useToast from './useToast';

const useTableau = () => {
    const { viz, parameters, filters, relatedReports, activeSheet, sheetsToExport, setViz, setRelatedReports, setActiveSheet, setParameters, setFilters, setSheetsToExport } = useTableauStore();
    const [isFiltersResetLoading, setIsFiltersResetLoading] = useState(false);

    const ref = useRef<HTMLDivElement | null>(null);
    const { setNotification } = useToast();

    useEffect(() => {
        let intervalId: NodeJS.Timeout;

        if (viz) {
            intervalId = setInterval(() => {
                viz.refreshDataAsync();
            }, 900000);
        }

        return () => {
            clearInterval(intervalId);
        };
    }, [viz]);

    const handleSheetChange = async (key: string) => {
        try {
            setRelatedReports(null);
            await viz?.getWorkbook().activateSheetAsync(key);
            getTableauParameters(viz?.getWorkbook()!);
            getTableauFilters(viz?.getWorkbook()!);
            setActiveSheet(key);
            setSheetsToExport([]);
        } catch (error) {
            setNotification('Failed to load sheet', 'error');
        }
    };

    const getTableauParameters = async (workbook: tableau.Workbook) => {
        try {
            const parameters = await workbook.getParametersAsync();

            const paramArr: TableauParamsType[] = [];

            parameters.forEach((param) => {
                paramArr.push({
                    name: param.getName(),
                    options: param.getAllowableValues(),
                    currentValue: param.getCurrentValue(),
                    type: param.getAllowableValuesType(),
                    minValue: param.getMinValue(),
                    maxValue: param.getMaxValue(),
                    stepSize: param.getStepSize()
                });
            });

            setParameters(paramArr);
        } catch (error) {
            setNotification('Failed to load filters', 'error');
        }
    };

    const getTableauFilters = async (workbook: tableau.Workbook) => {
        try {
            // @ts-ignore
            const worksheets = workbook.getActiveSheet().getWorksheets();
            const filtersObj = {} as TableauFilterType;

            const filtersToDisplay = await worksheets[0].getFiltersAsync();

            filtersToDisplay.forEach(async (filter: any) => {
                if (typeof filter?.getAppliedValues === 'function') {
                    const appliedValues = filter.getAppliedValues() ? filter.getAppliedValues().filter((values: any) => values.value) : [];

                    filtersObj[filter.getFieldName()] = { options: appliedValues || [], values: null };
                }
            });

            setFilters(filtersObj);

            const withExportSheet = worksheets.filter((filter: any) => {
                const fname = filter.getName();

                return fname.includes('Export');
            });

            setSheetsToExport(withExportSheet || []);
        } catch (error) {
            setNotification('Failed to load filters', 'error');
        }
    };

    const handleParametersChange = async (name: string, data: { value: any, formattedValue: any }, isNotify = true) => {
        try {
            if (!parameters) return;

            const updatedParameters = parameters.map((param) => param.name === name
                ? { ...param, currentValue: { value: data.value, formattedValue: data.formattedValue } }
                : param);

            setParameters(updatedParameters);
            await viz?.getWorkbook().changeParameterValueAsync(name, data.formattedValue);

            if (isNotify) {
                setNotification('Filter applied', 'success');
            }
        } catch (error) {
            setNotification('Failed to apply filter', 'error');
        }
    };

    const handleFiltersChange = async (name: string, data: any, isNotify = true) => {
        try {
            const filtersCopy = { ...filters };

            filtersCopy[name] = { ...filtersCopy[name], values: filtersCopy[name].options.length === data.length ? null : data.map((data: any) => ({ formattedValue: data.label, value: data.value })) };
            setFilters(filtersCopy);
            // @ts-ignore
            await viz?.getWorkbook().getActiveSheet().getWorksheets()[0].applyFilterAsync(name, data.map((d) => d.label), tableau.FilterUpdateType.REPLACE);

            if (isNotify) {
                setNotification('Filter applied', 'success');
            }
        } catch (error) {
            setNotification('Failed to apply filter', 'error');
        }
    };

    const handleDownload = (type: typeof DOWNLOAD_FILES[number]) => {
        if (type === 'PDF') {
            viz?.showExportPDFDialog();
        } else if (type === 'Image') {
            viz?.showExportImageDialog();
        } else if (type === 'Tableau Workbook') {
            viz?.showDownloadWorkbookDialog();
        }

        setNotification(`${type} Downloaded`, 'success');
    };

    const handleExportExcel = async (sheetName: string) => {
        try {
            // @ts-ignore
            await viz?.exportCrossTabToExcel(sheetName);
            setNotification('Excel file exported', 'success');
        } catch (error) {
            setNotification('Failed to export excel file', 'error');
        }
    };

    const handleResetFilters = async () => {
        try {
            setIsFiltersResetLoading(true);

            await viz?.getWorkbook().revertAllAsync();
            await getTableauParameters(viz?.getWorkbook()!);
            await getTableauFilters(viz?.getWorkbook()!);
            setNotification('Filters reset', 'success');
        } catch (error) {
            setNotification('Failed to reset filters', 'error');
        } finally {
            setIsFiltersResetLoading(false);
        }
    };

    return {
        ref,
        parameters,
        filters,
        relatedReports,
        activeSheet,
        isFiltersResetLoading,
        sheetsToExport,
        viz,
        setViz,
        handleSheetChange,
        handleFiltersChange,
        handleParametersChange,
        handleDownload,
        handleResetFilters,
        handleExportExcel,
        getTableauFilters,
        getTableauParameters,
        setActiveSheet,
        setRelatedReports
    };
};

export default useTableau;
