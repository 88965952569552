import { useState } from 'react';
import { GridApi } from 'ag-grid-community';
import { insertMemberAirportsData } from 'app/api/aaan/aaan';
import { MemberAirports } from 'app/api/aaan/types';
import useToast from 'app/hooks/useToast';

const useAddRow = (refetch: any, gridApi: GridApi | null) => {
    const { setNotification } = useToast();
    const [isLoading, setIsLoading] = useState(false);

    const handleAddRow = async () => {
        const newDataItem: MemberAirports = {
            ICAO: '',
            LOCATION: '',
            NOTES: '',
            XERO_REFERENCE: ''
        };

        try {
            setIsLoading(true);
            await insertMemberAirportsData([newDataItem]);
            refetch();
            setNotification('Added a row', 'success');

            if (gridApi) {
                gridApi.paginationGoToLastPage();
            }
        } catch (error) {
            setNotification('Failed to add a row', 'error');
        } finally {
            setIsLoading(false);
        }
    };

    return { handleAddRow, isLoading };
};

export default useAddRow;
