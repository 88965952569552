import { useEffect, useMemo } from 'react';
import AppButton from 'app/components/Button/AppButton';
import useConfigStore from 'app/store/userConfig';
import useRightDrawerStore from 'app/store/rightDrawer';
import Typography from 'app/components/Typography';
import { ReportsType } from 'app/api/reports/types';
import { ORIENTATION } from 'app/utils/constants';
import { useGetMemberPaymentsData } from 'app/api/aaan/aaan';
import { MemberPayments } from 'app/api/aaan/types';
import useCreateAAANColumns from '../useCreateAAANColumns';
import { updateRightDrawerData } from 'app/utils/helpers';
import AgGrid from './AgGrid';
import SpreadsheetExport from './SpreadsheetExport';
import useSpreadsheet from '../../hooks/useSpreadsheet';

import '../spreadsheet.scss';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import useUpdateRows from './hooks/useUpdateRows';

type Props = {
    report: ReportsType;
    className?: string;
}

const SpreadsheetMemberPayments = ({ report, className }: Props) => {
    const { setRightDrawerContent, rightDrawerContent } = useRightDrawerStore();
    const { navbarOrientation } = useConfigStore();

    const { data: sheetDataResponse, isLoading: isSpreadsheetDataLoading, refetch } = useGetMemberPaymentsData(
        {
            refetchOnWindowFocus: false,
            refetchOnReconnect: false

        });

    const { gridApi, pageSize, isSheetUpdated, handleGridReady, onPageSizeChanged, onCellValueChanged } = useSpreadsheet(isSpreadsheetDataLoading);

    const sheetData: MemberPayments[] = useMemo(() => sheetDataResponse ? JSON.parse(JSON.stringify(sheetDataResponse.data.data)) : [], [sheetDataResponse]);

    const createdColumns = useCreateAAANColumns(sheetData[0] || {}, report);

    const columnNames = useMemo(() => createdColumns, [sheetData, report]);

    const { handleUpdateRows } = useUpdateRows(refetch);

    useEffect(() => {
        const rightDrawerContentUpdate = [{
            keyName: 'actions',
            isShow: false,
            content: () => <Typography>No Available Actions</Typography>
        }, {
            keyName: 'export',
            isShow: true,
            content: () => <SpreadsheetExport sheetData={sheetData} isSheetUpdated={isSheetUpdated} />
        }, {
            keyName: 'filters',
            isShow: false,
            content: () => <div />
        }];

        const updatedContent = updateRightDrawerData(rightDrawerContentUpdate, rightDrawerContent);

        setRightDrawerContent(updatedContent);
    }, [isSheetUpdated, sheetData]);

    return (
        <div>
            <div className='d-flex justify-content-between align-items-center mb-3'>
                <Typography className='w-100 text-left' weight='bolder' size='2x'>{report.reportName}</Typography>
            </div>
            <div className={`spreadsheet card w-100 ${className}`}>
                <div className='grid-control'>
                    <div className='d-flex justify-content-end gap-3'>
                        <AppButton size='sm'
                            onClick={() => handleUpdateRows(gridApi ? gridApi.getSelectedRows() : [])}>Process Payments</AppButton>
                    </div>
                    <div className='d-flex justify-content-end gap-3' />
                </div>
                <div className={`ag-theme-alpine ${navbarOrientation === ORIENTATION.VERTICAL ? 'vertical' : 'horizontal'}`}>
                    <AgGrid
                        pageSize={pageSize}
                        sheetData={[...sheetData]}
                        handleGridReady={handleGridReady}
                        columnDefs={columnNames}
                        onCellValueChanged={onCellValueChanged} />
                    <div className='d-flex align-items-center justify-content-end my-2'>
                        <p className='fw-semibold fs-4 m-0 me-2'>Page Size:</p>
                        <select value={pageSize} onChange={onPageSizeChanged} id='page-size'>
                            <option value='dynamic'>Dynamic</option>
                            <option value='50'>50</option>
                            <option value='100'>100</option>
                            <option value='300'>300</option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SpreadsheetMemberPayments;
