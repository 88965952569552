import AccountMenu from 'app/modules/account-menu/AccountMenu';
import './sign-out-nav.scss';
import { KTSVG } from '_metronic/helpers';
import MetronicDropdown from 'app/components/MetronicDropdown';
import AppTooltip from 'app/components/Tooltip';
import Typography from 'app/components/Typography';
import clsx from 'clsx';
import useWhiteLabel from 'app/hooks/useWhiteLabel';

type Props = {}

// const userAvatarClass = 'symbol-35px symbol-md-40px';

const SignoutNav = (props: Props) => {
    const whiteLabelOrg = useWhiteLabel();

    const btnIconClass = `svg-icon-1 btn btn-icon btn-custom btn-icon-muted btn-active-light btn-active-color-${whiteLabelOrg || 'primary'} mr-10`;

    return (
        <div className='signout-nav'>
            <div className='app-navbar justify-content-end h-100'>
                <MetronicDropdown className={clsx('app-navbar-item w-35px h-35px w-md-80px h-md-40px')} toggleElement={<AppTooltip placement='bottom' TooltipContent={<Typography>Account</Typography>}>
                    <KTSVG path='/media/icons/duotune/communication/com006.svg' className={btnIconClass} />
                </AppTooltip>}>
                    <AccountMenu />
                </MetronicDropdown>
            </div>
        </div>
    );
};

export default SignoutNav;
