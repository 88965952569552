import { KTSVG } from '_metronic/helpers';
import { ReactNode } from 'react';

type Props = {
    data: {
        content: () => ReactNode;
        keyName: string;
        title: string;
        isShow: boolean;
        otherAction?: () => void;
    }[]
}

const RightDrawer = ({ data }: Props) => {
    return (
        <>
            <div className='z-index-3 engage-toolbar d-flex position-fixed px-5 fw-bolder top-50 end-0 transform-90 mt-20 gap-2'>
                {data.map(({ keyName, title, isShow, otherAction }) => {
                    return <button
                        onClick={otherAction || undefined}
                        key={title}
                        id={`kt_engage_${keyName}_toggle`}
                        className={`engage-demos-toggle btn btn-flex h-35px bg-body btn-color-gray-700 btn-active-color-gray-900 shadow-sm fs-6 px-4 rounded-top-0 ${!isShow && 'd-none'}`}
                        data-bs-toggle='tooltip'
                        data-bs-placement='left'
                        data-bs-dismiss='click'
                        data-bs-trigger='hover'>
                        <span id={`kt_engage_${keyName}_label`}>{title}</span>
                    </button>;
                })}
            </div>
            {data.map(({ keyName, title, content, otherAction }) => {
                if (!otherAction && content) {
                    return <div id={`kt_engage_${keyName}`}
                        key={title}
                        className='bg-body'
                        data-kt-drawer='true'
                        data-kt-drawer-name='explore'
                        data-kt-drawer-activate='true'
                        data-kt-drawer-overlay='true'
                        data-kt-drawer-width="{default:'350px'}"
                        data-kt-drawer-direction='end'
                        data-kt-drawer-toggle={`#kt_engage_${keyName}_toggle`}
                        data-kt-drawer-close={`#kt_engage_${keyName}_close`}>
                        <div className='card shadow-none w-100'>
                            <div className='card-header' id='kt_explore_header'>
                                <h5 className='card-title fw-bold text-gray-600'>
                                    {title}
                                </h5>

                                <div className='card-toolbar'>
                                    <button
                                        type='button'
                                        className='btn btn-sm btn-icon explore-btn-dismiss me-n5'
                                        id={`kt_engage_${keyName}_close`}>
                                        <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-2' />
                                    </button>
                                </div>
                            </div>
                            <div className='card-body' id='kt_explore_body'>
                                {content()}
                            </div>
                        </div>
                    </div>;
                }
            })}

        </>
    );
};

export default RightDrawer;
