import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import { fileUpload } from 'app/api/processing/processing';
import useToast from 'app/hooks/useToast';
import useModalStore from 'app/store/modal';
import { ButtonConfig } from '../buttonConfig';
import { useNavigate, useParams } from 'react-router-dom';
import AppButton from 'app/components/Button/AppButton';

type Props = {
    config: ButtonConfig;
}
type FileEvent = React.ChangeEvent<HTMLInputElement>;

const SpreadsheetActions = ({ config }: Props) => {
    const [file, setFile] = useState<File | null>(null);
    const [isLoading, setIsLoading] = useState(false);

    const navigate = useNavigate();
    const params = useParams();
    const { setNotification } = useToast();
    const { clearModalContent } = useModalStore();

    const handleFileChange = (event: FileEvent) => {
        const selectedFile = event.target.files?.[0];

        setFile(selectedFile || null);
    };

    const handleFileUpload = async () => {
        if (file && config.apiCall) {
            const formData = new FormData();

            formData.append('billingFile', file);
            setIsLoading(true);

            try {
                await fileUpload(config.apiCall?.endpoint, formData);

                setNotification(config.successMessage || `${config.label} success.`, 'success', { autoClose: 10000 });
                clearModalContent();

                if (config.navigateTo) {
                    navigate(`/organisation/${params.organisationId}/report/${config.navigateTo}`);
                }
            } catch (error) {
                setNotification(`${config.label} success`, 'error');
                setIsLoading(false);
            }
        }
    };

    return (
        <div className='d-flex justify-content-center'>
            {config.inputComponent?.type === 'file'
                ? <>
                    <Form.Group controlId='formFile'>
                        <Form.Control onChange={handleFileChange}
                            type='file' accept={config.inputComponent.accept} />
                    </Form.Group>
                    <AppButton onClick={handleFileUpload}
                        disabled={!file || isLoading}>
                        Upload
                    </AppButton>
                </>
                : <div />}
        </div>
    );
};

export default SpreadsheetActions;
