import create from 'zustand';
import { ModalSizeType } from 'app/components/Modal/Modal';

type ModalStateType = {
    title?: string;
    modalContent: string | JSX.Element;
    setModalContent: (modalContent: { content: string | JSX.Element, title: string }) => void;
    clearModalContent: () => void;
    onToggle?: () => void;
    className?: string;
    size?: ModalSizeType;
}

const useModalStore = create<ModalStateType>((set) => ({
    modalContent: '',
    setModalContent: (modalContent) => set((state) => ({ ...state, modalContent: modalContent.content, title: modalContent.title })),
    className: '',
    size: undefined,
    clearModalContent: () => set((state) => ({ ...state, modalContent: '' }))
}));

export default useModalStore;
