import React from 'react';
import Select, { GroupBase, Props } from 'react-select';
import './select.scss';

function AppSelect<
    OptionType,
    IsMulti extends boolean = false,
    GroupType extends GroupBase<OptionType> = GroupBase<OptionType>
>({
    className = '',
    ...props
}: Props<OptionType, IsMulti, GroupType>) {
    return <Select className={className} {...props} />;
}

export default AppSelect;
