import AppSelect from 'app/components/Select';
import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';

const defaultFilterOptions = [
    {
        label: 'Contains',
        value: 'contains'
    },
    {
        label: 'Not Contains',
        value: 'not_contains'
    },
    {
        label: 'Equal',
        value: 'equal'
    },
    {
        label: 'Not Equal',
        value: 'not_equal'
    },
    {
        label: 'Starts With',
        value: 'starts_with'
    },
    {
        label: 'Ends With',
        value: 'ends_with'
    }
];

const CustomDropdownFilter: React.FC<any> = forwardRef(({ values, api, colDef, column, columnApi, context, valueGetter, filterChangedCallback }, ref) => {
    const [selectedValues, setSelectedValues] = useState<string[]>(['reset']);

    const [selectedFilter, setSelectedFilter] = useState('contains');
    const [searchValue, setSearchValue] = useState('');
    const [currentFilter, setCurrentFilter] = useState<'dropdown' | 'search'>('search');

    const onDropdownChange = (values: any) => {
        setCurrentFilter('dropdown');
        setSearchValue('');
        const simplifiedValues = values.map((value: any) => value.value);

        setSelectedValues(simplifiedValues);
    };

    const onSearchChange = (value: string) => {
        setCurrentFilter('search');
        setSelectedValues(['reset']);
        setSearchValue(value);
    };

    const onReset = () => {
        setSearchValue('');
        setSelectedValues(['reset']);
        setSelectedFilter('contains');
    };

    useImperativeHandle(ref, () => ({
        doesFilterPass(params: any) {
            const { node } = params;

            let passed = true;

            const currentValue = valueGetter({
                api,
                colDef,
                column,
                columnApi,
                context,
                data: node.data,
                getValue: (field: any) => node.data[field],
                node
            });

            if (currentFilter === 'dropdown') {
                const valueToCheck = [...selectedValues];

                if (valueToCheck.includes('(Blank)')) valueToCheck.push('');

                if (!valueToCheck.includes(currentValue)) {
                    passed = false;
                }
            } else {
                const currentValueLowerCase = currentValue ? currentValue.toLowerCase() : '';
                const searchValueLowerCase = searchValue.toLowerCase();

                switch (selectedFilter) {
                    case 'contains':
                        passed = currentValueLowerCase.includes(searchValueLowerCase);
                        break;
                    case 'not_contains':
                        passed = !currentValueLowerCase.includes(searchValueLowerCase);
                        break;
                    case 'equal':
                        passed = currentValueLowerCase === searchValueLowerCase;
                        break;
                    case 'not_equal':
                        passed = currentValueLowerCase !== searchValueLowerCase;
                        break;
                    case 'starts_with':
                        passed = currentValueLowerCase.startsWith(searchValueLowerCase);
                        break;
                    case 'ends_with':
                        passed = currentValueLowerCase.endsWith(searchValueLowerCase);
                        break;
                    default:
                        passed = true;
                }
            }

            return passed;
        },

        isFilterActive() {
            return selectedValues ? (selectedValues.length > 0 && selectedValues[0] !== 'reset') || (searchValue !== '') : false;
        },

        getModel() {
            if (!this.isFilterActive()) {
                return null;
            }

            return { value: selectedValues };
        },

        setModel(model: any) {
            setSelectedValues(model == null ? null : model.value);
        }
    }));

    useEffect(() => {
        filterChangedCallback();
    }, [selectedValues, searchValue, selectedFilter]);

    const customStyles = {
        control: (provided: any, state: any) => ({
            ...provided,
            background: '#fff',
            borderColor: '#9e9e9e',
            minHeight: '25px',
            height: '25px',
            boxShadow: state.isFocused ? null : null
        }),
        valueContainer: (provided: any, state: any) => ({
            ...provided,
            height: '25px',
            padding: '0 6px'
        }),
        input: (provided: any, state: any) => ({
            ...provided,
            margin: '0px'
        }),
        indicatorSeparator: (state: any) => ({
            display: 'none'
        }),
        indicatorsContainer: (provided: any, state: any) => ({
            ...provided,
            height: '25px'
        })
    };

    return (
        <form onSubmit={(e) => e.preventDefault()} className='ag-filter-wrapper ag-focus-managed'>
            <div className='ag-filter-body-wrapper ag-simple-filter-body-wrapper'>
                <div className='ag-picker-field ag-labeled ag-label-align-left ag-select ag-filter-select'>
                    <AppSelect placeholder='Select options' isMulti={true} styles={customStyles}
                        value={selectedValues ? selectedValues[0] === 'reset' ? null : selectedValues.map((value) => ({ label: value, value: value })) : null}
                        onChange={onDropdownChange}
                        className='w-100'
                        menuPosition='fixed'
                        options={values} />
                </div>
                <div style={{ borderTopColor: 'lightgrey', borderTopWidth: 1, borderTopStyle: 'solid' }} className=''>
                    <div className='ag-picker-field ag-labeled ag-label-align-left ag-select ag-filter-select my-3'>
                        <AppSelect defaultValue={{ label: 'Contains', value: 'contains' }} styles={customStyles}
                            onChange={(val) => setSelectedFilter(val ? val.value : '')}
                            className='w-100'
                            menuPosition='fixed'
                            options={defaultFilterOptions} />
                    </div>
                    <div className='ag-wrapper ag-input-wrapper ag-text-field-input-wrapper'>
                        <input placeholder='Filter...' className='ag-input-field-input ag-text-field-input' type='text'
                            value={searchValue}
                            onChange={(e) => onSearchChange(e.target.value)} />
                    </div>
                </div>
            </div>
            <div className='ag-filter-apply-panel'>
                <button className='ag-button ag-standard-button ag-filter-apply-panel-button ms-0' onClick={onReset}>Reset</button>
            </div>
        </form>

    );
});

export default CustomDropdownFilter;
