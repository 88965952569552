import create from 'zustand';

type TableauStateType = {
    viz: tableau.Viz | null;
    setViz: (viz: tableau.Viz | null) => void;
    relatedReports: null | {
        tabName: string;
        key: string;
    }[];
    setRelatedReports: (reports: null | {
        tabName: string;
        key: string;
    }[]) => void;
    activeSheet: string;
    setActiveSheet: (activeSheet: string) => void;
    filters: TableauFilterType | null;
    setFilters: (filters: TableauFilterType | null) => void;
    parameters: TableauParamsType[] | null;
    setParameters: (parameters: TableauParamsType[] | null) => void;
    sheetsToExport: any[];
    setSheetsToExport: (sheetsToExport: any[]) => void;
}

export type TableauFilterType = { [key: string]: { options: { value: string, formattedValue: string }[], values: { value: string, formattedValue: string }[] | null } }

export type TableauParamsType = {
    name: string;
    options: tableau.DataValue[];
    currentValue: tableau.DataValue;
    type: tableau.ParameterAllowableValuesType;
    minValue: tableau.DataValue;
    maxValue: tableau.DataValue;
    stepSize: number;
}

const useTableauStore = create<TableauStateType>((set) => ({
    viz: null,
    setViz: (viz) => set((state) => ({ ...state, viz })),
    relatedReports: null,
    setRelatedReports: (relatedReports) => set((state) => ({ ...state, relatedReports })),
    activeSheet: '',
    setActiveSheet: (activeSheet) => set((state) => ({ ...state, activeSheet })),
    filters: null,
    setFilters: (filters) => set((state) => ({ ...state, filters })),
    parameters: null,
    setParameters: (parameters) => set((state) => ({ ...state, parameters })),
    sheetsToExport: [],
    setSheetsToExport: (sheetsToExport) => set((state) => ({ ...state, sheetsToExport }))
}));

export default useTableauStore;
