import { useParams } from 'react-router';

const REMOVE_LIME_SUPPORT = ['org_5RdjDW5V9jmQWIu7', 'org_3YuwULqg35HMZ19b', 'org_G7uYd9PNPJxvGHC4'];

const useRemoveLimeSupport = () => {
    const params = useParams();

    return !REMOVE_LIME_SUPPORT.includes(params.organisationId!);
};

export default useRemoveLimeSupport;
