import { lazy, FC, Suspense } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import { MasterLayout } from '../../_metronic/layout/MasterLayout';
import TopBarProgress from 'react-topbar-progress-indicator';
import { getCSSVariableValue } from '../../_metronic/assets/ts/_utils';
import { WithChildren } from '../../_metronic/helpers';
import OrganisationReport from '../pages/OrganisationReport/OrganisationReport';
import { ThemeModeProvider } from '_metronic/partials';

const PrivateRoutes = () => {
    const DataUpload = lazy(() => import('../pages/DataUpload/DataUploadContainer'));
    const SelectOrganisation = lazy(() => import('../pages/SelectOrganisation/SelectOrganisation'));
    const OrganisationDashboard = lazy(() => import('../pages/OrganisationDashboard/OrganisationDashboard'));

    return (
        <Routes>
            <Route element={<ThemeModeProvider>
                <MasterLayout />
            </ThemeModeProvider>}>
                {/* Redirect to Dashboard after success login/registartion */}
                <Route path='auth/*' element={<Navigate to='/organisation' />} />
                {/* Pages */}
                <Route path='organisation' element={<SuspensedView>
                    <SelectOrganisation />
                </SuspensedView>} />
                <Route path='organisation/:organisationId' element={<SuspensedView>
                    <OrganisationDashboard />
                </SuspensedView>} />
                <Route path='organisation/:organisationId/report/:reportKey' element={<OrganisationReport />} />
                <Route path='organisation/:organisationId/data-upload' element={<SuspensedView>
                    <DataUpload />
                </SuspensedView>} />
                {/* <Route path='builder' element={<BuilderPageWrapper />} />
                <Route path='menu-test' element={<MenuTestPage />} /> */}
                {/* Lazy Modules */}
                {/* <Route
                    path='crafted/pages/profile/*'
                    element={<SuspensedView>
                        <ProfilePage />
                    </SuspensedView>} />
                <Route
                    path='crafted/pages/wizards/*'
                    element={<SuspensedView>
                        <WizardsPage />
                    </SuspensedView>} />
                <Route
                    path='crafted/widgets/*'
                    element={<SuspensedView>
                        <WidgetsPage />
                    </SuspensedView>} />
                <Route
                    path='crafted/account/*'
                    element={<SuspensedView>
                        <AccountPage />
                    </SuspensedView>} />
                <Route
                    path='apps/chat/*'
                    element={<SuspensedView>
                        <ChatPage />
                    </SuspensedView>} />
                <Route
                    path='apps/user-management/*'
                    element={<SuspensedView>
                        <UsersPage />
                    </SuspensedView>} /> */}
                {/* Page Not Found */}
                <Route path='*' element={<Navigate to='/error/404' />} />
            </Route>
        </Routes>
    );
};

const SuspensedView: FC<WithChildren> = ({ children }) => {
    const baseColor = getCSSVariableValue('--kt-primary');

    TopBarProgress.config({
        barColors: {
            0: baseColor
        },
        barThickness: 1,
        shadowBlur: 5
    });

    return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>;
};

export { PrivateRoutes };
