import { useState } from 'react';
import Form from 'react-bootstrap/Form';
import useModalStore from 'app/store/modal';
import Button from 'app/components/Button';
import { duplicateAirportsScheduleRows } from 'app/api/airports/airports';
import useToast from 'app/hooks/useToast';
import AppInput from 'app/components/AppInput/AppInput';
import { AirportsScheduleData } from 'app/api/airports/types';
import Typography from 'app/components/Typography';
import AppSelect from 'app/components/Select/Select';

interface DuplicateRowsProps {
    refetch: any;
    airport: string;
    version: string;
    ids: number[];
    isVersionLocked: boolean;
    scheduleName: string;
    versionInfo: { ID: string, NAME: string }[]
}

const DuplicateRows: React.FC<DuplicateRowsProps> = ({ ids, airport, version, refetch, isVersionLocked, versionInfo, scheduleName }) => {
    const [isDuplicating, setIsDuplicating] = useState(false);
    const [selectedVersionType, setSelectedVersionType] = useState<'new' | 'existing' | null>(isVersionLocked ? 'new' : null);
    const [duplicateForm, setDuplicateForm] = useState({
        newVersionName: '',
        selectedExistingVersion: { label: '', value: '' },
        isIncrementPeriodStartAndPeriodEnd: false,
        newScenarioName: ''
    });

    const { clearModalContent } = useModalStore();
    const { setNotification } = useToast();

    const handleDuplicate = async () => {
        try {
            setIsDuplicating(true);

            await duplicateAirportsScheduleRows(ids, {
                isIncrementDates: duplicateForm.isIncrementPeriodStartAndPeriodEnd ? 'true' : 'false',
                newVersionName: selectedVersionType === 'new' ? duplicateForm.newVersionName : '',
                version: selectedVersionType === 'existing' ? duplicateForm.selectedExistingVersion.value : version,
                airport,
                newScenarioName: duplicateForm.newScenarioName,
                scheduleName
            });
            await refetch();
            setNotification('Rows duplicated', 'success');
        } catch (error) {
            setNotification('Error duplicating rows', 'error');
        } finally {
            setIsDuplicating(false);
            clearModalContent();
        }
    };

    return (
        <div>
            {version
                ? <>
                    <form onSubmit={(e) => {
                        e.preventDefault();
                        handleDuplicate();
                    }}>
                        <Typography className='mb-5'>Selected row{ids.length > 1 ? 's' : ''}: {ids.length}</Typography>
                        <Form.Check
                            label={<label htmlFor='isUpdatePeriodStartAndPeriodEndYear' className='form-label pb-2'>Increment Period Start and Period End Years</label>}
                            id='isUpdatePeriodStartAndPeriodEndYear'
                            onChange={() => setDuplicateForm({ ...duplicateForm, isIncrementPeriodStartAndPeriodEnd: !duplicateForm.isIncrementPeriodStartAndPeriodEnd })}
                            checked={duplicateForm.isIncrementPeriodStartAndPeriodEnd}
                            type='checkbox' />
                        <Form.Check
                            label={<label htmlFor='isNewVersion' className='form-label pb-2'>Set duplicated scenario to a new version</label>}
                            id='isNewVersion'
                            onChange={() => setSelectedVersionType(isVersionLocked ? 'new' : selectedVersionType === 'new' ? null : 'new')}
                            checked={selectedVersionType === 'new'}
                            type='checkbox' />
                        <Form.Check
                            label={<label htmlFor='isExistingVersion' className='form-label pb-2'>Copy rows to an existing version</label>}
                            id='isExistingVersion'
                            onChange={() => setSelectedVersionType(isVersionLocked ? 'existing' : selectedVersionType === 'existing' ? null : 'existing')}
                            checked={selectedVersionType === 'existing'}
                            type='checkbox' />
                        {selectedVersionType === 'new' && <div className='mb-4'>
                            <AppInput label='Set new version name' value={duplicateForm.newVersionName} name='new-scenario'
                                onChange={(e) => setDuplicateForm({ ...duplicateForm, newVersionName: e.target.value })} />
                        </div>}
                        {selectedVersionType === 'existing' && <div className='mb-4'>
                            <AppSelect value={duplicateForm.selectedExistingVersion.value ? duplicateForm.selectedExistingVersion : null}
                                placeholder='Select Version'
                                onChange={(val) => setDuplicateForm({ ...duplicateForm, selectedExistingVersion: val || { label: '', value: '' } })}
                                options={versionInfo.map((version) => ({ label: version.NAME, value: version.ID })).filter((data) => data.value !== '1')} />
                        </div>}
                        <AppInput className='mb-4' label='Set new scenario name (Optional)' value={duplicateForm.newScenarioName}
                            name='newScenarioName'
                            onChange={(e) => setDuplicateForm({ ...duplicateForm, newScenarioName: e.target.value })} />
                        <div className='w-100 d-flex justify-content-end'>
                            <Button type='submit' disabled={isDuplicating || (!!selectedVersionType && (selectedVersionType === 'new' ? !duplicateForm.newVersionName : false))} size='sm'>Duplicate</Button>
                        </div>
                    </form>

                </>
                : <Typography>Please select rows to duplicate.</Typography>}

        </div>
    );
};

const useDuplicateRows = (airport: string, refetch: any, isVersionLocked: boolean, versionInfo: { ID: string, NAME: string }[], scheduleName: string) => {
    const { setModalContent } = useModalStore();

    const handleDuplicateRows = (selectedRows: AirportsScheduleData[]) => {
        const payload = selectedRows.map((row) => row.ID);

        setModalContent({
            content: <DuplicateRows airport={airport} version={selectedRows.length > 0 ? selectedRows[0].VERSION_ID : ''} ids={payload}
                scheduleName={scheduleName}
                isVersionLocked={isVersionLocked}
                refetch={refetch}
                versionInfo={versionInfo} />,
            title: 'Duplicate Rows'
        });
    };

    return { handleDuplicateRows };
};

export default useDuplicateRows;
