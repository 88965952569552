import { useCallback, useEffect, useState } from 'react';
import { GridApi, GridReadyEvent } from 'ag-grid-community';

const useSpreadsheet = (isSpreadsheetDataLoading: boolean) => {
    const [gridApi, setGridApi] = useState<GridApi | null>(null);
    const [isSheetUpdated, setIsSheetUpdated] = useState(false);
    const [pageSize, setPageSize] = useState<string>('dynamic');

    const handleGridReady = useCallback((params: GridReadyEvent) => {
        setGridApi(params.api);
    }, []);

    useEffect(() => {
        if (gridApi) {
            isSpreadsheetDataLoading ? gridApi.showLoadingOverlay() : gridApi.hideOverlay();
        }
    }, [gridApi, isSpreadsheetDataLoading]);

    useEffect(() => {
        const storedPageSize = localStorage.getItem('pageSize');

        if (storedPageSize) {
            setPageSize(storedPageSize);

            if (gridApi && storedPageSize !== 'dynamic') {
                gridApi.paginationSetPageSize(Number(storedPageSize));
            }
        }
    }, [gridApi]);

    const onPageSizeChanged = () => {
        if (gridApi) {
            const value = (document.getElementById('page-size') as HTMLInputElement).value || 'dynamic';

            gridApi.paginationSetPageSize(value !== 'dynamic' ? Number(value) : undefined);
            localStorage.setItem('pageSize', value);

            if (value === 'dynamic') window.location.reload();

            setPageSize(value);
        }
    };

    const onCellValueChanged = useCallback(() => setIsSheetUpdated(true), []);

    return { gridApi, pageSize, isSheetUpdated, setIsSheetUpdated, handleGridReady, onPageSizeChanged, onCellValueChanged };
};

export default useSpreadsheet;
