import DatePicker, { ReactDatePickerProps } from 'react-datepicker';
import './app-datepicker.scss';
import 'react-datepicker/dist/react-datepicker.css';
import { MONTHS } from 'app/utils/constants';
import { Form } from 'react-bootstrap';

const getYear = (date: Date) => {
    return date ? date.getFullYear() : new Date().getFullYear();
};

const getMonth = (date: Date) => {
    return date ? date.getMonth() : new Date().getMonth();
};

const years = [2020];

for (let year = years[0]; year <= 2030; year++) {
    years.push(year + 1);
}

type AppDatePickerProps = ReactDatePickerProps & {
    showCustomHeader?: boolean; // Optional prop to control custom header rendering
    label?: string; // Optional label prop for the datepicker
    error?: string; // Optional error message prop
};

const AppDatePicker: React.FC<AppDatePickerProps> = ({ showCustomHeader = false, label, error, ...props }) => {
    return (
        <Form.Group controlId='datepicker'>
            {label && <Form.Label>{label}</Form.Label>}
            <DatePicker
                {...props}
                renderCustomHeader={showCustomHeader
                    ? ({
                        date,
                        changeYear,
                        changeMonth
                    }) => (
                        <div
                            style={{
                                margin: 10,
                                display: 'flex',
                                justifyContent: 'center'
                            }}>
                            <select
                                value={getYear(date)}
                                onChange={({ target: { value } }) => changeYear(Number(value))}>
                                {years.map((option) => (
                                    <option key={option} value={option}>
                                        {option}
                                    </option>
                                ))}
                            </select>

                            <select
                                value={MONTHS[getMonth(date)]}
                                onChange={({ target: { value } }) =>
                                    changeMonth(MONTHS.indexOf(value))}>
                                {MONTHS.map((option) => (
                                    <option key={option} value={option}>
                                        {option}
                                    </option>
                                ))}
                            </select>
                        </div>
                    )
                    : undefined} />
            {error && <Form.Text className='text-danger'>{error}</Form.Text>} {/* Error message rendering */}
        </Form.Group>
    );
};

export default AppDatePicker;
