import { useParams } from 'react-router-dom';
import { FullScreen, useFullScreenHandle } from 'react-full-screen';

import { useReport } from 'app/api/reports/reports';
import useIsMobile from 'app/hooks/useIsMobile';
import TableauFilter from './TableauFilter';
import useTableau from 'app/hooks/useTableau';
import useRightDrawerStore from 'app/store/rightDrawer';
import useConfigStore from 'app/store/userConfig';
import TableauActions from './TableauActions';
import { ORIENTATION } from 'app/utils/constants';
import { useEffect } from 'react';
import { getTableauServerURL, updateRightDrawerData } from 'app/utils/helpers';
import './tableau-report.scss';

type Props = {
    url: string;
    className?: string;
}

const TableauReport = ({ url, className }: Props) => {
    const { setViz, getTableauParameters, getTableauFilters, setActiveSheet, setRelatedReports, viz, ref } = useTableau();
    const { navbarOrientation, filterVersion } = useConfigStore();
    const { setRightDrawerContent, rightDrawerContent } = useRightDrawerStore();

    useEffect(() => {
        const initViz = (url: string) => {
            setViz(new tableau.Viz(ref.current!, url, {
                hideTabs: true,
                hideToolbar: true,
                onFirstInteractive: async (data) => {
                    const createdTabs = data.getViz().getWorkbook().getPublishedSheetsInfo().map((tab) => {
                        return { tabName: tab.getName(), key: tab.getName() };
                    });
                    const tableauActiveSheet = data.getViz().getWorkbook().getActiveSheet().getName();

                    getTableauParameters(data.getViz().getWorkbook());
                    getTableauFilters(data.getViz().getWorkbook());

                    setActiveSheet(tableauActiveSheet);
                    setRelatedReports(createdTabs);
                }
            }));
        };

        if (!url || !url.includes(getTableauServerURL())) return;

        if (viz) {
            viz.dispose();
            setViz(null);
        }

        initViz(url);
    }, [url]);

    const params = useParams();
    const isMobile = useIsMobile();
    const isTableauReport = url.includes(getTableauServerURL());

    const { data, isLoading } = useReport(params.reportKey!);

    const handle = useFullScreenHandle();

    useEffect(() => {
        const rightDrawerContentUpdate = [{
            keyName: 'actions',
            isShow: false,
            content: () => <div />
        }, {
            keyName: 'filters',
            isShow: (!!(data && data?.data.data.filters?.length > 0)) && (isMobile || handle.active || filterVersion === 'popOut'),
            content: () => ((isMobile || handle.active || filterVersion === 'popOut')
                ? <TableauFilter
                    isReportsLoading={isLoading}
                    filtersFromServer={data?.data.data.filters?.sort((a, b) => a.order - b.order)} />
                : <></>)
        }, {
            keyName: 'export',
            isShow: true,
            content: () => <TableauActions isTableauReport={isTableauReport} handle={handle} />
        }];

        const updatedContent = updateRightDrawerData(rightDrawerContentUpdate, rightDrawerContent);

        setRightDrawerContent(updatedContent);
    }, [isLoading, data, isMobile, handle, filterVersion, isTableauReport]);

    return (
        <div className={`tableau-report ${className}`}>
            <div className='card'>
                <div className={`d-flex flex-row ${handle.active
                    ? 'tableau-frame-fullscreen'
                    : isMobile
                        ? 'tableau-frame-mobile'
                        : navbarOrientation === ORIENTATION.VERTICAL
                            ? 'tableau-frame-vertical'
                            : 'tableau-frame-horizontal'}`}>
                    <FullScreen className='w-100' handle={handle}>
                        {url && isTableauReport && <div className='w-100' ref={ref} />}
                        {url && !isTableauReport && <iframe
                            title='report'
                            // onLoad={this.iframeLoaded}
                            frameBorder='0'
                            id='legacy-iframe'
                            src={url} />}
                    </FullScreen>

                    {(!!(data && data?.data.data.filters?.length > 0)) && !(isMobile || handle.active || filterVersion === 'popOut') && <TableauFilter
                        className='w-300px overflow-auto filter-height'
                        isReportsLoading={isLoading}
                        filtersFromServer={data?.data.data.filters?.sort((a, b) => a.order - b.order)} />}
                </div>
            </div>
        </div>

    );
};

export default TableauReport;
