
import { FC, useEffect } from 'react';
import { ILayout, useLayout } from '../../core';
import { MenuInner } from './header-menus';
import useFilteredReports from 'app/hooks/useFilteredReports';
import useFormatMenu from 'app/hooks/useFormatMenu';
import useIsMobile from 'app/hooks/useIsMobile';
import HeaderMenuMobile from './header-menus/header-menu-mobile';
import { KTSVG } from '../../../helpers';
import AppPlaceholder from 'app/components/Placeholder/Placeholder';
import useSearchReports from 'app/hooks/useSearchReports';
import Search from 'app/components/Search';

const Header: FC = () => {
    const { config } = useLayout();
    const isMobile = useIsMobile();

    const { data, isLoading } = useFilteredReports();

    const { formattedMenu } = useFormatMenu(data!);

    const { handleNavigate, searchOptions, searchValue } = useSearchReports();

    useEffect(() => {
        updateDOM(config);
    }, [config]);

    return (
        <div
            className='
        menu
        menu-rounded
        menu-column
        menu-lg-row
        my-5
        my-lg-0
        align-items-stretch
        fw-semibold
        px-2 px-lg-0
        header-menu
    '>
            {isMobile && <button
                type='button'
                className='btn btn-sm btn-icon btn-active-light-primary me-n5'
                id='kt_app_header_menu_close'>
                <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-1' />
            </button>}
            {isLoading && <AppPlaceholder className='h-30px' customSize='xs' numberOfPlaceholder={1}
                xs={12}
                md={12}
                lg={12}
                xl={12} />}
            {!isLoading && formattedMenu && <>
                {!isMobile ? <MenuInner formattedMenu={formattedMenu} /> : <HeaderMenuMobile formattedMenu={formattedMenu} />}
            </>}
            <div className='m-auto ps-4'>
                {searchOptions && !isMobile && <Search className='w-175px' handleSearch={handleNavigate} searchValue={searchValue}
                    options={searchOptions} />}
            </div>

        </div>
    );
};

const updateDOM = (config: ILayout) => {
    if (config.app?.header?.default?.fixed?.desktop) {
        document.body.setAttribute('data-kt-app-header-fixed', 'true');
    }

    if (config.app?.header?.default?.fixed?.mobile) {
        document.body.setAttribute('data-kt-app-header-fixed-mobile', 'true');
    }

    if (config.app?.header?.default?.stacked) {
        document.body.setAttribute('data-kt-app-header-stacked', 'true');
    }

    const appHeaderDefaultStickyEnabled = config.app?.header?.default?.sticky?.enabled;
    let appHeaderDefaultStickyAttributes: { [attrName: string]: string } = {};

    if (appHeaderDefaultStickyEnabled) {
        appHeaderDefaultStickyAttributes = config.app?.header?.default?.sticky?.attributes as {
            [attrName: string]: string
        };
    }

    const appHeaderDefaultMinimizeEnabled = config.app?.header?.default?.minimize?.enabled;
    let appHeaderDefaultMinimizeAttributes: { [attrName: string]: string } = {};

    if (appHeaderDefaultMinimizeEnabled) {
        appHeaderDefaultMinimizeAttributes = config.app?.header?.default?.minimize?.attributes as {
            [attrName: string]: string
        };
    }

    setTimeout(() => {
        const headerElement = document.getElementById('kt_app_header');

        // header
        if (headerElement) {
            const headerAttributes = headerElement
                .getAttributeNames()
                .filter((t) => t.indexOf('data-') > -1);

            headerAttributes.forEach((attr) => headerElement.removeAttribute(attr));

            if (appHeaderDefaultStickyEnabled) {
                for (const key in appHeaderDefaultStickyAttributes) {
                    if (appHeaderDefaultStickyAttributes.hasOwnProperty(key)) {
                        headerElement.setAttribute(key, appHeaderDefaultStickyAttributes[key]);
                    }
                }
            }

            if (appHeaderDefaultMinimizeEnabled) {
                for (const key in appHeaderDefaultMinimizeAttributes) {
                    if (appHeaderDefaultMinimizeAttributes.hasOwnProperty(key)) {
                        headerElement.setAttribute(key, appHeaderDefaultMinimizeAttributes[key]);
                    }
                }
            }
        }
    }, 0);
};

export { Header };
