import { Route, Routes } from 'react-router-dom';
import { Registration } from './components/Registration';
import { ForgotPassword } from './components/ForgotPassword';
import { Login } from './components/Login';
import { Callback } from './components/Callback';

const AuthPage = () => {
    return <Routes>
        <Route>
            <Route path='login' element={<Login />} />
            <Route path='registration' element={<Registration />} />
            <Route path='forgot-password' element={<ForgotPassword />} />
            <Route path='callback' element={<Callback />} />
            <Route index={true} element={<Login />} />
        </Route>
    </Routes>;
};

export { AuthPage };
