import { FC } from 'react';
import AppButton from 'app/components/Button/AppButton';

type Props = {
    title: string;
}

const MenuItem: FC<Props> = ({ title }) => {
    return (
        <div className='menu-item me-lg-1'>
            <AppButton size='sm'>{title}</AppButton>
        </div>
    );
};

export { MenuItem };
