import { useParams } from 'react-router-dom';
import clsx from 'clsx';
import { useOrganisations } from 'app/api/organisations/organisations';
import { KTSVG } from '../../../helpers';
import { useLayout } from '../../core';

const SidebarLogo = () => {
    const { config } = useLayout();
    const appSidebarDefaultMinimizeDesktopEnabled =
        config?.app?.sidebar?.default?.minimize?.desktop?.enabled;
    const appSidebarDefaultCollapseDesktopEnabled =
        config?.app?.sidebar?.default?.collapse?.desktop?.enabled;
    const toggleType = appSidebarDefaultCollapseDesktopEnabled
        ? 'collapse'
        : appSidebarDefaultMinimizeDesktopEnabled
            ? 'minimize'
            : '';
    const toggleState = appSidebarDefaultMinimizeDesktopEnabled ? 'active' : '';
    const appSidebarDefaultMinimizeDefault = config.app?.sidebar?.default?.minimize?.desktop?.default;

    const params = useParams();

    const { data, isLoading } = useOrganisations();
    const logoUrl = data?.data.data.find((org) => org.organisation.id === params.organisationId)?.organisation.branding.logoUrl;
    const isHoverable = config.app?.sidebar?.default?.minimize?.desktop?.hoverable;

    return (
        <div className='app-sidebar-logo px-6' id='kt_app_sidebar_logo'>
            <div className=''>
                {!isLoading
                    ? (
                        <div className='d-flex align-items-center'>
                            <img
                                alt='Logo'
                                src={logoUrl}
                                className='h-25px app-sidebar-logo-default' />
                            <span className={`text-gray-800 ms-3 fs-3 fw-bold ${isHoverable ? 'd-none hide-name' : 'tablet-minimize'} `}>{data?.data.data.find((org) => org.organisation.id === params.organisationId)?.organisation.displayName}</span>
                        </div>
                    )
                    : (
                        <>
                            <img
                                alt='Logo'
                                src={logoUrl}
                                className='h-25px app-sidebar-logo-default theme-light-show' />
                            <img
                                alt='Logo'
                                src={logoUrl}
                                className='h-25px app-sidebar-logo-default theme-dark-show' />
                        </>
                    )}

                <img
                    alt='Logo'
                    src={logoUrl}
                    style={{ height: 18 }}
                    className='app-sidebar-logo-minimize' />
            </div>

            {(appSidebarDefaultMinimizeDesktopEnabled || appSidebarDefaultCollapseDesktopEnabled) && (
                <div
                    id='kt_app_sidebar_toggle'
                    className={clsx(
                        'app-sidebar-toggle btn btn-icon btn-shadow btn-sm btn-color-muted btn-active-color-primary body-bg h-30px w-30px position-absolute top-50 start-100 translate-middle rotate',
                        { active: appSidebarDefaultMinimizeDefault }
                    )}
                    data-kt-toggle='true'
                    data-kt-toggle-state={toggleState}
                    data-kt-toggle-target='body'
                    data-kt-toggle-name={`app-sidebar-${toggleType}`}>
                    <KTSVG path='/media/icons/duotune/arrows/arr079.svg' className='svg-icon-2 rotate-180' />
                </div>
            )}
        </div>
    );
};

export { SidebarLogo };
