import { useParams } from 'react-router-dom';
import useIsMobile from 'app/hooks/useIsMobile';
import clsx from 'clsx';
import { useOrganisations } from 'app/api/organisations/organisations';
import { KTSVG } from '../../../helpers';
import { useLayout } from '../../core';
import { Header } from './Header';
import { Navbar } from './Navbar';
import useConfigStore from 'app/store/userConfig';
import { ORIENTATION } from 'app/utils/constants';
import './header-menu.scss';

export function HeaderWrapper() {
    const { config, classes } = useLayout();
    const isMobile = useIsMobile();
    const params = useParams();
    const { data, isLoading } = useOrganisations();
    const { navbarOrientation } = useConfigStore();

    if (!config.app?.header?.display) {
        return null;
    }

    const logoUrl = data?.data.data.find((org) => org.organisation.id === params.organisationId)?.organisation.branding.logoUrl;

    return (
        <div id='kt_app_header' className='app-header'>
            {navbarOrientation === ORIENTATION.HORIZONTAL && <div className='px-5 py-2'>
                {config.layoutType === 'dark-sidebar' && !isLoading
                    ? (
                        <div className='d-flex align-items-center'>
                            <img
                                alt='Logo'
                                src={logoUrl}
                                className='h-50px app-sidebar-logo-default' />
                        </div>
                    )
                    : (
                        <>
                            <img
                                alt='Logo'
                                src={logoUrl}
                                className='h-50px app-sidebar-logo-default theme-light-show' />
                            <img
                                alt='Logo'
                                src={logoUrl}
                                className='h-50px app-sidebar-logo-default theme-dark-show' />
                        </>
                    )}
            </div>}
            <div
                id='kt_app_header_container'
                className={clsx(
                    'app-container flex-lg-grow-1 w-100',
                    classes.headerContainer.join(' '),
                    config.app?.header?.default?.containerClass
                )}>
                {config.app.sidebar?.display && (
                    <>
                        <div
                            className='d-flex align-items-center d-lg-none ms-n2 me-2 justify-content-between w-100'
                            title='Show sidebar menu'>
                            {navbarOrientation === 'vertical' && <div
                                className='btn btn-icon btn-active-color-primary w-35px h-35px'
                                id='kt_app_sidebar_mobile_toggle'>
                                <KTSVG path='/media/icons/duotune/abstract/abs015.svg' className=' svg-icon-1' />
                            </div>}
                        </div>
                    </>
                )}

                <div
                    id='kt_app_header_wrapper'
                    className='d-flex align-items-stretch justify-content-between flex-lg-grow-1'>
                    {config.app.header.default?.content === 'menu' &&
                        config.app.header.default.menu?.display &&
                        (
                            <div
                                id='kt_app_header_menu'
                                className={`app-header-menu app-header-mobile-drawer align-items-stretch ${navbarOrientation === ORIENTATION.HORIZONTAL && !isMobile ? 'unset-bg' : ''}`}
                                data-kt-drawer='true'
                                data-kt-drawer-name='app-header-menu'
                                data-kt-drawer-activate='{default: true, lg: false}'
                                data-kt-drawer-overlay='true'
                                data-kt-drawer-width='100%'
                                data-kt-drawer-direction='end'
                                data-kt-drawer-toggle='#kt_app_header_menu_toggle'
                                data-kt-drawer-close='#kt_app_header_menu_close'>
                                <Header />
                            </div>
                        )}
                    {navbarOrientation === ORIENTATION.HORIZONTAL && <Navbar />}
                </div>
            </div>
        </div>
    );
}
