type Config = {
    left: ButtonConfig[];
    right: ButtonConfig[];
}

export type ButtonConfig = {
    label: string;
    component: string;
    inputComponent?: {
        type: 'file';
        accept: '.txt' | '.csv';
    };
    apiCall?: {
        endpoint: string;
    };
    saveSheetBeforeAction?: boolean;
    disableWhenDataIsEmpty?: boolean;
    hide?: string[];
    navigateTo?: string;
    successMessage?: string;
}

export const buttonConfig: Config = {
    left: [],
    right: [
        {
            label: 'Add Row',
            component: 'button',
            disableWhenDataIsEmpty: true
        },
        {
            label: 'Save',
            component: 'button',
            saveSheetBeforeAction: true
        }
    ]
};
