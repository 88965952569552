import React, { useState } from 'react';
import Form from 'react-bootstrap/Form';
import { AirportsScheduleData } from 'app/api/airports/types';
import AppSelect from 'app/components/Select/Select';
import useModalStore from 'app/store/modal';
import Button from 'app/components/Button';
import { saveAirportsScheduleData } from 'app/api/airports/airports';
import useToast from 'app/hooks/useToast';
import AppInput from 'app/components/AppInput/AppInput';
import { useForm, Controller, SubmitHandler, useFieldArray } from 'react-hook-form';
import { SingleValue } from 'react-select';
import Typography from 'app/components/Typography';

interface UpdateModalProps {
    sheetDataOptions: string[];
    airport: string;
    refetch: any;
    version: string;
    scheduleName: string;
    selectedRows: AirportsScheduleData[];
}

type Field = {
    label: string;
    value: string;
};

const daysOfWeek = ['MONDAY_MOVEMENTS', 'TUESDAY_MOVEMENTS', 'WEDNESDAY_MOVEMENTS', 'THURSDAY_MOVEMENTS', 'FRIDAY_MOVEMENTS', 'SATURDAY_MOVEMENTS', 'SUNDAY_MOVEMENTS'] as const;

type DaysOfWeek = typeof daysOfWeek[number];

type FormValues = {
    updateFields: {
        updateType: string;
        fieldToUpdate: Field;
    }[];
    newLoadFactorValue?: string;
    newRouteValue?: string;
    newAircraftValue?: { label: string; value: string };
    newFlightCategoryValue?: { label: 'DOM' | 'INT'; value: 'DOM' | 'INT' };
} & {
    [K in DaysOfWeek]: string;
};

const fieldsToUpdate: Field[] = [
    { label: 'Load Factor', value: 'LOAD_FACTOR' },
    { label: 'Movements', value: 'MOVEMENTS' },
    { label: 'Aircraft', value: 'AIRCRAFT' },
    { label: 'Route', value: 'ROUTE' },
    { label: 'Flight Category', value: 'FLIGHT_CATEGORY' }
];

const UpdateModal: React.FC<UpdateModalProps> = ({ sheetDataOptions, selectedRows, refetch, scheduleName }) => {
    const { handleSubmit, control, watch, formState: { isSubmitting } } = useForm<FormValues>({
        defaultValues: {
            updateFields: [{ updateType: 'value', fieldToUpdate: fieldsToUpdate[0] }],
            newLoadFactorValue: '',
            newRouteValue: '',
            newAircraftValue: { label: sheetDataOptions[0], value: sheetDataOptions[0] },
            newFlightCategoryValue: { label: 'DOM', value: 'DOM' },
            ...daysOfWeek.reduce((acc, day) => {
                acc[day.toUpperCase() as DaysOfWeek] = '0';

                return acc;
            }, {} as { [key in DaysOfWeek]: string })
        }
    });

    const { fields, append, remove } = useFieldArray({
        control,
        name: 'updateFields'
    });

    const { clearModalContent } = useModalStore();
    const { setNotification } = useToast();

    const onSubmit: SubmitHandler<FormValues> = async (data) => {
        // Extract the unique years from PERIOD_START
        const years = Array.from(
            new Set(selectedRows.map(item => new Date(item.PERIOD_START).getFullYear()))
        ).sort((a, b) => a - b);

        const yearToLoadFactor: { [key: number]: number } = {};
        const yearToMovementChange: { [key: number]: number } = {};

        // Calculate the load factor and movement changes, considering missing years
        const calculateYearBasedChanges = (updateType: string, baseValue: number) => {
            const firstYear = years[0];
            const lastYear = years[years.length - 1];

            // Fill in missing years based on updateType and baseValue
            for (let year = firstYear, index = 1; year <= lastYear; year++, index++) {
                if (updateType === 'LOAD_FACTOR') {
                    yearToLoadFactor[year] = baseValue * index;
                } else if (updateType === 'MOVEMENTS') {
                    yearToMovementChange[year] = baseValue * index;
                }
            }
        };

        // Update the selected rows
        const updatedSelectedRows = selectedRows.map(rowData => {
            let updatedRowData = { ...rowData };

            // Loop through the updateFields array
            data.updateFields.forEach(updateField => {
                const fieldToUpdate = updateField.fieldToUpdate.value;
                const updateType = updateField.updateType;

                switch (fieldToUpdate) {
                    case 'LOAD_FACTOR': {
                        let newLoadFactor = parseFloat(parseFloat(data.newLoadFactorValue || '0').toFixed(2));

                        if (updateType === 'change') {
                            newLoadFactor = rowData.LOAD_FACTOR + newLoadFactor;
                        } else if (updateType === 'change by year') {
                            const year = new Date(rowData.PERIOD_START).getFullYear();

                            calculateYearBasedChanges('LOAD_FACTOR', newLoadFactor);
                            newLoadFactor = rowData.LOAD_FACTOR + yearToLoadFactor[year];
                        }

                        // Ensure LOAD_FACTOR is within 0 and 100
                        newLoadFactor = parseFloat(Math.min(Math.max(0, newLoadFactor), 100).toFixed(2));

                        updatedRowData = { ...updatedRowData, LOAD_FACTOR: newLoadFactor };
                        break;
                    }

                    case 'AIRCRAFT':
                        updatedRowData = { ...updatedRowData, AIRCRAFT: data.newAircraftValue?.value || '' };
                        break;

                    case 'ROUTE':
                        updatedRowData = { ...updatedRowData, ROUTE: data.newRouteValue || '' };
                        break;

                    case 'MOVEMENTS': {
                        const daysObject = {} as Record<typeof daysOfWeek[number], number>;

                        daysOfWeek.forEach(day => {
                            let updatedMovement: number;

                            if (updateType === 'value') {
                                updatedMovement = parseInt(data[day]);
                            } else if (updateType === 'change by year') {
                                const year = new Date(rowData.PERIOD_START).getFullYear();

                                calculateYearBasedChanges('MOVEMENTS', parseInt(data[day]));
                                updatedMovement = rowData[day] + yearToMovementChange[year];
                            } else {
                                updatedMovement = rowData[day] + parseInt(data[day]);
                            }

                            // Ensure MOVEMENTS value for each day is not negative
                            daysObject[day] = Math.max(0, updatedMovement);
                        });

                        updatedRowData = { ...updatedRowData, ...daysObject };
                        break;
                    }

                    case 'FLIGHT_CATEGORY':
                        updatedRowData = { ...updatedRowData, FLIGHT_CATEGORY: data.newFlightCategoryValue?.value || 'DOM' };
                        break;

                    default:
                        break; // Do nothing if the field doesn't match
                }
            });

            return updatedRowData;
        });

        try {
            await saveAirportsScheduleData(updatedSelectedRows, { scheduleName: scheduleName });

            await refetch();
            setNotification('Field updated', 'success');
        } catch (error) {
            setNotification('Error updating field', 'error');
        } finally {
            clearModalContent();
        }
    };

    const selectedFieldValues = watch('updateFields').map(field => field.fieldToUpdate.value);

    return <form onSubmit={handleSubmit(onSubmit)}>
        {
            selectedRows.length > 0
                ? <>
                    <Typography className='mb-5'>Selected row{selectedRows.length > 1 ? 's' : ''}: {selectedRows.length}</Typography>
                    {fields.map((item, index) => {
                        const availableFields = fieldsToUpdate.filter(field => !selectedFieldValues.includes(field.value));

                        return <div key={item.id} className={`mb-4 ${fields.length > 0 && index > 0 ? 'border-top border-secondary' : ''} py-4`}>
                            <Form.Label>Select field to update</Form.Label>
                            <Controller
                                name={`updateFields.${index}.fieldToUpdate`}
                                control={control}
                                render={({ field }) => (
                                    <AppSelect
                                        {...field}
                                        className='mb-4'
                                        options={availableFields}
                                        onChange={(val: SingleValue<Field>) => {
                                            if (val) {
                                                field.onChange(val);
                                            }
                                        }} />
                                )} />

                            {watch(`updateFields.${index}.fieldToUpdate`).value === 'LOAD_FACTOR' && (
                                <>
                                    <div className='mb-4'>
                                        <Controller
                                            name={`updateFields.${index}.updateType`}
                                            control={control}
                                            render={({ field }) => {
                                                return <>
                                                    {['value', 'change', 'change by year'].map((type) => (
                                                        <Form.Check
                                                            key={`${type}-LOAD_FACTOR`}
                                                            label={<label className='form-label' htmlFor={`${type}-LOAD_FACTOR`}>{type.replace(/(^|\s)\S/g, l => l.toUpperCase())}</label>}
                                                            id={`${type}-LOAD_FACTOR`}
                                                            onChange={() => {
                                                                field.onChange(type);
                                                            }}
                                                            checked={field.value === type}
                                                            type='radio' />
                                                    ))}
                                                </>;
                                            }} />
                                    </div>
                                    <Controller
                                        name='newLoadFactorValue'
                                        control={control}
                                        render={({ field }) => {
                                            const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
                                                const { value } = e.target;

                                                // Validate if the value is a valid number with up to 2 decimal places and negative sign
                                                const regex = /^-?\d*\.?\d{0,2}$/;

                                                if (regex.test(value)) {
                                                    field.onChange(value);
                                                }
                                            };

                                            return <AppInput
                                                className='mb-4'
                                                label={`Set Load Factor ${watch(`updateFields.${index}.updateType`)} in percentage`}
                                                value={field.value}
                                                name='new-load-factor-value'
                                                onChange={handleChange} />;
                                        }} />
                                </>
                            )}
                            {watch(`updateFields.${index}.fieldToUpdate`).value === 'ROUTE' && (
                                <Controller
                                    name='newRouteValue'
                                    control={control}
                                    render={({ field }) => (
                                        <AppInput
                                            className='mb-4'
                                            label={'Set new Route value'}
                                            value={field.value}
                                            name='newRouteValue'
                                            onChange={(val) => field.onChange(val)} />
                                    )} />
                            )}

                            {watch(`updateFields.${index}.fieldToUpdate`).value === 'AIRCRAFT' && (
                                <Controller
                                    name='newAircraftValue'
                                    control={control}
                                    render={({ field }) => (
                                        <AppSelect
                                            {...field}
                                            className='mb-4'
                                            placeholder='Select Aircraft'
                                            options={sheetDataOptions.map((data) => ({ label: data, value: data }))}
                                            onChange={(val: SingleValue<Field>) => {
                                                if (val) {
                                                    field.onChange(val);
                                                }
                                            }} />
                                    )} />
                            )}

                            {watch(`updateFields.${index}.fieldToUpdate`).value === 'FLIGHT_CATEGORY' && (
                                <Controller
                                    name='newFlightCategoryValue'
                                    control={control}
                                    render={({ field }) => (
                                        <AppSelect
                                            {...field}
                                            className='mb-4'
                                            placeholder='Select Flight Category'
                                            options={[{ label: 'DOM', value: 'DOM' }, { label: 'INT', value: 'INT' }]}
                                            onChange={(val: SingleValue<{ label: 'DOM' | 'INT'; value: 'DOM' | 'INT' }>) => {
                                                if (val) {
                                                    field.onChange(val);
                                                }
                                            }} />
                                    )} />
                            )}

                            {watch(`updateFields.${index}.fieldToUpdate`).value === 'MOVEMENTS' && (
                                <>
                                    <div className='mb-4'>
                                        <Controller
                                            name={`updateFields.${index}.updateType`}
                                            control={control}
                                            render={({ field }) => (
                                                <>
                                                    {['value', 'change', 'change by year'].map((type) => (
                                                        <Form.Check
                                                            key={`${type}-MOVEMENTS`}
                                                            label={<label className='form-label' htmlFor={`${type}-MOVEMENTS`}>{type.replace(/(^|\s)\S/g, l => l.toUpperCase())}</label>}
                                                            id={`${type}-MOVEMENTS`}
                                                            onChange={() => {
                                                                field.onChange(type);
                                                            }}
                                                            checked={field.value === type}
                                                            type='radio' />
                                                    ))}
                                                </>
                                            )} />
                                    </div>

                                    {daysOfWeek.map((day) => (
                                        <Controller
                                            key={day}
                                            name={day.toUpperCase() as DaysOfWeek}
                                            control={control}
                                            render={({ field }) => (
                                                <AppInput
                                                    className='mb-4'
                                                    label={`Set movements for ${day.split('_')[0]}`}
                                                    value={field.value}
                                                    name={day.toUpperCase()}
                                                    type='number'
                                                    onChange={(e) => {
                                                        const value = e.target.value;
                                                        const isValidNumber = /^-?\d*\.?\d{0,2}$/.test(value);

                                                        if (isValidNumber) {
                                                            field.onChange(value);
                                                        }
                                                    }} />
                                            )} />
                                    ))}
                                </>
                            )}

                            {fields.length > 1 && <Button variant='danger' size='sm' onClick={() => remove(index)}>Remove</Button>}
                        </div>;
                    })}
                    <Button disabled={fields.length === fieldsToUpdate.length} variant='secondary' size='sm'
                        onClick={() => append({ updateType: 'value', fieldToUpdate: { label: '', value: '' } })}>Add Another Field</Button>
                </>
                : <Typography className='mb-5'>Please select rows to update.</Typography>
        }
        <div className='w-100 d-flex justify-content-end gap-3'>
            <Button onClick={clearModalContent} variant='secondary' size='sm'>Cancel</Button>
            {selectedRows.length > 0 && <Button disabled={isSubmitting} isLoading={isSubmitting} type='submit'
                size='sm'>Submit</Button>}
        </div>
    </form>;
};

const useUpdateRows = (sheetDataOptions: string[], airport: string, version: string, refetch: any, scheduleName: string) => {
    const { setModalContent } = useModalStore();

    const handleUpdateRows = (selectedRows: AirportsScheduleData[]) => {
        setModalContent({
            content: <UpdateModal sheetDataOptions={sheetDataOptions} airport={airport} version={version}
                scheduleName={scheduleName}
                selectedRows={selectedRows}
                refetch={refetch} />,
            title: 'Update Field'
        });
    };

    return { handleUpdateRows };
};

export default useUpdateRows;
