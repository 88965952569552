import { FullScreenHandle } from 'react-full-screen';
import Form from 'react-bootstrap/Form';
import Dropdown from 'react-bootstrap/Dropdown';
import { DOWNLOAD_FILES } from 'app/utils/constants';
import useTableau from 'app/hooks/useTableau';
import useIsMobile from 'app/hooks/useIsMobile';
import AppPlaceholder from 'app/components/Placeholder/Placeholder';
import AppButton from 'app/components/Button/AppButton';
import './tableau-actions.scss';

type Props = {
    handle: FullScreenHandle;
    isTableauReport: boolean;
}

const TableauActions = ({ handle, isTableauReport }: Props) => {
    const { relatedReports, handleDownload, sheetsToExport, isFiltersResetLoading, handleExportExcel } = useTableau();

    const isMobile = useIsMobile();

    const handleFullscreenChange = (e: any) => {
        if (handle.active) {
            handle.exit();
        } else {
            handle.enter();
        }
    };

    return (
        <div className='tableau-actions'>
            {isTableauReport && <div className='pb-8'>
                <label className='form-label fw-bold pb-2'>Download Report</label>
                <div className='d-flex gap-3'>
                    {relatedReports
                        ? <>
                            {DOWNLOAD_FILES.map((file, i: number) => {
                                return <AppButton onClick={() => handleDownload(file)} id='kt_engage_actions_close' size='sm'
                                    key={file}>{file}</AppButton>;
                            })}
                        </>
                        : <AppPlaceholder className='h-30px w-150px' customSize='xs' numberOfPlaceholder={1} />}
                    {sheetsToExport.length === 1
                        ? <AppButton disabled={isFiltersResetLoading} size='sm'
                            onClick={() => handleExportExcel(sheetsToExport[0])}>Excel</AppButton>
                        : sheetsToExport.length > 1
                            ? <Dropdown>
                                <Dropdown.Toggle size='sm' id='dropdown-related-reports'>
                                    Export to Excel
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                    {sheetsToExport.map((sheet, i) => {
                                        return <Dropdown.Item key={i} onClick={() => handleExportExcel(sheet)}>{sheet.getName()}</Dropdown.Item>;
                                    })}
                                </Dropdown.Menu>
                            </Dropdown>
                            : <></>}
                </div>
            </div>}
            {!isMobile && <div className='pb-5 d-flex gap-4'>
                <Form.Check
                    label={<label className='form-label fw-bold pb-2'>Fullscreen Report</label>}
                    id='kt_engage_actions_close'
                    onChange={handleFullscreenChange}
                    checked={handle.active}
                    type='switch' />
            </div>}
        </div>
    );
};

export default TableauActions;
