import { useParams } from 'react-router-dom';

const whiteLabelOrgs: Record<string, string> = {
    org_RGhj06rNgLqOqzLy: 'pitcher-partners',
    org_t72WAGpPkZymyDJS: 'pitcher-partners',
    org_5RdjDW5V9jmQWIu7: 'cgnw',
    org_3YuwULqg35HMZ19b: 'cgnw',
    org_G7uYd9PNPJxvGHC4: 'cgnw'
};

const useWhiteLabel = () => {
    const params = useParams();

    if (params.organisationId && whiteLabelOrgs[params.organisationId]) {
        return whiteLabelOrgs[params.organisationId];
    } else return null;
};

export default useWhiteLabel;
