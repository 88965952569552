import { useQuery } from 'react-query';
import makeApiRequest from '../makeApiCall';
import { DataTemplateByOrganisationType, OrganisationOwnerType } from './types';

const GET_DATA_TEMPLATE_BY_ORGANISATION_KEY = 'getDataTemplateByOrganisation';
const GET_ORGANISATION_OWNERS_KEY = 'getOrganisationOwners';

// Server should return DataTemplateByOrganisationType
async function getDataTemplateByOrganisation(organisationId: string) {
    return await makeApiRequest<DataTemplateByOrganisationType[]>(`/datatemplate/organisation/${organisationId}`);
}

export const useDataTemplateByOrganisation = (organisationId: string, queryOptions?: any) => useQuery([GET_DATA_TEMPLATE_BY_ORGANISATION_KEY, organisationId], () => getDataTemplateByOrganisation(organisationId), {
    ...queryOptions,
    staleTime: Infinity
});

// Server should return OrganisationOwnerType
async function getOrganisationOwners(organisationId: string) {
    return await makeApiRequest<OrganisationOwnerType[]>(`/datatemplate/organisation/${organisationId}/owners`);
}

export const useOrganisationOwners = (organisationId: string, queryOptions?: any) => useQuery([GET_ORGANISATION_OWNERS_KEY, organisationId], () => getOrganisationOwners(organisationId), {
    ...queryOptions,
    staleTime: Infinity
});

export async function completeJob(completeJob: any) {
    return await makeApiRequest<any>('/upload/complete', 'POST', { ...completeJob });
}
