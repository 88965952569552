import React from 'react';
import moment from 'moment';
import Form from 'react-bootstrap/Form';
import useModalStore from 'app/store/modal';
import Button from 'app/components/Button';
import useToast from 'app/hooks/useToast';
import AppInput from 'app/components/AppInput/AppInput';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';
import Typography from 'app/components/Typography';
import AppDatePicker from 'app/components/AppDatePicker';
import { MemberPayments } from 'app/api/aaan/types';
import { updateMemberPaymentsData } from 'app/api/aaan/aaan';

interface UpdateModalProps {
    refetch: any;
    selectedRows: MemberPayments[];
}

type FormValues = {
    invoiceType: 'scheduled' | 'immediate' | 'completed';
    BILLING_SCHEDULE_INVOICE_DATE: string;
    BILLING_NOTES_AIRPORT: string;
    BILLING_INVOICE_DUE_DATE: string;
};

const validateFields = (data: FormValues, setError: any) => {
    const validationErrors: { [key: string]: string } = {};

    // Validate other fields conditionally based on invoiceType
    if (data.invoiceType === 'immediate') {
        if (!data.BILLING_INVOICE_DUE_DATE) {
            validationErrors.BILLING_INVOICE_DUE_DATE = 'Payment Date is Required';
        }
    } else if (data.invoiceType === 'scheduled') {
        if (!data.BILLING_INVOICE_DUE_DATE) {
            validationErrors.BILLING_INVOICE_DUE_DATE = 'Payment Date is Required';
        }

        if (!data.BILLING_SCHEDULE_INVOICE_DATE) {
            validationErrors.BILLING_SCHEDULE_INVOICE_DATE = 'Schedule Invoice Date is Required';
        }
    }

    // Set errors in the form state
    for (const [key, value] of Object.entries(validationErrors)) {
        setError(key as keyof FormValues, { type: 'manual', message: value });
    }

    return Object.keys(validationErrors).length === 0; // Return true if no errors
};

const UpdateModal: React.FC<UpdateModalProps> = ({ selectedRows, refetch }) => {
    const { handleSubmit, control, watch, setError, formState: { isSubmitting, errors } } = useForm<FormValues>({
        defaultValues: {
            invoiceType: 'immediate',
            BILLING_SCHEDULE_INVOICE_DATE: '',
            BILLING_NOTES_AIRPORT: '',
            BILLING_INVOICE_DUE_DATE: ''
        }
    });

    const { clearModalContent } = useModalStore();
    const { setNotification } = useToast();

    const onSubmit: SubmitHandler<FormValues> = async (data) => {
        const isValid = validateFields(data, setError);

        if (!isValid) {
            return; // Stop submission if there are validation errors
        }

        const batchMap: { [key: string]: string[] } = {};

        selectedRows.forEach((item) => {
            if (!batchMap[item.BATCH_ID]) {
                batchMap[item.BATCH_ID] = [];
            }

            batchMap[item.BATCH_ID].push(item.BILLING_ICAO_CODE);
        });

        const batchArray = Object.keys(batchMap).map((batchId) => ({
            BATCH_ID: batchId,
            BILLING_ICAO_CODE: batchMap[batchId]
        }));

        const payload = {
            BATCH: batchArray,
            BILLING_SCHEDULE_INVOICE_DATE: data.BILLING_SCHEDULE_INVOICE_DATE,
            INVOICE_TYPE: data.invoiceType,
            BILLING_NOTES_AIRPORT: data.BILLING_NOTES_AIRPORT,
            BILLING_INVOICE_DUE_DATE: data.BILLING_INVOICE_DUE_DATE
        };

        try {
            await updateMemberPaymentsData(payload);

            await refetch();
            setNotification('Field updated', 'success');
        } catch (error) {
            setNotification('Error updating field', 'error');
        } finally {
            clearModalContent();
        }
    };

    const selectedInvoiceType = watch('invoiceType');

    return <form onSubmit={handleSubmit(onSubmit)}>
        {
            selectedRows.length > 0
                ? <>
                    <Typography className='mb-5'>Selected row{selectedRows.length > 1 ? 's' : ''}: {selectedRows.length}</Typography>
                    <Controller
                        name='invoiceType'
                        control={control}
                        render={({ field: { onChange, value } }) => (
                            <>
                                <Form.Check
                                    label={<label htmlFor='immediate' className='form-label pb-2'>Send Immediately</label>}
                                    id='immediate'
                                    onChange={() => onChange('immediate')}
                                    checked={value === 'immediate'}
                                    type='checkbox' />
                                <Form.Check
                                    label={<label htmlFor='scheduled' className='form-label pb-2'>Schedule</label>}
                                    id='scheduled'
                                    onChange={() => onChange('scheduled')}
                                    checked={value === 'scheduled'}
                                    type='checkbox' />
                                <Form.Check
                                    label={<label htmlFor='completed' className='form-label pb-2'>Mark as completed</label>}
                                    id='completed'
                                    onChange={() => onChange('completed')}
                                    checked={value === 'completed'}
                                    type='checkbox' />
                            </>
                        )} />
                    {selectedInvoiceType === 'completed' && <Controller
                        name='BILLING_NOTES_AIRPORT'
                        control={control}
                        render={({ field }) => (
                            <AppInput
                                className='mb-4'
                                label='Notes'
                                value={field.value}
                                name='BILLING_NOTES_AIRPORT'
                                onChange={(val) => {
                                    field.onChange(val.target.value);
                                }} />
                        )} />}

                    <div className='mb-4 border-top border-secondary py-4'>
                        {selectedInvoiceType !== 'completed' && <div className='mb-4'>
                            <Controller
                                name='BILLING_INVOICE_DUE_DATE'
                                control={control}
                                render={({ field }) => (
                                    <AppDatePicker
                                        label='Payment Date'
                                        dateFormat='yyyy/MM/dd'
                                        showIcon={true}
                                        onKeyDown={(e) => {
                                            e.preventDefault();
                                        }}
                                        icon='fa fa-calendar'
                                        className='w-100'
                                        error={errors?.BILLING_INVOICE_DUE_DATE?.message || ''}
                                        isClearable={true}
                                        onChange={(val) => {
                                            // Format the date to dd/MM/yyyy and convert to string
                                            const formattedDate = val ? moment(val).format('yyyy-MM-DD') : '';

                                            field.onChange(formattedDate); // Call field.onChange with the formatted string
                                        }}
                                        selected={field.value ? moment(field.value, 'yyyy/MM/DD').toDate() : null} />
                                )} />
                        </div>
                        }
                        {selectedInvoiceType === 'scheduled' && <div className='mb-4'>
                            <Controller
                                name='BILLING_SCHEDULE_INVOICE_DATE'
                                control={control}
                                render={({ field }) => (
                                    <AppDatePicker
                                        label='Schedule Invoice Date'
                                        dateFormat='yyyy/MM/dd'
                                        showIcon={true}
                                        onKeyDown={(e) => {
                                            e.preventDefault();
                                        }}
                                        icon='fa fa-calendar'
                                        className='w-100'
                                        error={errors?.BILLING_SCHEDULE_INVOICE_DATE?.message || ''}
                                        isClearable={true}
                                        onChange={(val) => {
                                            // Format the date to dd/MM/yyyy and convert to string
                                            const formattedDate = val ? moment(val).format('yyyy-MM-DD') : '';

                                            field.onChange(formattedDate); // Call field.onChange with the formatted string
                                        }}
                                        selected={field.value ? moment(field.value, 'yyyy/MM/DD').toDate() : null} />
                                )} />
                        </div>}
                    </div>
                </>
                : <Typography className='mb-5'>Please select rows to update.</Typography>
        }
        <div className='w-100 d-flex justify-content-end gap-3'>
            <Button onClick={clearModalContent} variant='secondary' size='sm'>Cancel</Button>
            {selectedRows.length > 0 && <Button disabled={isSubmitting} isLoading={isSubmitting} type='submit'
                size='sm'>Submit</Button>}
        </div>
    </form>;
};

const useUpdateRows = (refetch: any) => {
    const { setModalContent } = useModalStore();

    const handleUpdateRows = (selectedRows: MemberPayments[]) => {
        setModalContent({
            content: <UpdateModal
                selectedRows={selectedRows}
                refetch={refetch} />,
            title: 'Member Payments'
        });
    };

    return { handleUpdateRows };
};

export default useUpdateRows;
