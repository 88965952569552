import EmailSupport from 'app/components/EmailSupport';
import useModalStore from 'app/store/modal';

const useMailSupport = () => {
    const { setModalContent } = useModalStore();

    const openMailSupportModal = () => {
        setModalContent({ content: <EmailSupport />, title: 'Lime Support' });
    };

    return openMailSupportModal;
};

export default useMailSupport;
