import { useState } from 'react';
import { ReportsType } from 'app/api/reports/types';
import { useParams, useNavigate } from 'react-router-dom';
import useFilteredReports from './useFilteredReports';

const useSearchReports = () => {
    const params = useParams();
    const { data } = useFilteredReports();

    const [searchValue, setSearchValue] = useState<{ label: string, value: any } | null>(null);

    const navigate = useNavigate();

    const handleNavigate = (data: { label: string, value: ReportsType }) => {
        setSearchValue(null);
        navigate(`organisation/${params.organisationId}/report/${data.value.key}`);
    };

    return { searchValue, handleNavigate, searchOptions: data?.map((report) => ({ label: report.reportName, value: report })) };
};

export default useSearchReports;
