import { FC, ReactNode } from 'react';
import clsx from 'clsx';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router';
import { checkIsActive, KTSVG, WithChildren } from '../../../../helpers';
import { useLayout } from '../../../core';

type Props = {
    className?: string;
    to: string;
    title: string;
    titleRightElement?: ReactNode;
    icon?: string;
    fontIcon?: string;
    titleLeftElement?: ReactNode;
}

const SidebarMenuItem: FC<Props & WithChildren> = ({
    children,
    to,
    title,
    icon,
    fontIcon,
    titleRightElement,
    titleLeftElement = false,
    className
}) => {
    const { pathname } = useLocation();
    const isActive = checkIsActive(pathname, to);
    const { config } = useLayout();
    const { app } = config;

    return (
        <div className={`menu-item ${className}`}>
            <Link className={clsx('menu-link without-sub', titleLeftElement && 'ps-0', { active: isActive })} to={to}>
                {titleLeftElement && titleLeftElement}
                {icon && app?.sidebar?.default?.menu?.iconType === 'svg' && (
                    <span className='menu-icon'>
                        {' '}
                        <KTSVG path={icon} className='svg-icon-2' />
                    </span>
                )}
                {fontIcon && app?.sidebar?.default?.menu?.iconType === 'font' && (
                    <i className={clsx('bi fs-3', fontIcon)} />
                )}
                <span className='menu-title custom-title-color'>{title}</span>
                {titleRightElement && titleRightElement}
            </Link>
            {children}
        </div>
    );
};

export { SidebarMenuItem };
