import React from 'react';
import Button, { ButtonProps } from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';
import useWhiteLabel from 'app/hooks/useWhiteLabel';

type Props = ButtonProps & {
    variant?: string; // Make variant prop optional
    isLoading?: boolean; // Add isLoading prop
}

const AppButton = ({ variant = 'primary', isLoading, ...otherProps }: Props) => {
    const whiteLabelOrg = useWhiteLabel();

    return (
        <Button variant={whiteLabelOrg || variant} disabled={isLoading} {...otherProps}>
            {isLoading
                ? (
                    <>
                        <Spinner
                            as='span'
                            animation='border'
                            size='sm'
                            role='status'
                            aria-hidden='true'
                            className='mr-2' />
                    </>
                )
                : (
                    otherProps.children
                )}
        </Button>
    );
};

export default AppButton;
