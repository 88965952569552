import { useState } from 'react';
import { deleteAirporsScheduleRows } from 'app/api/airports/airports';
import { AirportsScheduleData } from 'app/api/airports/types';
import Typography from 'app/components/Typography';
import useModalStore from 'app/store/modal';
import Button from 'app/components/Button';
import useToast from 'app/hooks/useToast';
import { GridApi } from 'ag-grid-community';

interface DeleteModalProps {
    scheduleName: string;
    refetchDataOptions: any;
    selectedRows: AirportsScheduleData[];
    handleSaveSpreadsheet: () => Promise<any>;
}

const DeleteModal: React.FC<DeleteModalProps> = ({ scheduleName, selectedRows, refetchDataOptions, handleSaveSpreadsheet }) => {
    const [isDeleting, setIsDeleting] = useState(false);

    const { clearModalContent } = useModalStore();
    const { setNotification } = useToast();

    const handleDeleteRows = async () => {
        try {
            const payload = selectedRows.map((row) => row.ID);

            setIsDeleting(true);
            await handleSaveSpreadsheet();
            await deleteAirporsScheduleRows(payload, { scheduleName });

            await refetchDataOptions();
            clearModalContent();
            setNotification('Rows successfully deleted', 'success');
        } catch (error) {
            setNotification('Error deleting rows', 'error');
        } finally {
            setIsDeleting(false);
            clearModalContent();
        }
    };

    return (
        <div className='flex-column'>
            {selectedRows.length > 0
                ? <>
                    <Typography className='mb-5'>Selected row{selectedRows.length > 1 ? 's' : ''}: {selectedRows.length}</Typography>
                    <Typography className='mb-5'>{`Are you sure you want to delete the selected row${selectedRows.length > 1 ? 's' : ''}?`}</Typography>
                </>
                : <Typography className='mb-5'>Please select rows to delete.</Typography>}
            <div className='d-flex justify-content-end gap-3'>
                <Button onClick={clearModalContent} variant='secondary' size='sm'>Cancel</Button>
                {selectedRows.length > 0 && <Button onClick={() => handleDeleteRows()} disabled={isDeleting} variant='danger'
                    size='sm'>Delete</Button>}
            </div>
        </div>
    );
};

const useDeleteRows = (refetchDataOptions: any, scheduleName: string) => {
    const { setModalContent } = useModalStore();

    const handleDeleteModal = (handleSaveSpreadsheet: () => Promise<any>, gridApi: GridApi | null) => {
        const selectedRows = gridApi ? gridApi.getSelectedRows() : [];

        setModalContent({
            content: <DeleteModal handleSaveSpreadsheet={handleSaveSpreadsheet} scheduleName={scheduleName} selectedRows={selectedRows}
                refetchDataOptions={refetchDataOptions} />,
            title: `Delete Row${selectedRows.length > 1 ? 's' : ''}`
        });
    };

    return { handleDeleteModal };
};

export default useDeleteRows;
