export const getPreviousMonth = () => {
    const currentDate = new Date();

    // Set date to the first day of the current month
    currentDate.setDate(1);

    const year = currentDate.getFullYear();
    const month = currentDate.getMonth() + 1; // Month index starts from 0
    const formattedMonth = month < 10 ? '0' + month : month;
    const day = currentDate.getDate();
    const formattedDay = day < 10 ? '0' + day : day;
    const formattedDate = year + '-' + formattedMonth + '-' + formattedDay;

    return formattedDate;
};

export const getUniqueValues = <T>(data: T[], keys: (keyof T)[]): Record<string, string[]> => {
    const uniqueValues: Record<string, string[]> = {};

    data.forEach((obj: T) => {
        keys.forEach((key) => {
            const keyStr = key as string;

            if (!uniqueValues[keyStr]) {
                uniqueValues[keyStr] = [];
            }

            const value = obj[key];

            if (typeof value === 'string' && !uniqueValues[keyStr].includes(value)) {
                uniqueValues[keyStr].push(value);
            }
        });
    });

    return uniqueValues;
};

export const fieldExists = <T extends object>(data: T[], fieldName: keyof T): boolean => {
    // Iterate over each object in the array
    for (const obj of data) {
        // Check if the field exists in the current object
        if (fieldName in obj) {
            return true;
        }
    }

    return false;
};

export const yyyymmddFormatter = (params: any) => {
    const parsedDate = typeof params.value === 'string' ? new Date(params.value) : params.value;

    // Check if the parsedDate is a valid date
    if (isNaN(parsedDate.getTime())) {
        throw new Error('Invalid date');
    }

    // Get year, month, and day from parsedDate
    const year = parsedDate.getFullYear();
    const month = ('0' + (parsedDate.getMonth() + 1)).slice(-2); // Months are zero based
    const day = ('0' + parsedDate.getDate()).slice(-2);

    // Return formatted date in yyyy-MM-dd format
    return `${year}-${month}-${day}`;
};
