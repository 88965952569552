import { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useAuth } from '../core/Auth';
import { UserModel } from '../core/_models';

const Callback = () => {
    const { getAccessTokenSilently, user } = useAuth0();
    const { saveAuth, setCurrentUser } = useAuth();

    useEffect(() => {
        if (!user) return;

        const getToken = async () => {
            const token = await getAccessTokenSilently();

            saveAuth({ token });
        };

        getToken();
        setCurrentUser(user as UserModel);
        // accountStore.setUserId(user.sub);

        // setTimeout(() => {
        //   history.push('/select-organisation');
        // }, 400);
    }, [user]);

    return (
        <div>Callback</div>
    );
};

export { Callback };
