import React, { forwardRef, useEffect, useImperativeHandle, useState } from 'react';

interface TimeCellEditorProps {
    value: string;
    stopEditing: () => void;
}

const TimeCellEditor = forwardRef((props: TimeCellEditorProps, ref) => {
    const [value, setValue] = useState(props.value);

    useEffect(() => {
        setValue(props.value);
    }, [props.value]);

    useImperativeHandle(ref, () => ({
        getValue() {
            return value;
        }
    }));

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const inputValue = e.target.value;

        setValue(inputValue);
    };

    return (
        <input
            className='time-input'
            type='time'
            step='1'
            value={value}
            onChange={handleInputChange}
            onBlur={() => props.stopEditing()}
            onKeyUp={(e) => {
                if (e.key === 'Enter') {
                    props.stopEditing();
                }
            }} />
    );
});

export default TimeCellEditor;
