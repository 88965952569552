import React, { useMemo } from 'react';
import { GridReadyEvent } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { MemberPayments } from 'app/api/aaan/types';

type Props = {
    pageSize: string;
    sheetData: MemberPayments[];
    columnDefs: { [key: string]: any }[]
    handleGridReady: (params: GridReadyEvent) => void;
    onCellValueChanged: () => void;
}

const AgGrid = ({ pageSize, sheetData, columnDefs, handleGridReady, onCellValueChanged }: Props) => {
    const defaultColDef = useMemo(() => ({
        filter: true,
        sortable: true,
        resizable: true
    }), []);

    const updateCustomComponents = () => {
        const updatedData = [...columnDefs];

        columnDefs.forEach((item, index) => {
            if (item.field === 'BATCH_NAME') {
                updatedData[index] = {
                    ...updatedData[index],
                    headerCheckboxSelection: true,
                    headerCheckboxSelectionFilteredOnly: true,
                    checkboxSelection: true
                };
            }
        });

        return updatedData;
    };

    return (
        <AgGridReact
            overlayLoadingTemplate={'<span class="ag-overlay-loading-center">Loading spreadsheet...</span>'}
            pagination={true}
            paginationAutoPageSize={pageSize === 'dynamic'}
            suppressMenuHide={true}
            animateRows={true}
            singleClickEdit={true}
            rowSelection='multiple'
            suppressColumnVirtualisation={true}
            stopEditingWhenCellsLoseFocus={true}
            undoRedoCellEditing={true}
            undoRedoCellEditingLimit={10}
            onGridReady={handleGridReady}
            defaultColDef={defaultColDef}
            onCellValueChanged={onCellValueChanged}
            rowData={sheetData}
            columnDefs={updateCustomComponents()} />
    );
};

export default React.memo(AgGrid);
