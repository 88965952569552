import { useLayout } from '_metronic/layout/core';
import { SidebarMenuMain } from './SidebarMenuMain';
import './sidebar-menu.scss';

const SidebarMenu = () => {
    const { config } = useLayout();
    const appSidebarDefaultMinimizeDesktopEnabled =
        config?.app?.sidebar?.default?.minimize?.desktop?.enabled;
    const appSidebarDefaultCollapseDesktopEnabled =
        config?.app?.sidebar?.default?.collapse?.desktop?.enabled;
    const toggleType = appSidebarDefaultCollapseDesktopEnabled
        ? 'collapse'
        : appSidebarDefaultMinimizeDesktopEnabled
            ? 'minimize'
            : '';
    const toggleState = appSidebarDefaultMinimizeDesktopEnabled ? 'active' : '';
    const isHoverable = config.app?.sidebar?.default?.minimize?.desktop?.hoverable;

    return (
        <div className='app-sidebar-menu overflow-hidden flex-column-fluid'>
            <div
                id='kt_app_sidebar_menu_wrapper'
                className='app-sidebar-wrapper hover-scroll-overlay-y my-5'
                data-kt-scroll='true'
                data-kt-scroll-activate='true'
                data-kt-scroll-height='auto'
                data-kt-scroll-dependencies='#kt_app_sidebar_logo, #kt_app_sidebar_footer'
                data-kt-scroll-wrappers='#kt_app_sidebar_menu'
                data-kt-scroll-offset='5px'
                data-kt-scroll-save-state='true'>
                <div
                    id='kt_app_sidebar_toggle'
                    data-kt-toggle='true'
                    data-kt-toggle-state={toggleState}
                    data-kt-toggle-target='body'
                    data-kt-toggle-name={`app-sidebar-${toggleType}`}
                    className={`${isHoverable ? 'd-none' : 'menu-minimize-trigger'}`} />
                <div
                    className='menu menu-column menu-rounded menu-sub-indention px-3'
                    id='#kt_app_sidebar_menu'
                    data-kt-menu='true'
                    data-kt-menu-expand='false'>
                    <SidebarMenuMain />
                </div>
            </div>
        </div>
    );
};

export { SidebarMenu };
