import { useQuery } from 'react-query';
import { ReportsType } from 'app/api/reports/types';
import { useAuth } from 'app/modules/auth';
import { getReports } from 'app/api/reports/reports';
import { useParams } from 'react-router-dom';

const GET_REPORTS_QUERY_KEY = 'getReports';

const useFilteredReports = () => {
    const { currentUser } = useAuth();
    const { organisationId } = useParams();

    const response = useQuery([GET_REPORTS_QUERY_KEY, organisationId!], () => getReports(organisationId!), {
        select: (data) => {
            let filteredSpreadsheetReports: ReportsType[] = [];

            if (currentUser?.app_metadata?.accessGroup) {
                filteredSpreadsheetReports = data.data.filter(
                    (data) =>
                        data.accessGroup?.pageAccess?.includes(currentUser?.app_metadata.accessGroup || '')
                );
            } else {
                filteredSpreadsheetReports = data.data;
            }

            return filteredSpreadsheetReports;
        },
        staleTime: Infinity
    });

    return { ...response };
};

export default useFilteredReports;
