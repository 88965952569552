import { ReactNode } from 'react';
import create from 'zustand';

export type RightDrawerItem = {
    keyName: string;
    title: string;
    isShow: boolean;
    content: () => ReactNode;
};

type RightDrawerState = {
    rightDrawerContent: RightDrawerItem[] | [];
    setRightDrawerContent: (content: RightDrawerItem[]) => void;
};

export const useRightDrawerStore = create<RightDrawerState>((set) => ({
    rightDrawerContent: [{
        keyName: 'actions',
        title: 'Actions',
        isShow: false,
        content: () => <div />
    }, {
        keyName: 'export',
        title: 'Export',
        isShow: false,
        content: () => <div />
    }, {
        keyName: 'filters',
        title: 'Filters',
        isShow: false,
        content: () => <div />
    }],
    setRightDrawerContent: (content) => set({ rightDrawerContent: content })
}));

export default useRightDrawerStore;
