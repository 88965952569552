import React, { ReactNode } from 'react';

type Props = {
    className?: string;
    toggleElement: ReactNode;
    children: ReactNode;
    triggerType?: 'click' | 'hover';
    placement?:
    'right'
    | 'auto'
    | 'auto-start'
    | 'auto-end'
    | 'top'
    | 'bottom'
    | 'left'
    | 'top-start'
    | 'top-end'
    | 'bottom-start'
    | 'bottom-end'
    | 'right-start'
    | 'right-end'
    | 'left-start'
    | 'left-end'
    | undefined
}

const MetronicDropdown = ({ className, children, toggleElement, placement = 'bottom-end', triggerType = 'click' }: Props) => {
    return (
        <div >
            <button
                data-kt-menu-trigger={triggerType}
                data-kt-menu-attach='parent'
                data-kt-menu-placement={placement}
                className={`bare-button w-100 ${className}`}>
                {toggleElement}
            </button>
            <div
                className={'menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-bold py-4 fs-6 w-275px'}
                data-kt-menu='true'>
                {children}
            </div>
        </div>
    );
};

export default MetronicDropdown;
