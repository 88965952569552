import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import useFavouriteReport from 'app/hooks/useFavouriteReport';
import useFilteredReports from 'app/hooks/useFilteredReports';
import { ReportsType } from 'app/api/reports/types';
import Typography from 'app/components/Typography';
import './header-menu-mobile.scss';
import { formatReportGroup, removeNumber } from 'app/utils/helpers';

type Props = {
    formattedMenu: {
        order: number;
        name: string;
        data: {
            [key: string]: ReportsType[];
        };
    }[];
}

const HeaderMenuMobile = ({ formattedMenu }: Props) => {
    const [selectedModule, setSelectedModule] = useState('');
    const [selectedGroup, setSelectedGroup] = useState('');

    const params = useParams();
    const navigate = useNavigate();
    const { data } = useFilteredReports();

    const { favouriteReports, handleFavouriteReport } = useFavouriteReport();

    const handleNavigate = (data: { label: string, value: ReportsType }) => {
        setSelectedModule('');
        setSelectedGroup('');
        navigate(`organisation/${params.organisationId}/report/${data.value.key}`);
    };

    const NavButton = ({ onClick, keyName }: { onClick: () => void, keyName: string }) => {
        return <div onClick={onClick} className='py-3'>
            <div
                className='d-flex justify-content-between align-items-center btn btn-custom btn-primary overflow-hidden text-nowrap px-0 h-35px w-100'>
                <span className='btn-label d-flex'>
                    <div className='d-flex justify-content-start align-items-center ms-4'>
                        <span className='text-white ms-1'>{keyName}</span>
                    </div>
                </span>
                <i style={{ fontSize: 20 }} className='fa-solid fa-caret-right me-3' />
            </div>
        </div>;
    };

    const renderFavouriteElement = (report: ReportsType) => {
        return !favouriteReports.includes(report.key)
            ? <i onClick={(e) => handleFavouriteReport(e, report.key)} style={{ fontSize: 15 }} className='fa-regular fa-star px-2' />
            : <i onClick={(e) => handleFavouriteReport(e, report.key)} style={{ color: '#e6cc00', fontSize: 15 }} className='fa-solid fa-star px-2' />;
    };

    const renderReportItem = (report: ReportsType) => {
        return <div id='kt_app_header_menu_close' onClick={() => handleNavigate({ label: report.key, value: report })} className='card report-item mb-3 p-5'>
            <div key={report.key} className='d-flex align-items-center justify-content-between'>
                <div className='d-flex align-items-center'>
                    <div className='placeholder-image me-4'>
                        <img className='me-4' width={55} src={report.thumbnailUrl}
                            alt={report.key} />
                    </div>
                    <div>
                        <Typography weight='bold' size='5'>{report.reportName}</Typography>
                        <p className='fw-semibold fs-7 text-start report-description'>
                            {report.description &&
                                report.description.substring(0, 245)}
                            &nbsp;
                            {report.description &&
                                report.description.length >= 245
                                ? '...'
                                : ''}
                        </p>
                    </div>
                </div>
                {renderFavouriteElement(report)}
            </div>
        </div>;
    };

    return (
        <div className='header-menu-mobile p-3'>
            <Breadcrumb className='pb-5'>
                <Breadcrumb.Item onClick={() => setSelectedModule('')}>Home</Breadcrumb.Item>
                {selectedModule && <Breadcrumb.Item active={!selectedGroup} onClick={() => setSelectedGroup('')}>
                    {removeNumber(selectedModule)}
                </Breadcrumb.Item>}
                {selectedGroup && <Breadcrumb.Item active={!!selectedGroup}>{removeNumber(selectedGroup)}</Breadcrumb.Item>}
            </Breadcrumb>
            {!selectedModule && formattedMenu && formattedMenu.map((module, i) => {
                return <NavButton key={i} onClick={() => setSelectedModule(module.name)} keyName={removeNumber(module.name)} />;
            })}

            {!selectedGroup && selectedModule && <>
                {favouriteReports.length > 0 && <NavButton onClick={() => setSelectedGroup('Favourites')} keyName='Favourites' />}
                {selectedModule && !selectedGroup && selectedModule !== 'Favourites' && formatReportGroup(formattedMenu.find((menu) => menu.name === selectedModule)!.data).map((key, i: number) => {
                    return <NavButton key={i} onClick={() => setSelectedGroup(key.name)} keyName={removeNumber(key.name)} />;
                })}
            </>}

            <div className='reports-list'>
                <Typography weight='bold' className='mb-3 text-white' size='1'>{removeNumber(selectedGroup)}</Typography>

                {selectedGroup === 'Favourites' && <div className='mb-10' >
                    {favouriteReports.map((favReportKey) => {
                        const report = data?.find((data) => data.key === favReportKey)!;

                        return renderReportItem(report);
                    })}
                </div>}
                {formattedMenu && (selectedModule && selectedModule !== 'Favourites') && selectedGroup && Object.keys(formattedMenu.find((menu) => menu.name === selectedModule)!.data).map((key, j) => {
                    if (key === selectedGroup) {
                        return <div className='mb-10' key={j}>
                            {formattedMenu.find((menu) => menu.name === selectedModule)?.data[selectedGroup].map((report) => {
                                return renderReportItem(report);
                            })}
                        </div>;
                    } else return <div key={j} />;
                })}
            </div>
        </div>
    );
};

export default HeaderMenuMobile;
