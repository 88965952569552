import { useState } from 'react';
import Form from 'react-bootstrap/Form';
import { AirportsScheduleData } from 'app/api/airports/types';
import AppSelect from 'app/components/Select/Select';
import useModalStore from 'app/store/modal';
import { getUniqueValues } from '../../helpers';
import Button from 'app/components/Button';
import { duplicateAirportsScheduleScenario } from 'app/api/airports/airports';
import useToast from 'app/hooks/useToast';
import AppInput from 'app/components/AppInput/AppInput';

interface DuplicateScenarioProps {
    sheetData: AirportsScheduleData[];
    airport: string;
    refetch: any;
    version: string;
    isVersionLocked: boolean;
    scheduleName: string;
    versionInfo: { ID: string, NAME: string }[];
}

const DuplicateScenario: React.FC<DuplicateScenarioProps> = ({ sheetData, airport, version, refetch, isVersionLocked, versionInfo, scheduleName }) => {
    const [isDuplicating, setIsDuplicating] = useState(false);
    const [selectedVersionType, setSelectedVersionType] = useState<'new' | 'existing' | null>(isVersionLocked ? 'new' : null);
    const [duplicateForm, setDuplicateForm] = useState({
        scenarioToDuplicate: '',
        selectedExistingVersion: { label: '', value: '' },
        newScenarioName: 'High',
        newVersionName: ''
    });

    const { clearModalContent } = useModalStore();
    const { setNotification } = useToast();

    const scenarioOptions = getUniqueValues(sheetData, ['SCENARIO']);

    const handleDuplicate = async () => {
        try {
            setIsDuplicating(true);
            await duplicateAirportsScheduleScenario({
                airport,
                scenario: duplicateForm.scenarioToDuplicate,
                version,
                existingVersion: selectedVersionType === 'existing' ? duplicateForm.selectedExistingVersion.value : '',
                newScenarioName: duplicateForm.newScenarioName || 'High',
                newVersionName: selectedVersionType === 'new' ? duplicateForm.newVersionName : '',
                scheduleName
            });
            await refetch();
            setNotification('Scenario duplicated', 'success');
        } catch (error) {
            setNotification('Error duplicating rows', 'error');
        } finally {
            setIsDuplicating(false);
            clearModalContent();
        }
    };

    return (
        <form onSubmit={(e) => {
            e.preventDefault();
            handleDuplicate();
        }}>
            <div className='mb-4'>
                <Form.Label>Select scenario to duplicate</Form.Label>
                <AppSelect value={{ label: duplicateForm.scenarioToDuplicate, value: duplicateForm.scenarioToDuplicate }}
                    onChange={(e) =>
                        setDuplicateForm({ ...duplicateForm, scenarioToDuplicate: e ? e.value : '' })}
                    options={scenarioOptions.SCENARIO.map((option) => ({ label: option, value: option }))} />
            </div>
            <div className='mb-4'>
                <AppInput label='Set new scenario name for duplicated scenarios (Will default to "High" if empty).' value={duplicateForm.newScenarioName} name='new-scenario'
                    onChange={(e) => setDuplicateForm({ ...duplicateForm, newScenarioName: e.target.value })} />
            </div>
            <div className='mb-4'>
                <Form.Check
                    label={<label htmlFor='isNewVersion' className='form-label pb-2'>Set duplicated scenario to new version</label>}
                    id='isNewVersion'
                    onChange={() => setSelectedVersionType(isVersionLocked ? 'new' : selectedVersionType === 'new' ? null : 'new')}
                    checked={selectedVersionType === 'new'}
                    type='checkbox' />
                <Form.Check
                    label={<label htmlFor='isExistingVersion' className='form-label pb-2'>Copy rows to existing version</label>}
                    id='isExistingVersion'
                    onChange={() => setSelectedVersionType(isVersionLocked ? 'existing' : selectedVersionType === 'existing' ? null : 'existing')}
                    checked={selectedVersionType === 'existing'}
                    type='checkbox' />
            </div>
            {selectedVersionType === 'new' && <div className='mb-4'>
                <AppInput label='Set new version name' value={duplicateForm.newVersionName} name='new-scenario'
                    onChange={(e) => setDuplicateForm({ ...duplicateForm, newVersionName: e.target.value })} />
            </div>}
            {selectedVersionType === 'existing' && <div className='mb-4'>
                <AppSelect value={duplicateForm.selectedExistingVersion}
                    onChange={(val) => setDuplicateForm({ ...duplicateForm, selectedExistingVersion: val || { label: '', value: '' } })}
                    options={versionInfo.map((version) => ({ label: version.NAME, value: version.ID })).filter((data) => data.value !== version.toString() && data.value !== '1')} />
            </div>}
            <div className='w-100 d-flex justify-content-end'>
                <Button disabled={isDuplicating || !duplicateForm.scenarioToDuplicate || (!!selectedVersionType && !duplicateForm)} onClick={handleDuplicate} size='sm'>Duplicate</Button>
            </div>
        </form>
    );
};

const useDuplicateScenario = (sheetData: AirportsScheduleData[], airport: string, version: string, refetch: any, isVersionLocked: boolean, versionInfo: { ID: string, NAME: string }[], scheduleName: string) => {
    const { setModalContent } = useModalStore();

    const handleDuplicateScenario = () => {
        setModalContent({
            content: <DuplicateScenario sheetData={sheetData} airport={airport} version={version}
                scheduleName={scheduleName}
                isVersionLocked={isVersionLocked}
                refetch={refetch}
                versionInfo={versionInfo} />,
            title: 'Duplicate Scenario'
        });
    };

    return { handleDuplicateScenario };
};

export default useDuplicateScenario;
