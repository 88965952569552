import { GridApi } from 'ag-grid-community';
import { addAirportsScheduleRow } from 'app/api/airports/airports';
import { AirportsScheduleData } from 'app/api/airports/types';
import useToast from 'app/hooks/useToast';
import { getCurrentDate } from 'app/utils/helpers';
import { useState } from 'react';

const useAddRow = (refetch: any, gridApi: GridApi | null, scheduleName: string) => {
    const { setNotification } = useToast();
    const [isLoading, setIsLoading] = useState(false);

    const handleAddRow = async (currentVersion: string, airport: string) => {
        const currentDate = getCurrentDate();

        const newDataItem: AirportsScheduleData = {
            ID: 0,
            AIRPORT: airport,
            SCENARIO: '',
            VERSION_ID: currentVersion,
            VERSION_IS_LOCKED: false,
            PERIOD_START: currentDate,
            PERIOD_END: currentDate,
            AIRLINE_NAME: '',
            ROUTE: '',
            FLIGHT_CATEGORY: 'DOM',
            CHANGE_DRIVER: 'Load factor',
            AIRCRAFT: '',
            SEATS: 0,
            LOAD_FACTOR: 0,
            MONDAY_MOVEMENTS: 0,
            TUESDAY_MOVEMENTS: 0,
            WEDNESDAY_MOVEMENTS: 0,
            THURSDAY_MOVEMENTS: 0,
            FRIDAY_MOVEMENTS: 0,
            SATURDAY_MOVEMENTS: 0,
            SUNDAY_MOVEMENTS: 0
        };

        try {
            setIsLoading(true);
            await addAirportsScheduleRow([newDataItem], { scheduleName });
            refetch();
            setNotification('Added a row', 'success');

            if (gridApi) {
                gridApi.paginationGoToLastPage();
            }
        } catch (error) {
            setNotification('Failed to add a row', 'error');
        } finally {
            setIsLoading(false);
        }
    };

    return { handleAddRow, isLoading };
};

export default useAddRow;
