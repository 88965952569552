import axios from 'axios';
import { useAuth0 } from '@auth0/auth0-react';
import * as authHelper from '../modules/auth/core/AuthHelpers';

// Create an instance of axios with a specific type
const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
        'Content-Type': 'application/json'
    }
});

const useAxiosInterceptors = (): void => {
    const { getAccessTokenSilently, logout } = useAuth0();

    axiosInstance.interceptors.response.use(
        (response) => response,
        async (error) => {
            const originalRequest = error.config;

            if ((error.response?.status === 401 || error.response?.status === 403) && !originalRequest._retry) {
                originalRequest._retry = true;

                try {
                    // Attempt to refresh the token
                    const newToken: string = await getAccessTokenSilently({ ignoreCache: true });

                    authHelper.setAuth({ token: newToken });
                    // Set the new token in the headers and retry the original request
                    originalRequest.headers.Authorization = `Bearer ${newToken}`;

                    return axiosInstance(originalRequest);
                } catch (authError) {
                    // If token refresh fails, log out the user
                    logout({ returnTo: window.location.origin });

                    return Promise.reject(authError);
                }
            }

            // If it's not a 403 error or the retry fails, reject the promise
            return Promise.reject(error);
        }
    );
};

export { axiosInstance, useAxiosInterceptors };
