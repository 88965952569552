import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import AppSelect from 'app/components/Select/Select';
import { components } from 'react-select';
import './collapsible-select.scss';

interface CollapsibleSelectProps {
    value: string;
    stopEditing: () => void;
    values: Record<string, { AIRLINE_NAME: string; SEATS: string; DISPLAY_CODE: string }[]>;
}

const HideGroupHeading = (props: any) => {
    return (
        <div
            className='collapse-group-heading'
            onClick={() => {
                document.querySelector(`#${props.id}`)?.parentElement?.parentElement?.classList.toggle('collapsed-group');
            }}>
            <components.GroupHeading {...props} />
        </div>
    );
};

const HideGroupMenuList = (props: any) => {
    const newGroup = {
        ...props,
        children: Array.isArray(props.children)
            ? props.children.map((c: any, idx: any) =>
                idx === 0
                    ? c
                    : { ...c, props: { ...c.props, className: 'collapsed-group' } }
            )
            : props.children
    };

    return <components.MenuList {...newGroup} />;
};

const CollapsibleSelect = forwardRef((props: CollapsibleSelectProps, ref) => {
    const [selectedOption, setSelectedOption] = useState<any>(null);

    useEffect(() => {
        const initialValue = Object.values(props.values)
            .flat()
            .find(option => option.DISPLAY_CODE === props.value);

        if (initialValue) {
            setSelectedOption({
                value: initialValue.DISPLAY_CODE,
                label: initialValue.DISPLAY_CODE
            });
        }
    }, [props.value, props.values]);

    useImperativeHandle(ref, () => ({
        getValue() {
            return selectedOption ? selectedOption.value : '';
        }
    }));

    const handleChange = (option: { label: string; value: string } | null) => {
        setSelectedOption(option);
    };

    const groupedOptions = Object.entries(props.values)
        .map(([airline, options]) => ({
            label: airline,
            options: options.map((option: any) => ({
                label: option.DISPLAY_CODE,
                value: option.DISPLAY_CODE
            }))
        }))
        .sort((a, b) => a.label.localeCompare(b.label)); // Sort by label

    return (
        <AppSelect
            menuPortalTarget={document.body}
            // @ts-ignore
            styles={{
                groupHeading: (styles: any) => ({
                    ...styles,
                    backgroundColor: '#e9ecef',
                    margin: 0,
                    paddingTop: '5px',
                    paddingBottom: '5px',
                    color: 'black',
                    fontWeight: 500,
                    fontSize: '1rem',
                    flex: 1
                })
            }}
            value={selectedOption}
            onChange={handleChange}
            options={groupedOptions}
            blurInputOnSelect={false}
            closeMenuOnSelect={false}
            onBlur={() => props.stopEditing()}
            components={{
                GroupHeading: HideGroupHeading,
                MenuList: HideGroupMenuList
            }}
            onKeyDown={(e) => {
                if (e.key === 'Enter') {
                    props.stopEditing();
                }
            }} />
    );
});

export default CollapsibleSelect;
