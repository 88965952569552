import React, { useMemo } from 'react';
import { GridReadyEvent } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';

import { AircraftAirlineDataOptions } from 'app/api/airports/types';
import CustomSelectEditor from '../components/CustomSelect';

type Props = {
    pageSize: string;
    sheetData: any[];
    sheetDataOptions: AircraftAirlineDataOptions | undefined;
    columnDefs: { [key: string]: any }[]
    handleGridReady: (params: GridReadyEvent) => void;
    onCellValueChanged: () => void;
}

const AgGrid = ({ pageSize, sheetData, columnDefs, sheetDataOptions, handleGridReady, onCellValueChanged }: Props) => {
    const defaultColDef = useMemo(() => ({
        filter: true,
        sortable: true,
        resizable: true
    }), []);

    const updateCustomComponents = () => {
        const updatedData = [...columnDefs];

        // var aircraftSeats;
        if (sheetDataOptions) {
            columnDefs.forEach((item, index) => {
                if (item.cellEditor === 'agSelectCellEditor') {
                    updatedData[index] = {
                        ...updatedData[index],
                        cellEditorParams: {
                            values: sheetDataOptions[item.field as keyof AircraftAirlineDataOptions]
                        },
                        cellEditor: CustomSelectEditor
                    };
                }
            });
        }

        return updatedData;
    };

    return (
        <AgGridReact
            overlayLoadingTemplate={'<span class="ag-overlay-loading-center">Loading spreadsheet...</span>'}
            pagination={true}
            paginationAutoPageSize={pageSize === 'dynamic'}
            suppressMenuHide={true}
            animateRows={true}
            singleClickEdit={true}
            rowSelection='multiple'
            suppressColumnVirtualisation={true}
            stopEditingWhenCellsLoseFocus={true}
            undoRedoCellEditing={true}
            undoRedoCellEditingLimit={10}
            onGridReady={handleGridReady}
            defaultColDef={defaultColDef}
            onCellValueChanged={onCellValueChanged}
            rowData={sheetData}
            columnDefs={updateCustomComponents()} />
    );
};

export default React.memo(AgGrid);
