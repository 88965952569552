
export const AAAN_STATUS: any = {
    'SENT-TO-DEFENCE': 1,
    'DEFENCE-ACCEPTED': 2,
    'BILLED-TO-DEFENCE': 3
};

export const AAAN_STATUS_MAPPING = {
    1: 'Commenced',
    2: 'Being Validated',
    3: 'Billed to Defence'
};
