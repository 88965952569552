import React, { ReactNode, useState } from 'react';
import { IoCloseOutline } from 'react-icons/io5';
import BootstrapModal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';

export type ModalSizeType = 'sm' | 'lg' | 'xl';

type Props = {
    children: ReactNode;
    isShow: boolean;
    title?: string;
    withFooter?: boolean;
    onToggle?: Function;
    onSubmit?: Function;
    backdrop?: boolean | 'static';
    size?: ModalSizeType;
    className?: string;
}

const Modal = ({ children, isShow, onToggle, onSubmit, backdrop, className, size, title, withFooter = true }: Props) => {
    const [show, setShow] = useState(isShow);

    const handleClose = () => {
        onToggle && onToggle();

        setShow(!show);
    };

    const handleSubmit = () => {
        onSubmit && onSubmit();
        // handleClose();
    };

    return (
        <BootstrapModal
            show={isShow}
            centered={true}
            onHide={handleClose}
            backdrop={backdrop}
            size={size}
            dialogClassName={className}>
            <BootstrapModal.Header closeButton={true}>
                {title && (
                    <BootstrapModal.Title>{title}</BootstrapModal.Title>
                )}

                {/* {backdrop !== 'static' && <div onClick={handleClose} className='modal__close'>
                    <IoCloseOutline size={20} />
                </div>} */}
            </BootstrapModal.Header>
            <BootstrapModal.Body>{children}</BootstrapModal.Body>
            {/* {withFooter && <BootstrapModal.Footer>
                <Button variant='secondary' onClick={handleClose}>
                    Close
                </Button>
                <Button variant='primary' onClick={handleClose}>
                    Save Changes
                </Button>
            </BootstrapModal.Footer>} */}
        </BootstrapModal>
    );
};

export default Modal;
