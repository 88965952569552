import React, { useMemo } from 'react';
import { useParams } from 'react-router';
import { useGetData } from 'app/api/processing/processing';
import { GridReadyEvent, RowClassParams } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { useAuth } from 'app/modules/auth';
import CustomDropdownFilter from './components/filters/CustomDropdownFilter';
import TimeCellEditor from './components/editors/TimeCellEditor/TimeCellEditor';
import { FILTER_TYPES } from 'app/utils/constants';
import './ag-grid.scss';

type Props = {
    pageSize: string;
    parsedRawSheetData: any[];
    columnNames: { [key: string]: any }[]
    handleGridReady: (params: GridReadyEvent) => void;
    onCellValueChanged: () => void;
}

const cellsToDisableIfImportedFromFile = ['BILLING_DATE', 'BILLING_TIME', 'BILLING_ICAO_CODE'];
const cellsToDisableIfManuallyAdded = ['BILLING_RECORD_STATUS', 'BILLING_NOTES_DEFENCE'];

const AgGrid = ({ pageSize, parsedRawSheetData, columnNames, handleGridReady, onCellValueChanged }: Props) => {
    const params = useParams();
    const { currentUser } = useAuth();

    const modifiedValueFilter = (currentUser?.app_metadata.columnFilter || []);

    const { data: rawSheetData } = useGetData({ db: process.env.REACT_APP_AAAN_DB!, schema: process.env.REACT_APP_AAAN_SCHEMA!, viewOrTable: process.env.REACT_APP_AAAN_VIEW! }, modifiedValueFilter,
        {
            refetchOnWindowFocus: false,
            refetchOnReconnect: false,
            enabled: params.reportKey === 'aaan-prepare-for-defence'
        });

    const defaultColDef = useMemo(() => ({
        filter: true,
        sortable: true,
        resizable: true
    }), []);

    const updateCustomComponents = () => {
        const updatedData = [...columnNames];

        columnNames.forEach((item, index) => {
            if (params.reportKey === 'aaan-prepare-for-defence') {
                if (cellsToDisableIfImportedFromFile.includes(item.field)) {
                    updatedData[index] = {
                        ...updatedData[index],
                        editable: (params: any) => params.data.BILLING_ID === 0
                    };
                }

                if (cellsToDisableIfManuallyAdded.includes(item.field)) {
                    updatedData[index] = {
                        ...updatedData[index],
                        editable: (params: any) => params.data.BILLING_ID
                    };
                }

                if (item.field === 'BILLING_ID') {
                    updatedData[index] = {
                        ...updatedData[index],
                        headerCheckboxSelection: true,
                        headerCheckboxSelectionFilteredOnly: true,
                        checkboxSelection: true
                    };
                }
            } else if (params.reportKey === 'aaan-defence-not-approved') {
                if (item.field === 'BILLING_RECORD_STATUS') {
                    updatedData[index] = {
                        ...updatedData[index],
                        valueSetter: (params: any) => {
                            const newValue = params.newValue;
                            const data = params.data;

                            data.BILLING_RECORD_STATUS = newValue;

                            data.BILLING_MOVEMENTS = newValue === 'DEFENCE-DISPUTED' ? 1 : 0;

                            return true;
                        }
                    };
                }
            }

            if (item.filter === FILTER_TYPES.CUSTOM_TEXT) {
                const aircraftTypes = Array.from(new Set(parsedRawSheetData.map((obj) => obj[item.field] ? obj[item.field] : '(Blank)'))).sort().map((item) => ({ value: item, label: item }));

                updatedData[index] = {
                    ...updatedData[index],
                    filter: CustomDropdownFilter,
                    filterParams: {
                        values: aircraftTypes
                    }
                };
            } else if (item.cellEditor === 'agTimeCellEditor') {
                updatedData[index] = {
                    ...updatedData[index],
                    cellEditor: TimeCellEditor
                };
            } else if (item.field === 'BILLING_ICAO_CODE') {
                updatedData[index] = {
                    ...updatedData[index],
                    cellEditorParams: {
                        values: rawSheetData ? JSON.parse(rawSheetData.data.data).map((data: any) => data.ICAO).sort() : []
                    }
                };
            }
        });

        return updatedData;
    };

    const rowClassRules = {
        'aaan-light-grey': (params: RowClassParams) => params.data.BILLING_AMOUNT === 0
    };

    const calculateTotals = () => {
        const amountTotal = parsedRawSheetData.reduce((sum, row) => sum + parseFloat(row.BILLING_AMOUNT), 0);
        const movementsTotal = parsedRawSheetData.reduce((sum, row) => sum + parseInt(row.BILLING_MOVEMENTS), 0);

        return [
            {
                BILLING_ICAO_CODE: 'Totals',
                BILLING_AMOUNT: amountTotal,
                BILLING_MOVEMENTS: movementsTotal
            }
        ];
    };

    const pinnedBottomRowData = useMemo(() => params.reportKey === 'aaan-invoice-defence' ? calculateTotals() : undefined, [parsedRawSheetData]);

    return (
        <AgGridReact
            overlayLoadingTemplate={'<span class="ag-overlay-loading-center">Loading spreadsheet...</span>'}
            pagination={true}
            paginationAutoPageSize={pageSize === 'dynamic'}
            suppressMenuHide={true}
            animateRows={true}
            singleClickEdit={true}
            rowClassRules={params.reportKey === 'aaan-prepare-for-defence' ? rowClassRules : {}}
            rowSelection='multiple'
            suppressColumnVirtualisation={true}
            stopEditingWhenCellsLoseFocus={true}
            undoRedoCellEditing={true}
            undoRedoCellEditingLimit={10}
            onGridReady={handleGridReady}
            defaultColDef={defaultColDef}
            pinnedBottomRowData={pinnedBottomRowData}
            onCellValueChanged={onCellValueChanged}
            rowData={parsedRawSheetData}
            columnDefs={updateCustomComponents()} />
    );
};

export default React.memo(AgGrid);
