import React, { useMemo } from 'react';
import { GridReadyEvent } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';

import { AirportsScheduleDataOptions } from 'app/api/airports/types';
import { groupBy } from 'app/utils/helpers';
import CollapsibleSelect from '../components/CollapsibleSelect';
import './ag-grid.scss';

type Props = {
    pageSize: string;
    sheetData: any[];
    sheetDataOptions: AirportsScheduleDataOptions | undefined;
    columnDefs: { [key: string]: any }[]
    handleGridReady: (params: GridReadyEvent) => void;
    onCellValueChanged: () => void;
    onRowSelect: () => void;
}

const AgGrid = ({ pageSize, sheetData, columnDefs, sheetDataOptions, handleGridReady, onCellValueChanged, onRowSelect }: Props) => {
    const defaultColDef = useMemo(() => ({
        filter: true,
        sortable: true,
        resizable: true
    }), []);

    const groupedAircraft = groupBy(sheetDataOptions?.AIRCRAFT || [], (data) => data.AIRLINE_NAME);

    const updateCustomComponents = () => {
        const updatedData = [...columnDefs];

        // var aircraftSeats;
        if (sheetDataOptions) {
            columnDefs.forEach((item, index) => {
                if (item.cellEditor === 'agSelectCellEditor') {
                    updatedData[index] = {
                        ...updatedData[index],
                        cellEditorParams: {
                            values: item.field === 'AIRCRAFT' ? groupedAircraft : sheetDataOptions[item.field as keyof AirportsScheduleDataOptions]
                        },
                        cellEditor: item.field === 'AIRCRAFT' ? CollapsibleSelect : 'agSelectCellEditor'
                    };
                } else if (item.field === 'SEATS') {
                    updatedData[index] = {
                        ...updatedData[index],
                        value: sheetDataOptions.AIRCRAFT.filter((a) => a.DISPLAY_CODE === item.field.AIRCRAFT).map(a => a.SEATS)
                    };
                } else if (item.field === 'SCENARIO') {
                    updatedData[index] = {
                        ...updatedData[index],
                        headerCheckboxSelection: true,
                        headerCheckboxSelectionFilteredOnly: true,
                        checkboxSelection: true
                    };
                }
            });
        }

        updatedData.push({
            editable: false,
            width: 120,
            filter: 'agNumberColumnFilter',
            headerName: 'Total',
            valueGetter: 'data.SUNDAY_MOVEMENTS + data.MONDAY_MOVEMENTS + data.TUESDAY_MOVEMENTS + data.WEDNESDAY_MOVEMENTS + data.THURSDAY_MOVEMENTS + data.FRIDAY_MOVEMENTS + data.SATURDAY_MOVEMENTS'
        });

        return updatedData;
    };

    return (
        <AgGridReact
            overlayLoadingTemplate={'<span class="ag-overlay-loading-center">Loading spreadsheet...</span>'}
            pagination={true}
            paginationAutoPageSize={pageSize === 'dynamic'}
            suppressMenuHide={true}
            animateRows={true}
            singleClickEdit={true}
            rowSelection='multiple'
            suppressColumnVirtualisation={true}
            stopEditingWhenCellsLoseFocus={true}
            undoRedoCellEditing={true}
            undoRedoCellEditingLimit={10}
            onGridReady={handleGridReady}
            defaultColDef={defaultColDef}
            onCellValueChanged={onCellValueChanged}
            rowData={sheetData}
            columnDefs={updateCustomComponents()}
            onRowSelected={onRowSelect} />
    );
};

export default React.memo(AgGrid);
