import { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router';
import moment from 'moment';
import { GridApi, GridReadyEvent } from 'ag-grid-community';
import Button from 'react-bootstrap/Button';
import useToast from 'app/hooks/useToast';
import useConfigStore from 'app/store/userConfig';
import useRightDrawerStore from 'app/store/rightDrawer';
import Typography from 'app/components/Typography';
import AppDatePicker from 'app/components/AppDatePicker';
import { ReportsType } from 'app/api/reports/types';
import { updateAirportsData, useGetAirportsData } from 'app/api/airports/airports';
import { CELL_EDITOR_TYPES, ORIENTATION } from 'app/utils/constants';
import { findChangedObjects, updateRightDrawerData } from 'app/utils/helpers';
import { buttonConfig } from './buttonConfig';
import AgGrid from './AgGrid';
import SpreadsheetExport from './SpreadsheetExport';
import useCreateAirportGridColumns from './hooks/useCreateAirportGridColumns';

import './spreadsheet-grid-airports-commercial.scss';
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import { getPreviousMonth, fieldExists } from '../helpers';

type Props = {
    report: ReportsType;
    className?: string;
}

const SpreadsheetGridAirportsCommercial = ({ report, className }: Props) => {
    const [gridApi, setGridApi] = useState<GridApi | null>(null);
    const [isSheetUpdated, setIsSheetUpdated] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [monthYearFilter, setMonthYearFilter] = useState<string>(getPreviousMonth());
    const [pageSize, setPageSize] = useState(localStorage.getItem('pageSize') || 'dynamic');

    const params = useParams<{ reportKey: string, organisationId: string }>();

    const { setRightDrawerContent, rightDrawerContent } = useRightDrawerStore();
    const { navbarOrientation } = useConfigStore();

    const { data: rawSheetData, isLoading: isSpreadsheetDataLoading, refetch } = useGetAirportsData({ monthYear: monthYearFilter },
        {
            refetchOnWindowFocus: false,
            refetchOnReconnect: false
        });
    const { setNotification } = useToast();
    const parsedRawSheetData: any[] = useMemo(() => rawSheetData
        ? JSON.parse(rawSheetData.data.data).map((data: any) => {
            if (fieldExists(JSON.parse(rawSheetData.data.data), 'OUTLET_TURNOVER')) {
                return data;
            } else {
                return { AIRPORT_DATE: monthYearFilter, ...data, OUTLET_TURNOVER: 0, OUTLET_TRANSACTIONS: 0, AIRPORT_REVENUE: 0, RENTAL_DAYS: 0 };
            }
        })
        : [], [rawSheetData]);

    const createdColumns = useCreateAirportGridColumns(parsedRawSheetData[0] || {}, report);

    const columnNames = useMemo(() => createdColumns, [parsedRawSheetData, report]);

    useEffect(() => {
        const rightDrawerContentUpdate = [{
            keyName: 'actions',
            isShow: false,
            content: () => <Typography>No Available Actions</Typography>
        }, {
            keyName: 'export',
            isShow: true,
            content: () => <SpreadsheetExport parsedRawSheetData={parsedRawSheetData} isSheetUpdated={isSheetUpdated} />
        }, {
            keyName: 'filters',
            isShow: false,
            content: () => <div />
        }];

        const updatedContent = updateRightDrawerData(rightDrawerContentUpdate, rightDrawerContent);

        setRightDrawerContent(updatedContent);
    }, [isSheetUpdated, parsedRawSheetData]);

    useEffect(() => {
        if (gridApi) {
            isSpreadsheetDataLoading ? gridApi.showLoadingOverlay() : gridApi.hideOverlay();
        }
    }, [gridApi, isSpreadsheetDataLoading]);

    useEffect(() => {
        const pageSize = localStorage.getItem('pageSize');

        if (!pageSize) {
            localStorage.setItem('pageSize', 'dynamic');
        } else if (gridApi && pageSize !== 'dynamic') {
            gridApi.paginationSetPageSize(Number(pageSize));
        }
    }, [gridApi]);

    const handleSaveSpreadsheet = async () => {
        const updatedValues: any[] = [];

        if (gridApi) {
            gridApi.forEachNode((node) => {
                const updatedValue = node.data;

                updatedValues.push(updatedValue);
            });
        }

        if (rawSheetData) {
            const changedObjects = findChangedObjects(JSON.parse(rawSheetData.data.data), updatedValues);

            try {
                setIsSaving(true);
                await updateAirportsData(changedObjects);
                refetch();

                setIsSheetUpdated(false);
                setNotification('Sheet saved', 'success');
            } catch (error: any) {
                if (error.response && error.response.data && error.response.data.message) {
                    setNotification(error.response.data.message, 'error');
                } else {
                    setNotification('Failed to update spreadsheet', 'error');
                }
            } finally {
                setIsSaving(false);
            }
        }
    };

    const handleAddRowGrid = async () => {
        const newData = columnNames.reduce((acc: { [key: string]: string | number }, column) => {
            if (column.cellEditor === CELL_EDITOR_TYPES.NUMBER) {
                acc[column.field] = 0;
            } else if (column.field === 'AIRPORT_DATE') {
                acc[column.field] = monthYearFilter;
            } else if (column.field === 'OUTLET_TURNOVER') {
                acc[column.field] = 0;
            } else if (column.field === 'OUTLET_TRANSACTIONS') {
                acc[column.field] = 0;
            } else if (column.field === 'AIRPORT_REVENUE') {
                acc[column.field] = 0;
            } else if (column.field === 'RENTAL_DAYS') {
                acc[column.field] = 0;
            } else if (column.field === 'ID') {
                acc[column.field] = 0;
            }

            return acc;
        }, {});

        if (gridApi) {
            gridApi.applyTransaction({ add: [newData] })!;

            gridApi.paginationGoToLastPage();
            setNotification('Added a row', 'success');
        }
    };

    const onCellValueChanged = useCallback(() => setIsSheetUpdated(true), []);

    const onPageSizeChanged = () => {
        if (gridApi) {
            const value = (document.getElementById('page-size') as HTMLInputElement).value || 'dynamic';

            gridApi.paginationSetPageSize(value !== 'dynamic' ? Number(value) : undefined);
            localStorage.setItem('pageSize', value);

            if (value === 'dynamic') window.location.reload();

            setPageSize(value);
        }
    };

    const handleGridReady = useCallback((params: GridReadyEvent) => {
        setGridApi(params.api);
    }, []);

    return (
        <div>
            <div className='d-flex justify-content-between align-items-center mb-3'>
                <Typography className='w-100 text-left' weight='bolder' size='2x'>{report.reportName}</Typography>
                <AppDatePicker
                    showIcon={true}
                    icon='fa fa-calendar'
                    className='w-200px'
                    onKeyDown={(e) => {
                        e.preventDefault();
                    }}
                    placeholderText='Select Month and Year'
                    dateFormat='yyyy-MM'
                    dropdownMode='select'
                    showMonthYearPicker={true}
                    selected={new Date(monthYearFilter)}
                    onChange={(date: Date) => setMonthYearFilter(date ? moment(date).startOf('month').format('YYYY-MM-DD') : getPreviousMonth())} />
            </div>
            <div className={`spreadsheet-grid-airports-commercial card w-100 ${className}`}>
                <div className='grid-control'>
                    <div className='d-flex justify-content-end gap-3' />
                    <div className='d-flex justify-content-end gap-3'>
                        {buttonConfig.right.map((config, i) => {
                            if (config.label === 'Add Row') {
                                return <Button key={i} size='sm'
                                    onClick={handleAddRowGrid}>{config.label}</Button>;
                            } else if (config.label === 'Save') {
                                return <Button key={i} disabled={config.saveSheetBeforeAction ? isSaving || !isSheetUpdated : false} size='sm'
                                    onClick={handleSaveSpreadsheet}>{config.label}</Button>;
                            } else return <div key={i} />;
                        })}
                    </div>
                </div>
                <div className={`ag-theme-alpine ${navbarOrientation === ORIENTATION.VERTICAL ? 'vertical' : 'horizontal'}`}>
                    <AgGrid
                        pageSize={pageSize}
                        parsedRawSheetData={parsedRawSheetData}
                        handleGridReady={handleGridReady}
                        columnDefs={columnNames}
                        onCellValueChanged={onCellValueChanged} />
                    <div className='d-flex align-items-center justify-content-end my-2'>
                        <p className='fw-semibold fs-4 m-0 me-2'>Page Size:</p>
                        <select value={pageSize} onChange={onPageSizeChanged} id='page-size'>
                            <option value='dynamic'>Dynamic</option>
                            <option value='50'>50</option>
                            <option value='100'>100</option>
                            <option value='300'>300</option>
                        </select>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SpreadsheetGridAirportsCommercial;
