import React, { useMemo } from 'react';
import { GridReadyEvent } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import { MemberAirports } from 'app/api/aaan/types';
import CustomSelectEditor from '../components/CustomSelect';

type Props = {
    pageSize: string;
    sheetData: MemberAirports[];
    sheetDataOptions: { ICAO: string[], XERO_CONTACTS: { XERO_CONTACT_ID: string, XERO_CONTACT_NAME: string }[] } | undefined;
    columnDefs: { [key: string]: any }[]
    handleGridReady: (params: GridReadyEvent) => void;
    onCellValueChanged: () => void;
}

const AgGrid = ({ pageSize, sheetData, columnDefs, sheetDataOptions, handleGridReady, onCellValueChanged }: Props) => {
    const defaultColDef = useMemo(() => ({
        filter: true,
        sortable: true,
        resizable: true
    }), []);

    const updateCustomComponents = () => {
        const updatedData = [...columnDefs];

        // var aircraftSeats;
        if (sheetDataOptions) {
            const selectedICAOCodes = sheetData.map((data) => data.ICAO);

            columnDefs.forEach((item, index) => {
                if (item.field === 'ICAO') {
                    updatedData[index] = {
                        ...updatedData[index],
                        cellEditorParams: {
                            values: sheetDataOptions.ICAO.filter((value) => !selectedICAOCodes.includes(value)).map((value) => ({ label: value, value: value.split('-')[0] }))
                        },
                        cellEditor: CustomSelectEditor,
                        valueSetter: (params: any) => {
                            const newValue = params.newValue;
                            const data = params.data;

                            const location = sheetDataOptions!.ICAO.find((data) => data.split('-')[0] === newValue);

                            data.ICAO = newValue;
                            data.LOCATION = location?.split('-')[1];

                            return true;
                        }
                    };
                } else if (item.field === 'XERO_CONTACT_NAME') {
                    updatedData[index] = {
                        ...updatedData[index],
                        cellEditorParams: {
                            values: sheetDataOptions.XERO_CONTACTS.map((value) => ({ label: value.XERO_CONTACT_NAME, value: value.XERO_CONTACT_NAME }))
                        },
                        cellEditor: CustomSelectEditor

                    };
                }
            });
        }

        return updatedData;
    };

    return (
        <AgGridReact
            overlayLoadingTemplate={'<span class="ag-overlay-loading-center">Loading spreadsheet...</span>'}
            pagination={true}
            paginationAutoPageSize={pageSize === 'dynamic'}
            suppressMenuHide={true}
            animateRows={true}
            singleClickEdit={true}
            rowSelection='multiple'
            suppressColumnVirtualisation={true}
            stopEditingWhenCellsLoseFocus={true}
            undoRedoCellEditing={true}
            undoRedoCellEditingLimit={10}
            onGridReady={handleGridReady}
            defaultColDef={defaultColDef}
            onCellValueChanged={onCellValueChanged}
            rowData={sheetData}
            columnDefs={updateCustomComponents()} />
    );
};

export default React.memo(AgGrid);
